import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RosterProfileService} from '../../../services/roster-profile.service';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {RosterProfileModel} from '../../../models/roster-profile.model';
import {PhoneValidationUtility} from '../../../shared/utilities/phone-validation.utility';
import {transformDateTimeToDateOnly} from '../../../shared/utilities/form.utility';
import {ContextService} from '../../../services/context.service';
import {SMSOptStatusModel} from '../../../models/sms.model';
import {SmsDialogComponent} from '../../../shared/components/sms-dialog/sms-dialog.component';
import {PiiValidateModel} from '../../../models/pii-validate.model';
import {PiiDuplicateDialogComponent} from '../../../shared/components/pii-duplicate-dialog/pii-duplicate-dialog.component';
import {PiiValidateService} from '../../../services/pii-validate.service';
import {addHelmetsToHardhatsCustomField, donotRequirePrimaryEmailCustom, separateCustomFieldsOnProfile} from '../../../shared/utilities/component-manager-utility';

@Component({
  selector: 'app-roster-contact-form',
  templateUrl: './roster-form-contact.component.html',
  styleUrls: ['./roster-form-contact.component.scss']
})

export class RosterFormContactComponent implements OnInit, OnChanges, OnDestroy {
  context = this.contextService.contextObject;
  mainForm: FormGroup;
  dateOfBirth: Date;
  rosterProfile: RosterProfileModel;
  inputObjHonorific: GenericSelectModel;
  inputObjRace: GenericSelectModel;
  inputObjEthnicity: GenericSelectModel;
  // inputObjLanguage: GenericSelectModel;
  inputObjEducation: GenericSelectModel;
  inputObjMilitary: GenericSelectModel;
  inputObjMilitaryStatus: GenericSelectModel;
  inputObjMarriageStatus: GenericSelectModel;
  inputObjShirtSize: GenericSelectModel;
  inputObjCitizenship: GenericSelectModel;
  inputObjLanguage: GenericSelectModel;
  setPreferredLanguage: number;
  setMilitaryStatusId: number;
  setMarriageStatusId: number;
  setShirtSizeId: number;
  setCitizenshipId: number;
  languages;
  selectedLanguages: number[] = [];
  preferredLanguageString: string;
  races;
  isSearching: boolean;
  isEditing: boolean;
  selectedRaces: number[] = [];
  setPrefixId: number;
  loading: boolean = false;
  isDirty: boolean;
  isSaving: boolean = false;
  // setRaceId: number;
  setEthnicityId: number;
  setLanguageId: number;
  setEducationId: number;
  setMilitaryId: number;
  inputObjOrganization: GenericSelectModel;
  inputObjType: GenericSelectModel;
  existingMessage: boolean = false;
  PrimaryPhoneFailedCommunication: boolean = false;
  SecondaryPhoneFailedCommunication: boolean = false;
  PrimaryEmailFailedCommunication: boolean = false;
  SecondaryEmailFailedCommunication: boolean = false;
  locationHeader: string;
  showEmergency: boolean = false;
  smsData: SMSOptStatusModel[] = [];
  requirePrimaryEmail: boolean;
  @Input() personID: number;
  @Input() isEdit: boolean;
  @Input() canEdit: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  public _separateCustomFieldsOnProfile = separateCustomFieldsOnProfile;
  private _donotRequirePrimaryEmailCustom = donotRequirePrimaryEmailCustom;
  private _addHelmetsToHardhatsCustomField = addHelmetsToHardhatsCustomField;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService, public contextService: ContextService, private piiValidateService: PiiValidateService,
              private rosterProfileService: RosterProfileService, private ref: DynamicDialogRef,
              private lookupsDataService: LookupsDataService, private phoneValidationUtility: PhoneValidationUtility,
              private confirmationService: ConfirmationService, public dialogService: DialogService) {

    this.mainForm = this.formBuilder.group({
      prefixID: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      middleName: new FormControl(''),
      lastName: new FormControl('', [Validators.required]),
      suffix: new FormControl(''),
      dateOfBirth: new FormControl(''),
      gender: new FormControl(''),
      militaryStatusID: new FormControl(null),
      marriageStatusID: new FormControl(null),
      citizenshipID: new FormControl(null),
      shirtSizeID: new FormControl(null),
      schoolAttended: new FormControl(null),
      primaryPhoneNumber: new FormControl(''),
      secondaryPhoneNumber: new FormControl(''),
      thirdPhoneNumber: new FormControl(null),
      SMSOptIn: new FormControl(''),
      optOutCarrier: new FormControl(true),
      primaryEmail: new FormControl(null, [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      EmailMsgOptIn: new FormControl(''),
      secondaryEmail: new FormControl('', [Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      AltEmailMsgOptIn: new FormControl(''),
      raceID: new FormControl(''),
      voter: new FormControl(null),
      ethnicityID: new FormControl(''),
      languageID: new FormControl(''),
      educationID: new FormControl(''),
      militaryID: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.isEdit) {
      this.loading = true;
      this.rosterProfileService.getRosterProfile(this.personID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.rosterProfile = res;
            this.rosterProfile.EmergencyContacts.forEach(x => {
              x.hideDelete = false;
            });
            this.initForm();
            this.loading = false;
          }, error: () => {
            this.loading = false;
          }
        });
    }

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: `'${this.rosterProfile.FirstName} ${this.rosterProfile.LastName}' has been modified. Please save, or all changes will be lost.`,
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.isEdit) {
    //   if (this.isEdit) {
    //     this.loading = true;
    //     this.rosterProfileService.getRosterProfile(this.personID)
    //       .pipe(takeUntil(this.ngUnsubscribe))
    //       .subscribe({next:(res) => {
    //         this.rosterProfile = res;
    //         this.rosterProfile.EmergencyContacts.forEach(x => {
    //           x.hideDelete = false;
    //         });
    //         this.initForm();
    //         this.loading = false;
    //       }, error: () => {
    //         this.loading = false;
    //       }});
    //   }
    // }
    if (this.canEdit) {
      this.mainForm.enable();
    } else {
      this.mainForm.disable();
    }
  }

  initSelects() {
    this.initPrefix(false);
    // this.initEthnicity(false);
    this.initEducation(false);
    this.initMilitary(false);
    this.initMilitaryStatus(false);
    this.initMarriageStatus(false);
    this.initCitizenship(false);
    this.initShirtSize(false);
    this.initRace(false);
    this.initLanguages(false);
  }

  initForm() {
    this.isSearching = false;
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.mainForm.get('optOutCarrier').disable();
    this.mainForm.get('prefixID').setValue(this.rosterProfile.PrefixID);
    this.mainForm.get('firstName').setValue(this.rosterProfile.FirstName);
    this.mainForm.get('middleName').setValue(this.rosterProfile.MiddleName);
    this.mainForm.get('lastName').setValue(this.rosterProfile.LastName);
    this.mainForm.get('suffix').setValue(this.rosterProfile.Suffix);
    this.locationHeader = (this.rosterProfile.Suffix) ? this.rosterProfile.FirstName + ' ' + this.rosterProfile.LastName + ' ' + this.rosterProfile.Suffix : this.rosterProfile.FirstName + ' ' + this.rosterProfile.LastName;
    if (this.rosterProfile.DOB) {
      this.dateOfBirth = new Date(this.rosterProfile.DOB);
      this.mainForm.get('dateOfBirth').setValue(this.dateOfBirth);
    }
    if (this.rosterProfile.RegisteredVoter === true) {
      this.mainForm.get('voter').setValue('true');
    } else if (this.rosterProfile.RegisteredVoter === false) {
      this.mainForm.get('voter').setValue('false');
    }
    this.mainForm.get('gender').setValue(this.rosterProfile.Gender);
    this.mainForm.get('primaryPhoneNumber').setValue(this.rosterProfile.PrimaryPhoneNumber);
    this.mainForm.get('secondaryPhoneNumber').setValue(this.rosterProfile.SecondaryPhoneNumber);
    this.mainForm.get('thirdPhoneNumber').setValue(this.rosterProfile.ThirdPhoneNumber);
    this.mainForm.get('SMSOptIn').setValue(this.rosterProfile.SMSOptIn);
    if (this.rosterProfile.SecondaryPhoneNumber) {
      this.getSmsData(this.rosterProfile.SecondaryPhoneNumber);
    }
    this.mainForm.get('primaryEmail').setValue(this.rosterProfile.PrimaryEmailAddress);
    this.mainForm.get('EmailMsgOptIn').setValue(this.rosterProfile.PrimaryEmailOptOut.toString().toLowerCase());
    this.mainForm.get('secondaryEmail').setValue(this.rosterProfile.SecondaryEmailAddress);
    this.mainForm.get('AltEmailMsgOptIn').setValue(this.rosterProfile.SecondaryEmailOptOut.toString().toLowerCase());
    this.PrimaryPhoneFailedCommunication = this.rosterProfile.PrimaryPhoneFailedCommunication;
    this.SecondaryPhoneFailedCommunication = this.rosterProfile.SecondaryPhoneFailedCommunication;
    this.PrimaryEmailFailedCommunication = this.rosterProfile.PrimaryEmailFailedCommunication;
    this.SecondaryEmailFailedCommunication = this.rosterProfile.SecondaryEmailFailedCommunication;
    this.setCitizenshipId = this.rosterProfile.CitizenshipCountryID;
    this.setShirtSizeId = this.rosterProfile.ShirtSizeID;
    this.setMarriageStatusId = this.rosterProfile.MarriageStatusID;
    this.setMilitaryStatusId = this.rosterProfile.MilitaryStatusID;
    this.mainForm.get('schoolAttended').setValue(this.rosterProfile.SchoolAttended);
    this.setPrefixId = this.rosterProfile.PrefixID;
    // this.updatedRosterProfile.PersonID = this.rosterProfile.PersonID;
    this.selectedLanguages = this.rosterProfile.LanguageIDList;
    this.setPreferredLanguage = this.rosterProfile.PreferredLanguageID;
    this.setEthnicityId = this.rosterProfile.EthnicityID;
    this.setLanguageId = this.rosterProfile.LanguageID;
    this.setEducationId = this.rosterProfile.EducationID;
    this.setMilitaryId = this.rosterProfile.MilitaryID;
    this.selectedLanguages = this.rosterProfile.LanguageIDList;
    this.selectedRaces = this.rosterProfile.RaceIDList;
    if (this._addHelmetsToHardhatsCustomField()) {
      // add the Helmets to Hardhats attribute as necessary
      if (this.rosterProfile.CustomAttributes && this.rosterProfile.CustomAttributes.length > 0) {
        if (!this.rosterProfile.CustomAttributes.find(x => x.FieldName === 'Helmets To Hardhats')) {
          this.rosterProfile.CustomAttributes.push({
            OrganizationID: this.context.RootMemberOrgID,
            FieldName: 'Helmets To Hardhats',
            DataTypeEnum: 'YESNO',
            Value: 'No'
          });
        }
      } else {
        this.rosterProfile.CustomAttributes = [{
          OrganizationID: this.context.RootMemberOrgID,
          FieldName: 'Helmets To Hardhats',
          DataTypeEnum: 'YESNO',
          Value: 'No'
        }];
      }
    }
    if (this._donotRequirePrimaryEmailCustom() || this.rosterProfile.TypeAlias === 'Non-Member') {
      this.requirePrimaryEmail = false;
      this.mainForm.get('primaryEmail').clearValidators();
      this.mainForm.get('primaryEmail').updateValueAndValidity();
    } else {
      this.requirePrimaryEmail = true;
    }
    this.initSelects();
  }

  getSmsData(mobileNumber: string) {
    this.rosterProfileService.getRosterSMSOptOutStatus(mobileNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.smsData = res;
        }
      });
  }

  initPrefix(disable: boolean): void {
    this.inputObjHonorific = {
      labelText: 'Prefix',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setPrefixId,
      disabled: disable,
      canWrite: this.canEdit
    };
    this.lookupsDataService.getHonorificsLookupData().then((lookupData) => {
      this.inputObjHonorific.data = lookupData;
      this.inputObjHonorific = Object.assign({}, this.inputObjHonorific);
    });
  }

  getRaces() {
    this.lookupsDataService.getRaceLookupData().then((lookupData) => {
      this.races = lookupData;
      this.races.shift();
    });
  }

  initRace(disable: boolean): void {
    this.inputObjRace = {
      labelText: 'Race / Ethnicity',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.selectedRaces,
      disabled: disable,
      canWrite: this.canEdit
    };
    this.lookupsDataService.getRaceLookupData().then((lookupData) => {
      this.inputObjRace.data = lookupData;
      this.inputObjRace = Object.assign({}, this.inputObjRace);
    });
  }

  getRaceData(event: any) {
    if (event) {
      this.selectedRaces = event;
    } else {
      this.selectedRaces = [];
    }
    this.mainForm.markAsDirty();
    // this.mainForm.get('raceID').setValue(this.setRaceId);
  }

  initEthnicity(disable: boolean): void {
    this.inputObjEthnicity = {
      labelText: 'Ethnicity',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEthnicityId,
      disabled: disable,
      canWrite: this.canEdit
    };
    this.lookupsDataService.getEthnicityLookupData().then((lookupData) => {
      this.inputObjEthnicity.data = lookupData;
      this.inputObjEthnicity = Object.assign({}, this.inputObjEthnicity);
    });
  }

  initLanguages(disable, preferredChanged?: boolean) {
    this.lookupsDataService.getLanguagesLookupData(false).then((lookupData: any[]) => {
      if (preferredChanged) {
        //do nothing for now
      } else {
        this.preferredLanguageString = this.rosterProfile.PreferredLanguageID ?
          ' (Preferred ' + lookupData.find(x => x.ID === this.rosterProfile.PreferredLanguageID).Description + ')' : '';
      }

      this.inputObjLanguage = {
        labelText: 'Spoken Languages' + this.preferredLanguageString,
        optionValue: 'ID',
        optionLabel: 'Description',
        filter: true,
        requiredField: false,
        selectFirstValue: false,
        initSelected: this.selectedLanguages,
        disabled: disable,
        canWrite: this.canEdit,
        data: lookupData
      };
    });
  }

  setLanguageData(event) {
    if (event) {
      this.selectedLanguages = event;
    } else {
      this.selectedLanguages = [];
    }
    this.mainForm.markAsDirty();
  }

  setPreferredLanguageData(event) {
    console.log(event);
    this.preferredLanguageString = event ?
      ' (Preferred ' + this.inputObjLanguage.data.find(x => x.ID === event).Description + ')' : '';

    this.initLanguages(false, true);
    this.rosterProfile.PreferredLanguageID = event;
    this.mainForm.markAsDirty();
  }

  initEducation(disable: boolean): void {
    this.inputObjEducation = {
      labelText: 'Education Level',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEducationId,
      disabled: disable,
      canWrite: this.canEdit
    };
    this.lookupsDataService.getEducationLookupData().then((lookupData) => {
      this.inputObjEducation.data = lookupData;
      this.inputObjEducation = Object.assign({}, this.inputObjEducation);
    });
  }

  initMilitary(disable: boolean): void {
    this.inputObjMilitary = {
      labelText: 'Military Branch',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setMilitaryId,
      disabled: disable,
      canWrite: this.canEdit
    };
    this.lookupsDataService.getMilitaryLookupData().then((lookupData) => {
      this.inputObjMilitary.data = lookupData;
      this.inputObjMilitary = Object.assign({}, this.inputObjMilitary);
    });
  }

  initMilitaryStatus(disable: boolean): void {
    this.inputObjMilitaryStatus = {
      labelText: 'Military Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setMilitaryStatusId,
      disabled: disable,
      canWrite: this.canEdit
    };
    this.lookupsDataService.getMilitaryStatusLookupData().then((lookupData) => {
      this.inputObjMilitaryStatus.data = lookupData;
      this.inputObjMilitaryStatus = Object.assign({}, this.inputObjMilitaryStatus);
    });
  }

  getMilitaryStatusData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setMilitaryStatusId = event[0].ID;
    } else {
      this.setMilitaryStatusId = 0;
    }
    if (this.mainForm.get('militaryStatusID').value !== this.setMilitaryStatusId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('militaryStatusID').setValue(this.setMilitaryStatusId);
  }

  initMarriageStatus(disable: boolean): void {
    this.inputObjMarriageStatus = {
      labelText: 'Marital Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setMarriageStatusId,
      disabled: disable,
      canWrite: this.canEdit
    };
    this.lookupsDataService.getMarriageStatusLookupData().then((lookupData) => {
      this.inputObjMarriageStatus.data = lookupData;
      this.inputObjMarriageStatus = Object.assign({}, this.inputObjMarriageStatus);
    });
  }

  getMarriageStatusData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setMarriageStatusId = event[0].ID;
    } else {
      this.setMarriageStatusId = 0;
    }
    if (this.mainForm.get('marriageStatusID').value !== this.setMarriageStatusId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('marriageStatusID').setValue(this.setMarriageStatusId);
  }

  initShirtSize(disable: boolean): void {
    this.inputObjShirtSize = {
      labelText: 'T-Shirt Size',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setShirtSizeId,
      disabled: disable,
      canWrite: this.canEdit
    };
    this.lookupsDataService.getShirtSizeLookupData().then((lookupData) => {
      this.inputObjShirtSize.data = lookupData;
      this.inputObjShirtSize = Object.assign({}, this.inputObjShirtSize);
    });
  }

  getShirtSizeData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setShirtSizeId = event[0].ID;
    } else {
      this.setShirtSizeId = 0;
    }
    if (this.mainForm.get('shirtSizeID').value !== this.setShirtSizeId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('shirtSizeID').setValue(this.setShirtSizeId);
  }

  initCitizenship(disable: boolean): void {
    this.inputObjCitizenship = {
      labelText: 'Current Citizenship',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setCitizenshipId,
      disabled: disable,
      canWrite: this.canEdit
    };
    this.lookupsDataService.getCitizenshipLookupData().then((lookupData) => {
      this.inputObjCitizenship.data = lookupData;
      this.inputObjCitizenship = Object.assign({}, this.inputObjCitizenship);
    });
  }

  getCitizenshipData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setCitizenshipId = event[0].ID;
    } else {
      this.setCitizenshipId = 0;
    }
    if (this.mainForm.get('citizenshipID').value !== this.setCitizenshipId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('citizenshipID').setValue(this.setCitizenshipId);
  }

  getHonorificData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setPrefixId = event[0].ID;
    } else {
      this.setPrefixId = 0;
    }

    if (this.mainForm.get('prefixID').value !== this.setPrefixId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('prefixID').setValue(this.setPrefixId);
  }

  getEthnicityData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setEthnicityId = event[0].ID;
    } else {
      this.setEthnicityId = 0;
    }
    if (this.mainForm.get('ethnicityID').value !== this.setEthnicityId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('ethnicityID').setValue(this.setEthnicityId);
  }


  getEducationData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setEducationId = event[0].ID;
    } else {
      this.setEducationId = 0;
    }
    if (this.mainForm.get('educationID').value !== this.setEducationId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('educationID').setValue(this.setEducationId);
  }

  getMilitaryData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setMilitaryId = event[0].ID;
    } else {
      this.setMilitaryId = 0;
    }
    if (this.mainForm.get('militaryID').value !== this.setMilitaryId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('militaryID').setValue(this.setMilitaryId);
  }

  addEmergencyContact() {
    this.showEmergency = true;
    this.rosterProfile.EmergencyContacts.push({
      FirstName: null,
      LastName: null,
      Relationship: null,
      Address1: null,
      Address2: null,
      City: null,
      StateProvinceID: null,
      Zip: null,
      CountryID: null,
      PhoneTypeID: null,
      PhoneNumber: null,
      EmailAddress: null,
      SequenceNumber: this.rosterProfile.EmergencyContacts.length === 0 ? 1 : 2,
      hideDelete: true
    });
  }

  deleteEmergencyContact(contact, index) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove ' + contact.FirstName + ' ' + contact.LastName + ' as a contact?',
      acceptLabel: 'Continue',
      rejectLabel: 'Cancel',
      header: 'Delete Contact',
      icon: 'pi pi-trash',
      accept: () => {
        if (this.rosterProfile.EmergencyContacts[index].LastName && this.rosterProfile.EmergencyContacts[index].FirstName) {
          this.rosterProfile.EmergencyContacts.splice(index, 1);
          this.mainForm.markAsDirty();
          this.processData();
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  updateEmergencyAddress(event, i) {
    this.mainForm.markAsDirty();
    this.rosterProfile.EmergencyContacts[i] = event;
    this.processData();
  }

  updateEmergencyPriority() {
    if (this.rosterProfile.EmergencyContacts.length > 1) {
      this.mainForm.markAsDirty();
      this.rosterProfile.EmergencyContacts[0].SequenceNumber = 2;
      this.rosterProfile.EmergencyContacts[1].SequenceNumber = 1;
      this.rosterProfile.EmergencyContacts = this.rosterProfile.EmergencyContacts.reverse();
      this.processData();
    }
  }


  processData() {
    if (this.mainForm.valid && this.mainForm.dirty && !this.isSearching) {
      this.existingMessage = false;
      this.rosterProfile.PrefixID = this.mainForm.get('prefixID').value;
      this.rosterProfile.FirstName = this.mainForm.get('firstName').value;
      this.rosterProfile.MiddleName = this.mainForm.get('middleName').value;
      this.rosterProfile.LastName = this.mainForm.get('lastName').value;
      this.rosterProfile.Suffix = this.mainForm.get('suffix').value;
      this.rosterProfile.DOB = transformDateTimeToDateOnly(this.mainForm.get('dateOfBirth').value);
      this.rosterProfile.Gender = this.mainForm.get('gender').value;
      this.rosterProfile.PrimaryPhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
      this.rosterProfile.SecondaryPhoneNumber = this.mainForm.get('secondaryPhoneNumber').value;
      this.rosterProfile.ThirdPhoneNumber = this.mainForm.get('thirdPhoneNumber').value;
      this.rosterProfile.SMSOptIn = this.mainForm.get('SMSOptIn').value;
      if (this.mainForm.get('primaryEmail').value === '') {
        this.rosterProfile.PrimaryEmailAddress = null;
      } else {
        this.rosterProfile.PrimaryEmailAddress = this.mainForm.get('primaryEmail').value;
      }
      this.rosterProfile.PrimaryEmailOptOut = (this.mainForm.get('EmailMsgOptIn').value === 'true');
      if (this.mainForm.get('secondaryEmail').value === '') {
        this.rosterProfile.SecondaryEmailAddress = null;
      } else {
        this.rosterProfile.SecondaryEmailAddress = this.mainForm.get('secondaryEmail').value;
      }
      this.rosterProfile.SecondaryEmailOptOut = (this.mainForm.get('AltEmailMsgOptIn').value === 'true');
      this.rosterProfile.EthnicityID = this.setEthnicityId;
      this.rosterProfile.LanguageID = this.setLanguageId;
      this.rosterProfile.EducationID = this.setEducationId;
      //  this.rosterProfile.ClassificationID = this.rosterProfile.ClassificationID;
      if (this.setMilitaryStatusId === 0) {
        this.rosterProfile.MilitaryStatusID = null;
      } else {
        this.rosterProfile.MilitaryStatusID = this.setMilitaryStatusId;
      }
      if (this.setMilitaryId === 0) {
        this.rosterProfile.MilitaryID = null;
      } else {
        this.rosterProfile.MilitaryID = this.setMilitaryId;
      }
      this.rosterProfile.LanguageIDList = this.selectedLanguages;
      this.rosterProfile.CitizenshipCountryID = this.setCitizenshipId;
      this.rosterProfile.ShirtSizeID = this.setShirtSizeId;
      this.rosterProfile.MarriageStatusID = this.setMarriageStatusId;
      this.rosterProfile.RaceIDList = this.selectedRaces;
      this.rosterProfile.SchoolAttended = this.mainForm.get('schoolAttended').value;
      if (this.mainForm.get('voter').value === 'true') {
        this.rosterProfile.RegisteredVoter = true;
      } else if (this.mainForm.get('voter').value === 'false') {
        this.rosterProfile.RegisteredVoter = false;
      }
      if (this._addHelmetsToHardhatsCustomField()) {
        // save the Helmets to Hardhats attribute, or add, as necessary
        if (this.rosterProfile.CustomAttributes && this.rosterProfile.CustomAttributes.length > 0) {
          if (!this.rosterProfile.CustomAttributes.find(x => x.FieldName === 'Helmets To Hardhats')) {
            this.rosterProfile.CustomAttributes.push({
              OrganizationID: this.context.RootMemberOrgID,
              FieldName: 'Helmets To Hardhats',
              DataTypeEnum: 'YESNO',
              Value: 'No'
            });
          }
        } else {
          this.rosterProfile.CustomAttributes = [{
            OrganizationID: this.context.RootMemberOrgID,
            FieldName: 'Helmets To Hardhats',
            DataTypeEnum: 'YESNO',
            Value: 'No'
          }];
        }
      }
      this.saveForm();
    }
  }

  saveForm() {
    this.rosterProfileService.updateRosterProfile(this.personID, this.rosterProfile)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.rosterProfile.EmergencyContacts.forEach(x => {
            x.hideDelete = false;
          });
          this.ref.destroy();
          this.confirmationService.close();
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: `'${this.rosterProfile.FirstName} ${this.rosterProfile.LastName}' has been successfully updated`
          });
          this.mainForm.markAsPristine();
          this.mainForm.markAsUntouched();
          this.isSaving = false;
          this.existingMessage = false;
        }, error: () => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          this.isSaving = false;
        }
      });
  }

  updateCustomAttribute(index: number, event): void {
    this.mainForm.markAsDirty();
    this.rosterProfile.CustomAttributes[index] = event;
    // reassign to update fields
    const stringReplace = '{"ID":null,"Description":"--","Abbreviation":""},';
    const newCusApp: string = JSON.stringify(this.rosterProfile.CustomAttributes).replaceAll(stringReplace, '');
    this.rosterProfile.CustomAttributes = [];
    this.rosterProfile.CustomAttributes = JSON.parse(newCusApp);
    if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
      this.existingMessage = true;
      this.openDialog();
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  toggleSMS() {
    if (this.mainForm.get('SMSOptIn').value === 'true') {
      this.mainForm.get('SMSOptIn').setValue('true');
    } else {
      this.mainForm.get('SMSOptIn').setValue('false');
    }
  }

  toggleEmailMsg() {
    if (this.mainForm.get('EmailMsgOptIn').value === 'true') {
      this.mainForm.get('EmailMsgOptIn').setValue('true');
    } else {
      this.mainForm.get('EmailMsgOptIn').setValue('false');
    }
  }

  toggleAltEmailMsgAlt() {
    if (this.mainForm.get('AltEmailMsgOptIn').value === 'true') {
      this.mainForm.get('AltEmailMsgOptIn').setValue('true');
    } else {
      this.mainForm.get('AltEmailMsgOptIn').setValue('false');
    }
  }

  mobileCheckValidation(phone) {
    this.phoneValidationUtility.phoneValidation(phone).then((res) => {
      this.rosterProfile.SecondaryPhoneVerified = res;
      this.mainForm.markAsDirty();
    });
  }

  smsDialog(): void {
    this.dialogService.open(SmsDialogComponent, {
      data: this.smsData,
      header: 'Opted Out with Carrier',
      width: '75%',
      height: '55%'
    });
  }

  validatePii(key: string, value: string) {
    this.isSearching = true;
    this.isEditing = false;
    const piiData: PiiValidateModel = {
      SSN: null,
      SIN: null,
      PrimaryEmailAddress: null,
      PersonID: this.personID,
      MatchType: key
    };
    piiData[key] = value;
    this.piiValidateService.validatePii(piiData)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.isSearching = false;
          if (res.MatchType) {
            const ref = this.dialogService.open(PiiDuplicateDialogComponent, {
              data: {
                piiData: res,
                value
              },
              header: 'Duplicate User(s) Found',
              width: '60%',
            });
            ref.onClose.subscribe({
              next: () => {
                switch (key) {
                  case 'SSN' : {
                    this.mainForm.get('SSN').setValue(null);
                    break;
                  }
                  case 'SIN' : {
                    this.mainForm.get('SIN').setValue(null);
                    break;
                  }
                  case 'PrimaryEmailAddress' : {
                    this.mainForm.get('primaryEmail').setValue(null);
                    break;
                  }
                }
              }
            });
          }
        }
      });
  }
}
