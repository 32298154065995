import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {CreateSurveyJotFormModel, CreateSurveyModel, SurveyCommunicationModel, SurveyModel, SurveysGridModel, SurveyStatisticsGridModel, UpdateSurveyModel} from '../models/surveys.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class SurveysService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getSurveysPrimeNG(dataMode: string, organizationId: number, context: string, filterData): Observable<SurveysGridModel> {
    Userpilot.track('Survey Builder List');
    if(!organizationId) {
      organizationId = this.contextService.contextObject.RootMemberOrgID;
    }
    return this.http.post<SurveysGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/grid/${organizationId}?context=${context}&mode=${dataMode}`, filterData);
  }

  getSurvey(surveyId: number): Observable<SurveyModel> {
    return this.http.get<SurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/${surveyId}`);
  }

  updateSurvey(surveyId: number, modelData: UpdateSurveyModel): Observable<SurveyModel> {
    return this.http.put<SurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/${surveyId}`, modelData);
  }

  updateSurveyPrimeNg(personId: number, modelData: SurveyModel): Observable<SurveyModel> {
    return this.http.post<SurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/questionlist/reorder?personid=${personId}`, modelData);
  }

  createSurvey(modelData: CreateSurveyModel): Observable<SurveyModel> {
    return this.http.post<SurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/create`, modelData);
  }

  createSurveyJotForm(modelData: CreateSurveyJotFormModel): Observable<SurveyModel> {
    return this.http.post<SurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/create`, modelData);
  }

  copySurvey(surveyId: number): Observable<SurveyModel> {
    return this.http.get<SurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/${surveyId}/copy`);
  }

  deleteSurvey(surveyId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/remove/${surveyId}`);
  }

  // getSurveyStatisticsKGrid(surveyId: number): Observable<SurveyStatisticsGridModel> {
  //   return this.http.get<SurveyStatisticsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/${surveyId}/statsgrid`);
  // }

  getSurveyStatistics(surveyId: number): Observable<SurveyStatisticsGridModel> {
    return this.http.get<SurveyStatisticsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/${surveyId}/statsgrid`);
  }

  getCommunicationsBySurveyId(surveyId: number): Observable<SurveyCommunicationModel[]> {
    return this.http.get<SurveyCommunicationModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/surveycommunications/${surveyId}`);
  }

  getStatisticsByStatisticsId(surveyId: number, statisticId: number): Observable<SurveyModel> {
    return this.http.get<SurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/${surveyId}/statistics/${statisticId}`);
  }
}
