<form [formGroup]="mainForm" class="margin-top-10 padding-bottom-50">
  <div class="p-grid p-fluid">
      <div class="col-md-3 col-sm-2 col-xs-12 margin-top-10">
        <label>Name </label>
        <input type="text" pInputText  formControlName="name">
<!--        <p-calendar [(ngModel)]="tempWorkMonthDate" appendTo="body" placeholder="mm/yyyy" view="month" dateFormat="mm/yy" [showIcon]="true" yearRange="1950:2030" [ngModelOptions]="{standalone: true}"></p-calendar>-->
      </div>
      <div class="col-md-3 col-sm-2 col-xs-12 margin-top-10">
        <label>Email </label>
        <input type="text" pInputText  formControlName="email">
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12  margin-top-10">
        <label>Phone </label>
        <p-inputMask type="text" pInputText formControlName="phone" [className]="'form-input no-border'" mask="(999) 999-9999"
                     unmask="true" tabindex="0"></p-inputMask>
      </div>
    <div class="col-md-12 col-sm-2 col-xs-12 margin-top-10">
      <label>Subject </label>
      <input type="text" pInputText  formControlName="subject">
    </div>
    <div class="col-md-12 col-sm-2 col-xs-12 margin-top-10">
      <label>Description </label>
      <textarea type="text" pInputTextarea rows="8" formControlName="description"></textarea>
    </div>
    <div class="col-md-12 text-align-right margin-top-10">
      <div class="action-buttons float-right">
        <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times"
                pTooltip="Cancel" tooltipPosition="bottom"
                (click)="cancel()"></button>
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                  tooltipPosition="bottom"
                  (click)="processData()" [className]="'inline-button'"
                  [disabled]="mainForm.invalid || !mainForm.dirty"></button>
        }
        @if (isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  </div>
</form>
