import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {MessageService} from 'primeng/api';
import {StateLookupModel} from '../../../models/lookups.model';
import {AddressModel, intCandidatesModel, SmartyStreetsIntCandidatesModel, SmartyStreetsModel, SmartyStreetsSuggestionsModel, suggestionsModel, VerifyAddressModel} from '../../../models/addresses.model';
import {AddressesService} from '../../../services/addresses.service';
import {LookupsService} from '../../../services/lookups.service';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {MapsService} from '../../../services/maps.service';
import {MapsDialogComponent} from '../maps-dialog/maps-dialog.component';
import {AutoComplete} from 'primeng/autocomplete';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})

export class AddressFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  addressForm: FormGroup;
  inputObjState: GenericSelectModel;
  inputObjCountry: GenericSelectModel;
  inputObjContactCategory: GenericSelectModel;
  setContactCategoryId: number;
  setStateId: number;
  setCountryId: number;
  international: boolean;
  internationalEntries: boolean;
  suggestAddress: boolean = false;
  suggestAddressJSON: SmartyStreetsModel;
  suggestions: SmartyStreetsSuggestionsModel[];
  intCandidateSuggestions: SmartyStreetsIntCandidatesModel[];
  autocompleteData: suggestionsModel = {} as suggestionsModel;
  autocompleteItemData: SmartyStreetsSuggestionsModel[] = [];
  intAutocompleteEntryData: intCandidatesModel = {} as intCandidatesModel;
  intAutocompleteEntryItemData: SmartyStreetsIntCandidatesModel[] = [];
  verifyAddress: VerifyAddressModel = {} as VerifyAddressModel;
  latitude: number;
  longitude: number;
  currentAddress: string;
  cancelSuggestAddress: boolean;

  @ViewChild('autocomplete') autocompleteElement: AutoComplete;

  @Input() showZipPlus4: boolean;
  @Input() showCounty: boolean;
  @Input() required: boolean;
  @Input() requiredCounty: boolean;
  @Input() canTabWrite: boolean;
  @Input() mapDialogDataTitle: string;
  @Input() mapDialogHeader: string;
  @Input() incomingAddress: AddressModel;
  @Input() showCategory: boolean = false;
  @Output() outgoingAddress: EventEmitter<AddressModel> = new EventEmitter<AddressModel>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef,
              private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private addressesService: AddressesService,
              private lookupsService: LookupsService, private tenantConfigDataService: TenantConfigDataService,
              private mapsService: MapsService, private dialogService: DialogService) {
    this.addressForm = this.formBuilder.group({
      category: new FormControl(null, Validators.required),
      country: new FormControl(null),
      streetAddress: new FormControl(null, Validators.maxLength(100)),
      streetAddress2: new FormControl(null, Validators.maxLength(100)),
      city: new FormControl(null, Validators.maxLength(100)),
      state: new FormControl(null),
      postalCode: new FormControl(null, Validators.maxLength(10)),
      zip4: new FormControl(null, Validators.maxLength(4)),
      county: new FormControl(null, Validators.maxLength(100))
    });
  }

  ngOnInit(): void {
    this.suggestAddress = this.tenantConfigDataService.getBooleanValue('SUGGESTADDRESS');
    this.addressForm.markAsPristine();
    this.addressForm.markAsUntouched();

    this.addressForm.get('country').valueChanges.subscribe({
      next: () => {
        this.enableDisableFormParts();
      }
    });

    this.addressForm.valueChanges.subscribe({
      next: () => {
        if (this.addressForm.valid && this.addressForm.dirty) {
          this.processData();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.incomingAddress) {
      if (this.incomingAddress) {
        this.initForm();
      }
    }
    if (changes.required) {
      if (this.required) {
        this.addressForm.get('country').setValidators(Validators.required);
        this.addressForm.get('country').updateValueAndValidity();
        this.addressForm.get('streetAddress').setValidators(Validators.required);
        this.addressForm.get('streetAddress').updateValueAndValidity();
        this.addressForm.get('city').setValidators(Validators.required);
        this.addressForm.get('city').updateValueAndValidity();
        this.addressForm.get('state').setValidators(Validators.required);
        this.addressForm.get('state').updateValueAndValidity();
        this.addressForm.get('postalCode').setValidators(Validators.required);
        this.addressForm.get('postalCode').updateValueAndValidity();
        this.addressForm.updateValueAndValidity();
      }
    }
    if (changes.requiredCounty) {
      if (this.requiredCounty) {
        this.addressForm.get('county').setValidators(Validators.required);
        this.addressForm.get('county').updateValueAndValidity();
        this.addressForm.updateValueAndValidity();
      }
    }
    if (changes.canTabWrite) {
      if (this.canTabWrite) {
        for (const field in this.addressForm.controls) {
          if (this.addressForm.get(field).disabled) {
            this.addressForm.get(field).disable();
          } else {
            this.addressForm.get(field).enable();
          }
        }
        this.enableDisableFormParts();
      } else {
        this.addressForm.disable();
      }
    }
  }

  initSelects(): void {
    if(this.showCategory) {
      this.initContactCategory(false);
    } else {
      this.addressForm.get('category').clearValidators();
      this.addressForm.get('category').updateValueAndValidity();
    }
    this.initCountry(false);
    this.initState(this.setCountryId === null || this.setCountryId === undefined);
  }

  initContactCategory(disable: boolean): void {
    this.inputObjContactCategory = {
      labelText: 'Category',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: null,
      disabled: disable,
      canWrite: this.canTabWrite,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getContactCategoriesLookupData().then((lookupData) => {
      this.inputObjContactCategory.data = lookupData;
      this.inputObjContactCategory = Object.assign({}, this.inputObjContactCategory);
    });
  }

  getContactCategoryData(event:any) {
    if (event && event[0] && event[0].Description) {
      this.addressForm.get('category').setValue(event[0].Description.toUpperCase());
    } else {
      this.setContactCategoryId = 0;
    }
    this.addressForm.markAsDirty();
  }

  enableDisableFormParts(): void {
    if (this.addressForm) {
      if (!this.addressForm.get('country').value && this.addressForm.get('country').value !== '') {
        this.addressForm.get('streetAddress').disable();
        this.addressForm.get('streetAddress2').disable();
        this.addressForm.get('city').disable();
        this.addressForm.get('state').disable();
        this.addressForm.get('postalCode').disable();
        this.addressForm.get('zip4').disable();
        this.addressForm.get('county').disable();
      } else {
        this.addressForm.get('streetAddress').enable();
        this.addressForm.get('streetAddress2').enable();
        this.addressForm.get('city').enable();
        this.addressForm.get('state').enable();
        this.addressForm.get('postalCode').enable();
        this.addressForm.get('zip4').enable();
        this.addressForm.get('county').enable();
      }
    }
  }

  initForm(): void {
    this.addressForm.markAsPristine();
    this.addressForm.markAsUntouched();

    this.setCountryId = this.incomingAddress.CountryID;
    this.addressForm.get('country').setValue(this.incomingAddress.CountryID);
    this.international = (this.incomingAddress.CountryID !== 1);
    // if (this.suggestAddress) {
    //   if (this.international) {
    //     // this.addressForm.get('streetAddress').setValue({items: [{address_text: this.currentOrganizations.Address1}]});
    //     // this.addressForm.get('streetAddress').setValue({street: this.currentOrganizations.Address1});
    //     this.addressForm.get('streetAddress').setValue(this.currentOrganizations.Address1);
    //   } else {
    //     this.addressForm.get('streetAddress').setValue({street_line: this.currentOrganizations.Address1});
    //   }
    // } else {
    this.addressForm.get('streetAddress').setValue(this.incomingAddress.Address1);
    // }
    this.addressForm.get('streetAddress2').setValue(this.incomingAddress.Address2);
    this.addressForm.get('city').setValue(this.incomingAddress.City);
    this.setStateId = this.incomingAddress.StateProvinceID;
    this.addressForm.get('state').setValue(this.incomingAddress.StateProvinceID);
    this.addressForm.get('postalCode').setValue(this.incomingAddress.Zip);
    this.addressForm.get('zip4').setValue(this.incomingAddress.ZipPlus4);
    this.addressForm.get('county').setValue(this.incomingAddress.County);
    this.latitude = this.incomingAddress.Latitude;
    this.longitude = this.incomingAddress.Longitude;

    this.initSelects();
  }

  search(event:any): void {
    if (typeof this.addressForm.get('streetAddress').value !== 'object') {
      this.currentAddress = this.addressForm.get('streetAddress').value;
    }
    this.autocompleteItemData = [];
    if (this.suggestAddress === true && this.cancelSuggestAddress === false && (this.setCountryId === 1 || this.setCountryId === 5)) {
      // this.addressForm.get('streetAddress').valueChanges
      //   .pipe(debounceTime(500))
      //   .subscribe({next:(value) => {
      const reAlpha = new RegExp('[A-Za-z]');
      const reNum = new RegExp('[0-9]');
      // this.autocompleteItemData = [];
      if (reAlpha.test(event.query) && reNum.test(event.query)) {
        this.suggestAddressJSON = {
          country: (this.setCountryId === 5) ? 'CAN' : 'USA',
          prefix: event.query,
          city_filter: this.addressForm.get('city').value,
          state_filter: null
        };
        this.addressesService.getAddressSuggestions(this.suggestAddressJSON)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.suggestions = res.suggestions;
              if (this.suggestions) {
                this.suggestions.forEach(suggestion => {
                  this.autocompleteItemData.push({
                    street_line: suggestion.street_line,
                    city: suggestion.city,
                    state: suggestion.state,
                    zipcode: suggestion.zipcode
                  });
                });
                this.autocompleteData.items = this.autocompleteItemData;
              }
            }
          });
      }
      //}});
    } else {
      this.cancelSuggestAddress = false;
      this.addressForm.get('streetAddress').setValue(this.currentAddress);
    }
  }

  applySuggestion(event:any): void {
    if (this.cancelSuggestAddress === false) {
      this.clearLatLong('streetAddress');
      if (event?.value?.street_line) {
        this.addressForm.get('streetAddress').setValue(event?.value?.street_line);
        this.addressForm.get('streetAddress2').setValue(null);
      }
      if (event.value?.city) {
        this.addressForm.get('city').setValue(event.value?.city);
      }
      if (event.value?.state) {
        let stSelected: StateLookupModel[];
        this.lookupsService.getStatesLookup(this.addressForm.get('country').value)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              stSelected = res.filter(x => x.Abbreviation === event.value?.state);
              if (stSelected && stSelected[0].ID) {
                this.setStateId = stSelected[0].ID;
                this.initState(false);
                this.addressForm.get('state').setValue(this.setStateId);
              }
            }
          });
      }
      if (event.value?.zipcode) {
        this.addressForm.get('postalCode').setValue(event.value?.zipcode);
        if (this.setCountryId === 1) {
          this.verifyAddress.addressType = 'us-street-components';
          this.verifyAddress.street = encodeURIComponent(event.value?.street_line);
          this.verifyAddress.city = encodeURIComponent(event.value?.city);
          this.verifyAddress.state = encodeURIComponent(event.value?.state);
          this.verifyAddress.zipcode = encodeURIComponent(event.value?.zipcode);
          this.mapsService.verifyAddress(this.verifyAddress)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.addressForm.get('county').setValue(res[0]?.metadata?.county_name);
                this.longitude = res[0]?.metadata?.longitude;
                this.latitude = res[0]?.metadata?.latitude;
              }
            });
        }
      }
      this.addressForm.markAsDirty();
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Your address suggestion has been applied. Please review the new address in it\'s entirety for accuracy. Thanks so much!'
      });
      this.autocompleteData.items = [];
      this.autocompleteElement?.hide();
    } else {
      this.cancelSuggestAddress = false;
      this.addressForm.get('streetAddress').setValue(this.currentAddress);
    }
  }

  // cancelSuggestions(): void {
  //   this.cancelSuggestAddress = true;
  //   this.internationalEntries = false;
  //   this.autocompleteElement?.hide();
  // }

  searchIntCandidates(event:any): void {
    if (typeof this.addressForm.get('streetAddress').value !== 'object') {
      this.currentAddress = this.addressForm.get('streetAddress').value;
    }
    this.intAutocompleteEntryItemData = [];
    if (this.suggestAddress === true && this.cancelSuggestAddress === false && (this.setCountryId === 1 || this.setCountryId === 5)) {
      if (event?.value?.address_text) {
        this.addressForm.get('streetAddress').setValue(event?.value?.address_text);
      }
      const reAlpha = new RegExp('[A-Za-z]');
      const reNum = new RegExp('[0-9]');
      if ((reAlpha.test(event.query) && reNum.test(event.query)) || (reAlpha.test(event.value?.address_text) && reNum.test(event.value?.address_text))) {
        if (event.query !== undefined) {
          this.suggestAddressJSON = {
            country: (this.setCountryId === 5) ? 'CAN' : 'USA',
            prefix: event.query,
            address_id: null
          };
        } else {
          this.suggestAddressJSON = {
            country: (this.setCountryId === 5) ? 'CAN' : 'USA',
            prefix: event.value?.address_text,
            address_id: event.value?.address_id
          };
        }
        this.addressesService.getInternationalAddressSuggestions(this.suggestAddressJSON)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.intCandidateSuggestions = [];
              if (res.candidates[0].entries !== undefined) {
                if (res.candidates) {
                  this.internationalEntries = (res.candidates[0].entries !== undefined);
                  res.candidates.forEach((x) => {
                    this.intCandidateSuggestions.push({
                      entries: x.entries,
                      address_text: x.address_text,
                      address_id: x.address_id
                    });
                  });
                }
                if (this.intCandidateSuggestions) {
                  this.intCandidateSuggestions.forEach(suggestion => {
                    this.intAutocompleteEntryItemData.push({
                      entries: suggestion.entries,
                      address_text: suggestion.address_text,
                      address_id: suggestion.address_id
                    });
                  });
                  this.intAutocompleteEntryData.items = this.intAutocompleteEntryItemData;
                }
              } else {
                this.applyIntSuggestion({
                  street: res.candidates[0]?.street,
                  locality: res.candidates[0]?.locality,
                  administrative_area: res.candidates[0]?.administrative_area,
                  postal_code: res.candidates[0]?.postal_code
                });
                this.intAutocompleteEntryData.items = [];
                this.autocompleteElement?.hide();
              }
            }
          });
      }
    } else {
      this.cancelSuggestAddress = false;
      this.addressForm.get('streetAddress').setValue(this.currentAddress);
    }
  }

  applyIntSuggestion(event:any): void {
    this.internationalEntries = false;
    if (this.cancelSuggestAddress === false) {
      if (event.street) {
        this.addressForm.get('streetAddress').setValue(event.street);
        this.addressForm.get('streetAddress2').setValue(null);
      }
      if (event.locality) {
        this.addressForm.get('city').setValue(event.locality);
      }
      if (event.administrative_area) {
        let stSelected: StateLookupModel[];
        this.lookupsService.getStatesLookup(this.addressForm.get('country').value)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              stSelected = res.filter(x => x.Abbreviation === event.administrative_area);
              if (stSelected && stSelected[0].ID) {
                this.setStateId = stSelected[0].ID;
                this.initState(false);
                this.addressForm.get('state').setValue(this.setStateId);
              }
            }
          });
      }
      if (event.postal_code) {
        this.addressForm.get('postalCode').setValue(event.postal_code);
        this.verifyAddress.addressType = 'us-street-components';
        this.verifyAddress.street = encodeURIComponent(event.street);
        this.verifyAddress.city = encodeURIComponent(event.locality);
        this.verifyAddress.state = encodeURIComponent(event.administrative_area);
        this.verifyAddress.zipcode = encodeURIComponent(event.postal_code);
        this.mapsService.verifyAddress(this.verifyAddress)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.addressForm.get('county').setValue(res[0]?.metadata?.county_name);
              this.longitude = res[0]?.metadata?.longitude;
              this.latitude = res[0]?.metadata?.latitude;
            }
          });
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Your address suggestion has been applied. Please review the new address in it\'s entirety for accuracy. Thanks so much!'
      });
    }
  }

  initCountry(disable: boolean): void {
    this.inputObjCountry = {
      labelText: 'Country',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: this.required,
      selectFirstValue: false,
      initSelected: this.setCountryId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getCountriesLookupData().then((lookupData) => {
      this.inputObjCountry.data = lookupData;
      this.inputObjCountry = Object.assign({}, this.inputObjCountry);
    });
  }

  getCountryData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.setCountryId = event[0].ID;
    } else {
      this.setCountryId = null;
    }
    if (this.setCountryId !== null) {
      this.initState(false);
      this.international = event[0].Description !== 'United States';
      this.internationalEntries = this.international;
    } else {
      this.initState(true);
    }
    this.addressForm.markAsDirty();
    this.addressForm.get('country').setValue(this.setCountryId);
    this.addressForm.get('streetAddress').setValue(null);
    this.addressForm.get('streetAddress2').setValue(null);
    this.addressForm.get('city').setValue(null);
    this.setStateId = null;
    this.addressForm.get('state').setValue(null);
    this.addressForm.get('postalCode').setValue(null);
    this.addressForm.get('zip4').setValue(null);
    this.addressForm.get('county').setValue(null);
    this.longitude = null;
    this.latitude = null;
    this.clearLatLong('country');
    if (this.setCountryId === null) {
      this.clearLatLong('state');
      this.initState(true);
    }
    this.processData();
  }

  initState(disable: boolean): void {
    this.inputObjState = {
      labelText: 'State/Province',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: this.required,
      selectFirstValue: false,
      initSelected: this.setStateId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (this.setCountryId) {
      this.lookupsDataService.getStatesLookupData(this.setCountryId).then((lookupData) => {
        this.inputObjState.data = lookupData;
        this.inputObjState = Object.assign({}, this.inputObjState);
      });
    }
  }

  getStateData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.setStateId = event[0].ID;
    } else {
      this.setStateId = null;
    }
    this.addressForm.markAsDirty();
    this.addressForm.get('state').setValue(this.setStateId);
    this.clearLatLong('state');
  }

  processData(): void {
    if (this.addressForm.dirty) {
      const addressToSave: AddressModel = {} as AddressModel;
      addressToSave.CountryID = this.addressForm.get('country').value;
      // if (this.suggestAddress) {
      //   if (this.addressForm.get('streetAddress').value && typeof this.addressForm.get('streetAddress').value === 'object') {
      //     if (this.addressForm.get('streetAddress').value.street_line) {
      //       this.updatedOrganizations.Address1 = this.addressForm.get('streetAddress').value.street_line;
      //     }
      //   } else {
      //     this.updatedOrganizations.Address1 = this.addressForm.get('streetAddress').value;
      //   }
      // } else {
      addressToSave.Address1 = this.addressForm.get('streetAddress').value;
      // }
      addressToSave.Address2 = this.addressForm.get('streetAddress2').value;
      addressToSave.City = this.addressForm.get('city').value;
      addressToSave.StateProvinceID = this.addressForm.get('state').value;
      addressToSave.Zip = this.addressForm.get('postalCode').value;
      addressToSave.ZipPlus4 = this.addressForm.get('zip4').value;
      addressToSave.County = this.addressForm.get('county').value;
      addressToSave.Latitude = this.latitude;
      addressToSave.Longitude = this.longitude;
      addressToSave.Invalid = this.addressForm.invalid;
      addressToSave.PrimaryContactCategory = this.addressForm.get('category').value;
      this.outgoingAddress.emit(addressToSave);
    }
  }

  showMap(): void {
    if (typeof this.addressForm.get('state').value === 'number') {
      this.lookupsDataService.getStatesLookupData(this.setCountryId).then((res: StateLookupModel[]) => {
        const stateDesc = res.filter(x => x.ID === this.addressForm.get('state').value)[0]?.Description;
        this.dialogService.open(MapsDialogComponent, {
          data: {
            latitude: this.latitude,
            longitude: this.longitude,
            title: this.mapDialogDataTitle,
            street: this.addressForm.get('streetAddress').value,
            city: this.addressForm.get('city').value,
            state: stateDesc,
            zip: this.addressForm.get('postalCode').value
          },
          header: this.mapDialogHeader,
          width: '90%',
          height: '70%'
        });
      });
    } else {
      this.dialogService.open(MapsDialogComponent, {
        data: {
          latitude: this.latitude,
          longitude: this.longitude,
          title: this.mapDialogDataTitle,
          street: this.addressForm.get('streetAddress').value,
          city: this.addressForm.get('city').value,
          state: this.addressForm.get('state').value,
          zip: this.addressForm.get('postalCode').value
        },
        header: this.mapDialogHeader,
        width: '90%',
        height: '70%'
      });
    }
  }

  clearLatLong(formControlName: string): void {
    if (this.latitude !== null && this.longitude !== null && this.addressForm.get(formControlName) && (this.addressForm.get(formControlName).value === null || this.addressForm.get(formControlName).value === '')) {
      this.latitude = null;
      this.longitude = null;
      this.processData();
    } else {
      this.processData();
    }
  }
}
