<div class="p-fluid p-grid">
  @if (isRosterTable) {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <form [formGroup]="mainForm" class="full-width">
        <div class="p-fluid p-grid">
          @if (currentPage !== 'projectReports') {
            <div class="col-md-3 col-sm-3 col-xs-12">
              <app-dist-list-select class="full-width" [inputObj]="inputObjRosterQuery"
                                    (emitData)="setRosterQueryData($event)"></app-dist-list-select>
            </div>
          }
          <div class="col-md-6 col-sm-4 col-xs-12">
            @if (canPageWrite && currentFilter.AuxFilters.length > 0 && currentPage !== 'projectReports') {
              <button pButton (click)="saveCurrentDist()" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                      label="Save as Distribution List" [style]="{width:'auto', 'margin-right':'3px'}"></button>
            }
            @if (showAdd && currentFilter.AuxFilters.length > 0) {
              <button pButton (click)="showAddFilter()" icon="fas fa-arrow-circle-up" pTooltip="Hide Filters"
                      tooltipPosition="bottom" label="Hide Filters"
                      [style]="{width:'auto', 'margin-right':'3px'}"></button>
            }
            @if (!showAdd && currentFilter.AuxFilters.length > 0) {
              <button pButton (click)="showAddFilter()" icon="fas fa-arrow-circle-down" pTooltip="Show Filters"
                      tooltipPosition="bottom" label="Show Filters"
                      [style]="{width:'auto', 'margin-right':'3px'}"></button>
            }
            @if (!isExporting) {
              <button pButton (click)="confirmExport()" icon="fa fa-file-excel"
                      class="p-button-download export-menu export-excel margin-top-20"
                      pTooltip="Export All"
                      tooltipPosition="bottom" label="Export All" [style]="{width:'auto'}"></button>

            } @else {
              <button pButton
                      class="p-button-download export-menu export-excel margin-top-15 p-pl-2 inline-button"
                      [disabled]="true"
                      icon="pi pi-spin pi-spinner save-spinner" label="Export All">
              </button>
            }

          </div>

        </div>
      </form>
    </div>
  }
  @if (!isRosterTable) {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="p-fluid p-grid">
        <div class="col-md-3 col-sm-3 col-xs-12">
          <app-select class="full-width" [inputObj]="inputObjOrganization"
                      (emitData)="setOrganizationData($event)"></app-select>
        </div>
        <div class="col-md-6 col-sm-4 col-xs-12 margin-top-20">
          <!--        <button *ngIf="isExporting" [style]="{'margin-right':'3px'}" pButton class="p-button-download export-menu p-pl-2 inline-button" [disabled]="true" icon="pi pi-spin pi-spinner save-spinner" label="Export">-->
          <!--        </button>-->
          <!--        <button *ngIf="!isExporting" pButton (click)="export()" icon="fa fa-file-excel" pTooltip="Export" tooltipPosition="bottom" label="Export" [style]="{width:'auto', 'margin-right':'3px'}"></button>-->
          @if (showAdd && currentFilter.AuxFilters.length > 0) {
            <button pButton (click)="showAddFilter()" icon="fas fa-arrow-circle-up" pTooltip="Hide Filters"
                    tooltipPosition="bottom" label="Hide Filters"
                    [style]="{width:'auto', 'margin-right':'3px'}"></button>
          }
          @if (!showAdd && currentFilter.AuxFilters.length > 0) {
            <button pButton (click)="showAddFilter()" icon="fas fa-arrow-circle-down" pTooltip="Show Filters"
                    tooltipPosition="bottom" label="Show Filters"
                    [style]="{width:'auto', 'margin-right':'3px'}"></button>
          }
          @if (!isExporting) {
            <button pButton (click)="confirmExport()" icon="fa fa-file-excel"
                    class="p-button-download export-menu export-excel"
                    pTooltip="Export All"
                    tooltipPosition="bottom" label="Export All" [style]="{width:'auto'}"></button>

          } @else {
            <button pButton
                    class="p-button-download export-menu export-excel p-pl-2 inline-button"
                    [disabled]="true"
                    icon="pi pi-spin pi-spinner save-spinner" label="Export All">
            </button>
          }

        </div>
      </div>
    </div>
  }
  <div class="col-md-12 col-sm-6 col-xs-12 margin-top-10">
    @if (showAdd) {
      <app-auxiliary-filter-form [isNewFilter]="true" [isNewDist]="isNewDist" [organizationId]="setOrganizationId"
                                 [currentPage]="currentPage"
                                 [selectedRosterQuery]="selectedRosterQuery" [description]="mainForm.get('name').value"
                                 [isRosterTable]="isRosterTable"
                                 (updateFilter)="processNewDistList($event)" [isGridPage]="isGridPage"
                                 (updateLiveFilter)="loadTable($event); listReady = true"></app-auxiliary-filter-form>
    }
    @if (listReady && showAdd) {
      <p-accordion [styleClass]="'margin-top-10'">
        <p-accordionTab header="Applied Filters" [selected]="openExpand">
          <div class="p-grid p-fluid">
            <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding time-accordion margin-top-10">
              @for (filter of currentFilter.AuxFilters; track filter) {
                <p-card [styleClass]="'facet-container margin-top-10'">
                  <div class="p-grid p-fluid">
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <h3>{{ findTableName(filter.Enumerator) }}</h3>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 text-align-right">
                      @if (showPreview(filter.Enumerator)) {
                        <button pButton (click)="openAuxPreview(filter)" icon="pi pi-search" pTooltip="Preview"
                                tooltipPosition="bottom"
                                class="margin-right-10 inline-button margin-top-10" label="Preview Results"></button>
                      }
                    </div>
                  </div>
                  <h2 class="category-header"></h2>
                  @for (filterForm of filter?.FilterPrimeNG?.filters | keyvalue; track filterForm) {
                    <p-card [styleClass]="'sub-facet-container margin-top-10'">
                      @for (filterDetails of filterForm.value; track filterDetails) {
                        <app-auxiliary-existing-filter-form [isEdit]="false" [selectedFilter]="filterDetails"
                                                            [selectedTable]="filter.Enumerator"
                                                            [selectedRosterQuery]="selectedRosterQuery"
                                                            [selectedColumn]="filterForm.key"
                                                            (updateFilter)="deleteFilter($event)"
                                                            [isGridPage]="isGridPage"
                                                            (updateLiveFilter)="loadTable($event)"
                                                            (deleteFilterFromParent)="deleteFilter($event)"></app-auxiliary-existing-filter-form>
                      }
                    </p-card>
                  }
                </p-card>
              }
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    }
  </div>
  @if (showTable && isRosterTable) {
    <div class="col-md-12col-sm-12 col-xs-12 margin-top-10">
      <p-table
        #dt1
        [rows]="pageRowCountUtility.pageRowCount()"
        [columns]="selectedColumns"
        dataKey="PersonID"
        [value]="rosterQueryList"
        [lazy]="true"
        (onLazyLoad)="loadTable($event)"
        [paginator]="true"
        [showCurrentPageReport]="true"
        [metaKeySelection]="false"
        [totalRecords]="dataTotal"
        [rowsPerPageOptions]="[25,50,100,250,500]"
        paginatorDropdownAppendTo="body"
        [(selection)]="selStudent"
        responsiveLayout="scroll"
        selectionMode="single"
        sortMode="single"
        [loading]="loading"
        scrollHeight="52vh"
        [scrollable]="true"
        [reorderableColumns]="true"
        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
        styleClass="p-datatable-gridlines p-datatable-striped"
        currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries">
        <ng-template pTemplate="caption">
          <div class="table-column-picker">
            <p-multiSelect
              display="chip"
              [options]="cols"
              [(ngModel)]="_selectedColumns"
              optionLabel="header"
              [maxSelectedLabels]="13"
              selectedItemsLabel="{0} columns selected"
              [style]="{'min-width': '200px'}"
              placeholder="Choose Columns"/>
          </div>
          <div class="table-column-save">
            <button pButton pTooltip="Save" class="p-justify-center"
                    tooltipPosition="bottom"
                    (click)="saveColumns()"
            ><i class="pi pi-save p-mr-1"></i>Save Columns
            </button>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <!--            <th *ngIf="routeContext!=='membership' && isRosterTable" style="width:75px" pFrozenColumn [frozen]="true">-->
            <!--              Profile-->
            <!--            </th>-->
            @for (col of columns; track col) {
              <th pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" pSortableColumn="{{col.field}}" pReorderableColumn
                  style="min-width:125px;">
                {{ col.header }}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>
              </th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr (click)="goToEdit(rowData)" [ngClass]="{'selectedRow' : selStudent === rowData}" class="pointer relative">
            <!--            <td *ngIf="routeContext!=='membership' && isRosterTable" style="width:75px" pFrozenColumn [frozen]="true">-->
            <!--              <div *ngIf="isStatusInActive(rowData.StatusAlias)" class="inactive-flag"></div>-->
            <!--              <div class="avatar">-->
            <!--                <div class="avatar-without-image" [ngClass]="getAvatarClass(false, rowData.StatusAlias)"><img src="assets/images/profile-generic.png" alt=""></div>-->
            <!--              </div>-->
            <!--            </td>-->
            @for (col of columns; track col) {
              <td style="min-width:125px">
                @if (col.field === 'LastName' && isStatusInActive(rowData.StatusAlias)) {
                  <div class="inactive-flag"></div>
                }
                @if (col.visible && col.displayType === 'currency') {
                  @if (rowData[col.field] < 0) {
                    <span class="positive"> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                  }
                  @if (rowData[col.field] === 0) {
                    <span> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                  }
                  @if (rowData[col.field] > 0) {
                    <span class="negative">{{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                  }
                }
                @if (col.visible && col.displayType === 'date') {
                  {{ rowData[col.field] | date: 'shortDate' }}
                }
                @if (col.visible && col.displayType === 'numeric') {
                  {{ rowData[col.field] | number:'1.0':'en-US' }}
                }
                @if (col.visible && col.displayType === 'phone') {
                  {{ rowData[col.field] | phone }}
                }
                @if (col.visible && col.displayType === 'text') {
                  {{ rowData[col.field] }}
                }
                @if (col.visible && !col.displayType) {
                  {{ rowData[col.field] }}
                }
              </td>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="cols.length">No people found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }
  @if (!isRosterTable && this.parentObjectEnum === 'ORGPROFILE' && this.router.url.includes('/organizations/')) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      @if (setOrganizationId) {
        <p-table
          #dt2
          [rows]="pageRowCountUtility.pageRowCount()"
          [columns]="_selectedColumns"
          dataKey="ID"
          [value]="organizationList"
          [lazy]="true"
          (onLazyLoad)="loadTable($event)"
          [paginator]="true"
          [showCurrentPageReport]="true"
          [metaKeySelection]="false"
          [totalRecords]="dataTotal"
          [rowsPerPageOptions]="[25,50,100,250,500]"
          paginatorDropdownAppendTo="body"
          [(selection)]="selStudent"
          responsiveLayout="scroll"
          selectionMode="single"
          sortMode="single"
          [loading]="loading"
          scrollHeight="52vh"
          [scrollable]="true"
          [reorderableColumns]="true"
          (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
          styleClass="p-datatable-gridlines p-datatable-striped frozen-column-table"
          currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries">
          <ng-template pTemplate="caption">
            <div class="table-column-picker">
              <p-multiSelect
                display="chip"
                [options]="cols"
                [(ngModel)]="selectedColumns"
                optionLabel="header"
                [maxSelectedLabels]="13"
                selectedItemsLabel="{0} columns selected"
                [style]="{'min-width': '200px'}"
                placeholder="Choose Columns"/>
            </div>
            <div class="table-column-save">
              <button pButton pTooltip="Save" class="p-justify-center"
                      tooltipPosition="bottom"
                      (click)="saveColumns()"
              ><i class="pi pi-save p-mr-1"></i>Save Columns
              </button>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              @for (col of columns; track col) {
                <th pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" pSortableColumn="{{col.field}}" pReorderableColumn
                    style="min-width:125px">
                  {{ col.header }}
                  <p-sortIcon field="{{col.field}}"></p-sortIcon>
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
            <tr [ngClass]="{'selectedRow' : selStudent === rowData}" class="pointer relative" (click)="goToEdit(rowData)">
              @for (col of columns; track col) {
                <td style="min-width:125px">
                  @if (col.visible && col.displayType === 'currency') {
                    {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}
                  }
                  @if (col.visible && col.displayType === 'date') {
                    {{ rowData[col.field] | date: 'shortDate' }}
                  }
                  @if (col.visible && col.displayType === 'numeric') {
                    {{ rowData[col.field] | number:'1.0':'en-US' }}
                  }
                  @if (col.visible && col.displayType === 'phone') {
                    {{ rowData[col.field] | phone }}
                  }
                  @if (col.visible && col.displayType === 'text') {
                    {{ rowData[col.field] }}
                  }
                </td>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [colSpan]="cols?.length" style="width: 100%;">No organizations found.</td>
            </tr>
          </ng-template>
        </p-table>
      }
    </div>
  }
  @if (!isRosterTable && this.parentObjectEnum === 'ORGPROFILE' && this.router.url.includes('/employers/')) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      @if (setOrganizationId) {
        <p-table
          #dt2
          [rows]="pageRowCountUtility.pageRowCount()"
          [columns]="_selectedColumns"
          dataKey="ID"
          [value]="employerList"
          [lazy]="true"
          (onLazyLoad)="loadTable($event)"
          [paginator]="true"
          [showCurrentPageReport]="true"
          [metaKeySelection]="false"
          [totalRecords]="dataTotal"
          [rowsPerPageOptions]="[25,50,100,250,500]"
          paginatorDropdownAppendTo="body"
          [(selection)]="selStudent"
          responsiveLayout="scroll"
          selectionMode="single"
          sortMode="single"
          [loading]="loading"
          scrollHeight="52vh"
          [scrollable]="true"
          [reorderableColumns]="true"
          (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
          styleClass="p-datatable-gridlines p-datatable-striped frozen-column-table"
          currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries">
          <ng-template pTemplate="caption">
            <div class="table-column-picker">
              <p-multiSelect
                display="chip"
                [options]="cols"
                [(ngModel)]="selectedColumns"
                optionLabel="header"
                [maxSelectedLabels]="13"
                selectedItemsLabel="{0} columns selected"
                [style]="{'min-width': '200px'}"
                placeholder="Choose Columns"/>
            </div>
            <div class="table-column-save">
              <button pButton pTooltip="Save" class="p-justify-center"
                      tooltipPosition="bottom"
                      (click)="saveColumns()"
              ><i class="pi pi-save p-mr-1"></i>Save Columns
              </button>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              @for (col of columns; track col) {
                <th pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" pSortableColumn="{{col.field}}" pReorderableColumn
                    style="min-width:125px">
                  {{ col.header }}
                  <p-sortIcon field="{{col.field}}"></p-sortIcon>
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
            <tr [ngClass]="{'selectedRow' : selStudent === rowData}" class="pointer relative" (click)="goToEdit(rowData)">
              @for (col of columns; track col) {
                <td style="min-width:125px">
                  @if (col.visible && col.displayType === 'currency') {
                    {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}
                  }
                  @if (col.visible && col.displayType === 'date') {
                    {{ rowData[col.field] | date: 'shortDate' }}
                  }
                  @if (col.visible && col.displayType === 'numeric') {
                    {{ rowData[col.field] | number:'1.0':'en-US' }}
                  }
                  @if (col.visible && col.displayType === 'phone') {
                    {{ rowData[col.field] | phone }}
                  }
                  @if (col.visible && col.displayType === 'text') {
                    {{ rowData[col.field] }}
                  }
                </td>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [colSpan]="cols?.length" style="width: 100%;">No Employers found.</td>
            </tr>
          </ng-template>
        </p-table>
      }
    </div>
  }
</div>
