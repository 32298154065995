import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {RosterProfileActivity, RosterProfileActivityBillingDetails} from '../../../models/roster-profile-activities.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DOBValid, NotZeroValidation, transformDateTimeToDateOnly} from '../../utilities/form.utility';
import {take, takeUntil} from 'rxjs/operators';
import {RosterProfileActivitiesService} from '../../../services/roster-profile-activities.service';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {RosterProfileService} from '../../../services/roster-profile.service';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {PageService} from '../../../services/page.services';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PageModel} from '../../../models/page.model';
import {CustomAttributesModel} from '../../../models/attributes.model';
import {CustomAttributesService} from '../../../services/custom-attributes.service';
import {ContextService} from '../../../services/context.service';
import moment from 'moment';
import {RosterProfileModel} from '../../../models/roster-profile.model';
import {PiiValidateModel} from '../../../models/pii-validate.model';
import {PiiDuplicateDialogComponent} from '../pii-duplicate-dialog/pii-duplicate-dialog.component';
import {PiiValidateService} from '../../../services/pii-validate.service';

@Component({
  selector: 'app-member-activities',
  templateUrl: './member-activities.component.html',
  styleUrls: ['./member-activities.component.scss']
})
export class MemberActivitiesComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  isSaving: boolean = false;
  inActiveRosterProfile = {} as RosterProfileModel;
  inputObjActivities: GenericSelectModel;
  setActivityId: number;
  setActivityDesc: string;
  maxActivityDate: Date;
  inputObjActivityOrg: GenericSelectModel;
  setActivityOrg: number;
  inputObjActivityPathway: GenericSelectModel;
  setActivityPathway: number;
  inputObjActivityBargainingUnit: GenericSelectModel;
  setActivityBargainingUnit: number;
  inputObjActivityType: GenericSelectModel;
  setActivityType: number;
  inputObjActivityClass: GenericSelectModel;
  setActivityClass: number;
  inputObjActivityDues: GenericSelectModel;
  PersonProfileClassificationRequired: boolean;
  setActivityDues: number;
  activityPreview: boolean = false;
  showBargainingUnitFlag: boolean = false;
  showOrganizationFlag: boolean = false;
  showPathwayFlag: boolean = false;
  showPersonProfileClassificationFlag: boolean = false;
  showPersonProfileDuesGroups: boolean = false;
  showPersonProfileTypeFlag: boolean = false;
  showApplicationDate: boolean = false;
  showDOB: boolean = false;
  reqBargainingUnitFlag: boolean = false;
  reqOrganizationFlag: boolean = false;
  reqPathwayFlag: boolean = false;
  reqPersonProfileClassificationFlag: boolean = false;
  reqPersonProfileTypeFlag: boolean = false;
  rosterProfileActivity: RosterProfileActivity;
  preview: RosterProfileActivityBillingDetails[];
  previewTotals = {} as RosterProfileActivityBillingDetails;
  STRINGREPLACECLASSIFICATION: string;
  STRINGREPLACELOCAL: string;
  STRINGREPLACETYPE: string;
  showWaiveFee: boolean;
  setTypeId: number;
  setStatusId: number;
  setClassId: number;
  setPathwayId: number;
  setBillingId: number;
  isDialog: boolean = false;
  isSSN: boolean = true;
  showSSN: boolean;
  showSignature: boolean;
  customAttributes: CustomAttributesModel[];
  minDate;
  maxDate;
  isInactiveActivity: boolean;
  isSearching: boolean = false;
  isEditing: boolean = false;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() memberType: string;
  @Input() PersonID: number;
  @Input() setOrganizationId: number;
  @Input() dob: string;
  @Input() newMember: boolean;
  @Input() memberValid: boolean;
  @Input() applicationDate: string;
  //@Input() effectiveDate: Date;
  @Input() effectiveDate: string;
  @Input() initiationDate: string;
  @Input() StatusAlias: string;
  @Input() isPreApplicant: boolean;
  @Output() updateNonMemberId = new EventEmitter<number>();
  @Output() closeAddEdit = new EventEmitter<any>();
  @Output() updateOrg = new EventEmitter<any>();
  @Output() updatePathway = new EventEmitter<any>();
  @Output() updateType = new EventEmitter<any>();
  @Output() previewShown = new EventEmitter<boolean>();
  @Output() updateAttributesToParent = new EventEmitter<boolean>();
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(private lookupsDataService: LookupsDataService, private messageService: MessageService, private confirmationService: ConfirmationService,
              private rosterProfileActivitiesService: RosterProfileActivitiesService, private formBuilder: FormBuilder,
              private tenantConfigDataService: TenantConfigDataService, private rosterProfileService: RosterProfileService,
              private route: ActivatedRoute, public config: DynamicDialogConfig, private router: Router, private contextService: ContextService,
              private pageService: PageService, public ref: DynamicDialogRef, private customAttributesService: CustomAttributesService,
              private changeDetection: ChangeDetectorRef, private piiValidateService: PiiValidateService, private dialogService: DialogService) {
    this.mainForm = this.formBuilder.group({
      statusId: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      memberNumber: new FormControl({value: null, disabled: true}),
      class: new FormControl({value: null, disabled: true}),
      organization: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      pathway: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      typeId: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      effectiveDate: new FormControl({value: null, disabled: true}),
      applicationDate: new FormControl(null),
      initiationDate: new FormControl({value: null, disabled: true}),
      retirementDate: new FormControl({value: null, disabled: true}),
      billingStatusID: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      duesPaymentID: new FormControl({value: null, disabled: true}),
      bargainingUnit: new FormControl({value: null, disabled: true}, Validators.maxLength(100)),
      activityId: new FormControl(null),
      activityDate: new FormControl(null),
      activityOrg: new FormControl(null),
      activityPathway: new FormControl(null),
      activityBargainingUnit: new FormControl(null),
      activityType: new FormControl(null),
      activityClass: new FormControl(null),
      cumulativeYears: new FormControl(''),
      consecutiveYears: new FormControl(null),
      activityDues: new FormControl(null),
      waiveInitiationFee: new FormControl(null),
      dob: new FormControl(null,),
      sin: new FormControl(null),
      ssn: new FormControl(null),
      representation: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.minDate = moment(this.minDate).subtract(130, 'years').toDate();
    this.maxDate = moment(this.maxDate).toDate();
    this.mainForm.valueChanges.subscribe({
      next: () => {
        this.previewShown.emit(this.mainForm.valid && this.activityPreview);
      }
    });

    if (this.newMember) {
      this.mainForm.get('organization').setValidators(Validators.required);
      this.mainForm.get('pathway').setValidators(Validators.required);
      this.mainForm.get('pathway').setValidators(Validators.required);
    }

    const now = new Date();
    this.maxActivityDate = new Date(now.setDate(now.getDate()));
    this.STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    this.STRINGREPLACECLASSIFICATION = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
    this.STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isDialog = this.pageInfo.isDialog;
          this.PersonID = this.pageInfo.ContentID;
          if (this.config.data && !this.setOrganizationId) {
            this.setOrganizationId = this.config.data.orgid;
            this.newMember = this.config.data.newMember;
          }
          this.canTabWrite = true;//this.config.data.canTabWrite;
          this.resetActivity(true);
        } else {
          this.resetActivity(true);
        }
      }
    });
    this.mainForm.get('representation').valueChanges.subscribe({
      next: () => {
        console.log(this.mainForm);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.newMember) {
      this.STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
      this.initActivityOrg(false, true);
      this.initActivityPathway(false, true);
    }
    if (changes.setOrganizationId) {
      this.setActivityOrg = this.setOrganizationId;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getMemberProfile() {
    this.rosterProfileService.getRosterProfile(this.PersonID)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.inActiveRosterProfile = res;
          console.log(this.inActiveRosterProfile);
        }
      });
  }

  initActivity(disable: boolean): void {
    this.inputObjActivities = {
      labelText: 'New Activity',
      optionValue: 'ActivityID',
      optionLabel: 'ActivityDescription',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (this.newMember) {
      this.lookupsDataService.getAvailableActivitiesNewMemberLookupData(this.setActivityOrg, this.setActivityType, this.isPreApplicant).then((lookupData) => {
        this.inputObjActivities.data = lookupData;
        this.inputObjActivities = Object.assign({}, this.inputObjActivities);
      });
    } else {
      this.lookupsDataService.getAvailableActivitiesLookupData(this.PersonID).then((lookupData: any[]) => {
        this.inputObjActivities.data = lookupData;
        this.inputObjActivities = Object.assign({}, this.inputObjActivities);
        if ((this.inputObjActivities.data.find(x => x.ActivityDescription === 'Initiated')
          || this.inputObjActivities.data.find(x => x.ActivityDescription === 'Paying on Application'))) {
          this.customAttributesService.getCustomAttributesByOrgID(this.contextService.contextObject.OrganizationId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.customAttributes = res;
              }
            });
        } else {
        }
      });
    }
  }

  getActivitiesData(event: any) {
    if (!this.newMember) {
      this.resetActivity(false);
      this.mainForm.get('activityDate').setValidators(Validators.required);
      this.mainForm.get('activityOrg').clearValidators();
      this.mainForm.get('activityPathway').clearValidators();
      this.mainForm.get('activityBargainingUnit').clearValidators();
      this.mainForm.get('activityType').clearValidators();
      this.mainForm.get('activityClass').clearValidators();
      this.mainForm.get('activityDues').clearValidators();
      this.mainForm.get('applicationDate').clearValidators();
      this.mainForm.get('dob').clearValidators();
      this.mainForm.get('sin').clearValidators();
      this.mainForm.get('ssn').clearValidators();
      for (const field in this.mainForm.controls) {
        if (field) {
          this.mainForm.get(field).updateValueAndValidity();
        }
      }

      if (event && event[0] && event[0].ActivityID) {
        this.setActivityId = event[0].ActivityID;
        this.setActivityDesc = event[0].ActivityDescription;
        this.reqBargainingUnitFlag = (event[0].BargainingUnitFlag === 'R');
        if (this.reqBargainingUnitFlag) {
          this.mainForm.get('activityBargainingUnit').setValidators(Validators.required);
        }
        this.reqOrganizationFlag = (event[0].OrganizationFlag === 'R');
        if (this.reqOrganizationFlag) {
          this.mainForm.get('activityOrg').setValidators(Validators.required);
        }
        this.reqPathwayFlag = (event[0].PathwayFlag === 'R');
        if (this.reqPathwayFlag) {
          this.mainForm.get('activityPathway').setValidators(Validators.required);
        }
        this.reqPersonProfileClassificationFlag = (event[0].PersonProfileClassificationFlag === 'R');
        if (this.reqPersonProfileClassificationFlag) {
          this.mainForm.get('activityClass').setValidators(Validators.required);
        }
        this.reqPersonProfileTypeFlag = (event[0].PersonProfileTypeFlag === 'R');
        if (this.reqPersonProfileTypeFlag) {
          this.mainForm.get('activityType').setValidators(Validators.required);
        }
        if (event[0].PersonProfileDuesGroupFlag === 'R') {
          this.mainForm.get('activityDues').setValidators(Validators.required);
        }
        if (event[0].ApplicationDateFlag === 'R') {
          this.mainForm.get('applicationDate').setValidators(Validators.required);
        }
        this.showDOB = (event[0].DOBFlag !== 'X');
        if (event[0].DOBFlag === 'R') {
          this.mainForm.get('dob').setValidators([Validators.required, DOBValid()]);
        }
        console.log(this.memberType);
        if (this.setActivityDesc === 'Initiated' || this.setActivityDesc === 'Paying on Application') {
          if (this.StatusAlias === 'Inactive' || this.config.data?.isNonMember || this.memberType === 'Non-Member') {
            //if(this.StatusAlias === 'Inactive') {
            this.isInactiveActivity = true;
            this.getMemberProfile();
            //}
            this.showSignature = true;
            this.mainForm.get('representation').setValidators(Validators.required);
            this.mainForm.get('representation').updateValueAndValidity();
          }
        } else {
          this.showSignature = false;
          this.mainForm.get('representation').clearValidators();
          this.mainForm.get('representation').updateValueAndValidity();
        }


        this.initActivityBargainingUnit(!this.canTabWrite, this.reqBargainingUnitFlag);
        this.initActivityOrg(!this.canTabWrite, this.reqOrganizationFlag);
        this.initActivityDues(false, event[0].PersonProfileDuesGroupFlag === 'R');
        this.initActivityClass(true, false);
        this.initActivityPathway(true, true);
        this.showBargainingUnitFlag = (event[0].BargainingUnitFlag !== 'X');
        this.showOrganizationFlag = (event[0].OrganizationFlag !== 'X');

        this.showPathwayFlag = (event[0].PathwayFlag !== 'X');
        this.showPersonProfileDuesGroups = (event[0].PersonProfileDuesGroupFlag !== 'X');
        this.showPersonProfileClassificationFlag = (event[0].PersonProfileClassificationFlag !== 'X');
        this.showPersonProfileTypeFlag = (event[0].PersonProfileTypeFlag !== 'X');
        this.showApplicationDate = (event[0].ApplicationDateFlag !== 'X');
        this.showWaiveFee = (event[0].WaiveInitiationFeeFlag !== 'X');
        this.showSSN = (event[0].SSNSINFlag !== 'X');

        for (const field in this.mainForm.controls) {
          if (field) {
            this.mainForm.get(field).updateValueAndValidity();
          }
        }

      } else {
        this.setActivityId = null;
        this.setActivityDesc = null;
      }
      this.mainForm.markAsDirty();
      this.mainForm.get('activityId').setValue(this.setActivityId);
      this.activityPreview = false;

    } else { // new member
      this.mainForm.get('applicationDate').setValue(null);
      this.mainForm.get('waiveInitiationFee').setValue(null);
      this.mainForm.get('activityBargainingUnit').setValue(null);
      this.mainForm.get('activityDate').setValidators(Validators.required);
      this.mainForm.get('activityOrg').clearValidators();
      this.mainForm.get('activityPathway').clearValidators();
      this.mainForm.get('activityBargainingUnit').clearValidators();
      this.mainForm.get('activityType').clearValidators();
      if (!this.PersonProfileClassificationRequired) {
        this.mainForm.get('activityClass').clearValidators();
      }
      this.mainForm.get('activityDues').clearValidators();
      this.mainForm.get('applicationDate').clearValidators();
      this.setActivityId = event[0].ActivityID;
      this.showPersonProfileClassificationFlag = (event[0].PersonProfileClassificationFlag !== 'X');
      this.reqBargainingUnitFlag = (event[0].BargainingUnitFlag === 'R');
      this.showPersonProfileDuesGroups = (event[0].PersonProfileDuesGroupFlag !== 'X');
      this.showWaiveFee = (event[0].WaiveInitiationFeeFlag !== 'X');
      this.showApplicationDate = (event[0].ApplicationDateFlag !== 'X');
      this.showBargainingUnitFlag = (event[0].BargainingUnitFlag !== 'X');
      if (this.reqBargainingUnitFlag) {
        this.mainForm.get('activityBargainingUnit').setValidators(Validators.required);
      }
      this.reqOrganizationFlag = (event[0].OrganizationFlag === 'R');
      if (this.reqOrganizationFlag) {
        this.mainForm.get('activityOrg').setValidators(Validators.required);
      }
      this.reqPersonProfileClassificationFlag = (event[0].PersonProfileClassificationFlag === 'R');
      if (this.reqPersonProfileClassificationFlag) {
        this.mainForm.get('activityClass').setValidators(Validators.required);
      }
      if (event[0].ApplicationDateFlag === 'R') {
        this.mainForm.get('applicationDate').setValidators(Validators.required);
      }
      this.reqPersonProfileTypeFlag = (event[0].PersonProfileTypeFlag === 'R');
      if (this.reqPersonProfileTypeFlag) {
        this.mainForm.get('activityType').setValidators(Validators.required);
      }
      if (event[0].PersonProfileDuesGroupFlag === 'R') {
        this.mainForm.get('activityDues').setValidators(Validators.required);
      }

      this.initActivityBargainingUnit(false, this.reqBargainingUnitFlag);
      this.initActivityClass(false, this.PersonProfileClassificationRequired);
      this.initActivityDues(false, event[0].PersonProfileDuesGroupFlag === 'R');
      this.mainForm.markAsDirty();
      this.mainForm.get('activityId').setValue(this.setActivityId);
      this.activityPreview = false;
      for (const field in this.mainForm.controls) {
        if (field) {
          this.mainForm.get(field).updateValueAndValidity();
        }
      }
    }

  }

  updateAttributes(event, field) {
    if (field === 'Representation') {
      this.mainForm.get('representation').setValue(event);
      if (event === 'Yes') {
        this.mainForm.get('representation').markAsDirty();
        this.mainForm.markAsDirty();
      } else {
        this.mainForm.get('representation').setValue(null);
      }
    }
  }

  initActivityOrg(disable: boolean, required: boolean): void {
    this.inputObjActivityOrg = {
      labelText: this.STRINGREPLACELOCAL,
      optionValue: 'ID',
      optionLabel: 'Abbreviation',
      filter: true,
      requiredField: required,
      selectFirstValue: false,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getOrganizationsLocalsByTypeData(false,).then((lookupData) => {
      this.inputObjActivityOrg.data = lookupData;
      this.inputObjActivityOrg = Object.assign({}, this.inputObjActivityOrg);
    });
  }

  getActivityOrgData(event: any) {
    // this.setActivityOrg = null;
    if (event && event[0] && event[0].ID) {
      this.setActivityOrg = event[0].ID;
    } else {
      this.setActivityOrg = null;
    }
    this.updateOrg.emit(this.setActivityOrg);
    this.setActivityPathway = null;
    if (this.newMember) {
      this.mainForm.get('activityDate').setValue(null);
    }
    // this.mainForm.get('activityPathway').setValue(null);
    // this.mainForm.get('activityBargainingUnit').setValue(null);
    // this.mainForm.get('activityType').setValue(null);
    // this.mainForm.get('activityClass').setValue(null);
    // this.mainForm.get('activityDues').setValue(null);
    // this.mainForm.get('applicationDate').setValue(null);
    this.initActivityPathway(false, this.reqPathwayFlag);
    this.getActivityPathwayData(null);
    this.mainForm.markAsDirty();
    this.mainForm.get('activityOrg').setValue(this.setActivityOrg);
    this.activityPreview = false;
    this.initActivityBargainingUnit(false, false);
    this.initActivityType(false, this.reqPersonProfileTypeFlag);
    this.mainForm.updateValueAndValidity();
    this.changeDetection.detectChanges();
  }

  initActivityPathway(disable: boolean, required: boolean): void {
    this.inputObjActivityPathway = {
      labelText: 'Pathway',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: required,
      selectFirstValue: false,
      initSelected: this.setActivityPathway,
      disabled: disable,
      canWrite: this.canTabWrite,
    };
    let tempLookup;
    if (this.setActivityOrg) {
      this.lookupsDataService.getPathwaysLookupData(this.setActivityOrg).then((lookupData: any[]) => {
        if (lookupData?.length === 2) {
          this.setActivityPathway = lookupData[1].ID;
          this.mainForm.get('activityPathway').setValue(this.setActivityPathway);
          this.updatePathway.emit(this.setActivityPathway);
        }
        tempLookup = lookupData;
        this.inputObjActivityPathway = {
          labelText: 'Pathway',
          optionValue: 'ID',
          optionLabel: 'Description',
          filter: true,
          requiredField: required,
          selectFirstValue: false,
          initSelected: this.setActivityPathway,
          disabled: disable,
          canWrite: this.canTabWrite,
          data: tempLookup
        };
      });
    }
  }

  getActivityPathwayData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setActivityPathway = event[0].ID;
    } else {
      this.setActivityPathway = null;
      this.mainForm.get('activityType').setValue(null);
      this.mainForm.get('activityClass').setValue(null);

    }
    this.updatePathway.emit(this.setActivityPathway);
    this.mainForm.markAsDirty();
    this.mainForm.get('activityPathway').setValue(this.setActivityPathway);
    this.activityPreview = false;
    if (this.newMember) {
      this.initActivityType(false, true);
      this.initActivityClass(false, false);
    }
  }

  initActivityBargainingUnit(disable: boolean, required: boolean): void {
    this.inputObjActivityBargainingUnit = {
      labelText: 'Bargaining Unit',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: required,
      selectFirstValue: false,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (this.setActivityOrg) {
      this.lookupsDataService.getBargainingUnitByOrgLookupData(this.setActivityOrg).then((lookupData) => {
        this.inputObjActivityBargainingUnit.data = lookupData;
        this.inputObjActivityBargainingUnit = Object.assign({}, this.inputObjActivityBargainingUnit);
      });
    } else {
      this.lookupsDataService.getBargainingUnitByOrgLookupData(this.setOrganizationId).then((lookupData) => {
        this.inputObjActivityBargainingUnit.data = lookupData;
        this.inputObjActivityBargainingUnit = Object.assign({}, this.inputObjActivityBargainingUnit);
      });
    }
  }

  getActivityBargainingUnitData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setActivityBargainingUnit = event[0].ID;
    } else {
      this.setActivityBargainingUnit = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('activityBargainingUnit').setValue(this.setActivityBargainingUnit);
    this.activityPreview = false;
  }

  initActivityType(disable: boolean, required: boolean): void {
    this.inputObjActivityType = {
      labelText: this.STRINGREPLACETYPE,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: required,
      selectFirstValue: false,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (this.mainForm.get('activityDate').value) {
      if (this.showOrganizationFlag) {
        if (this.setActivityOrg) {
          this.lookupsDataService.getPersonProfileTypesByContextByOrgByDOBLookupData('membership', this.setActivityOrg, this.dob, this.mainForm.get('activityDate').value, false).then((lookupData) => {
            this.inputObjActivityType.data = lookupData;
            this.inputObjActivityType = Object.assign({}, this.inputObjActivityType);
          });
        }
      } else if (this.setOrganizationId) {
        if (!this.reqPersonProfileTypeFlag) {
          this.lookupsDataService.getPersonProfileTypesByContextByOrgByPersonIdLookupData('membership', this.setOrganizationId, this.PersonID, this.dob, this.mainForm.get('activityDate').value, false).then((lookupData) => {
            this.inputObjActivityType.data = lookupData;
            this.inputObjActivityType = Object.assign({}, this.inputObjActivityType);
          });
        } else {
          if (this.applicationDate) {
            this.lookupsDataService.getPersonProfileTypesByContextByOrgByPersonIdLookupData('membership', this.setOrganizationId, this.PersonID, this.dob, this.applicationDate, false).then((lookupData) => {
              this.inputObjActivityType.data = lookupData;
              this.inputObjActivityType = Object.assign({}, this.inputObjActivityType);
            });
          } else {
            this.lookupsDataService.getPersonProfileTypesByContextByOrgByPersonIdLookupData('membership', this.setOrganizationId, this.PersonID, this.dob, this.initiationDate, false).then((lookupData) => {
              this.inputObjActivityType.data = lookupData;
              this.inputObjActivityType = Object.assign({}, this.inputObjActivityType);
            });
          }
        }
      }
    }
  }

  getActivityTypeData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setActivityType = event[0].ID;
      this.initActivityClass(!this.canTabWrite, this.reqPersonProfileClassificationFlag);
    } else {
      this.setActivityType = null;
      this.initActivityClass(false, this.reqPersonProfileClassificationFlag);

    }
    this.updateType.emit(this.setActivityType);
    this.mainForm.markAsDirty();
    this.mainForm.get('activityType').setValue(this.setActivityType);
    this.activityPreview = false;
    if (this.newMember) {
      this.initActivity(false);
    }

    if (event[0].PersonProfileClassificationRequired) {
      this.mainForm.get('activityClass').setValidators(Validators.required);
      this.mainForm.get('activityClass').updateValueAndValidity();
      this.initActivityClass(false, true);
      this.PersonProfileClassificationRequired = true;
      console.log(this.mainForm);
    } else {
      this.mainForm.get('activityClass').clearValidators();
      this.mainForm.get('activityClass').updateValueAndValidity();
      this.initActivityClass(false, false);
      this.PersonProfileClassificationRequired = false;
    }
  }

  initActivityClass(disable: boolean, required: boolean): void {
    this.inputObjActivityClass = {
      labelText: this.STRINGREPLACECLASSIFICATION,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: required,
      selectFirstValue: false,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (this.setOrganizationId && this.setActivityType && this.mainForm.get('activityDate').value) {
      if (this.newMember || this.isDialog) {
        this.lookupsDataService.getPersonProfileClassificationsByTypeByOrgByDOBLookupData(this.setActivityType, this.setActivityOrg, this.dob, this.mainForm.get('activityDate').value).then((lookupData: any[]) => {
          lookupData = lookupData.filter(x => x.Active === true);
          this.inputObjActivityClass.data = lookupData;
          this.inputObjActivityClass = Object.assign({}, this.inputObjActivityClass);
        });
      } else {
        this.lookupsDataService.getPersonProfileClassificationsByTypeByOrgByPersonIdLookupData(this.setActivityType, this.setActivityOrg, this.PersonID, this.mainForm.get('activityDate').value).then((lookupData: any[]) => {
          lookupData = lookupData.filter(x => x.Active === true);
          this.inputObjActivityClass.data = lookupData;
          this.inputObjActivityClass = Object.assign({}, this.inputObjActivityClass);
        });
      }

    }
  }

  getActivityClassData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setActivityClass = event[0].ID;
    } else {
      this.setActivityClass = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('activityClass').setValue(this.setActivityClass);
    this.activityPreview = false;
  }

  initActivityDues(disable: boolean, required: boolean): void {
    this.inputObjActivityDues = {
      labelText: 'Dues Group',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: required,
      selectFirstValue: false,
      initSelected: this.setActivityDues,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getPersonProfileDuesLookupData().then((lookupData: any[]) => {
      this.inputObjActivityDues.data = lookupData;
      this.inputObjActivityDues = Object.assign({}, this.inputObjActivityDues);
    });
  }

  getActivityDuesData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setActivityDues = event[0].ID;
    } else {
      this.setActivityDues = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('activityDues').setValue(this.setActivityDues);
    this.activityPreview = false;
  }


  resetActivity(loadActivities: boolean): void {
    this.setActivityId = null;
    this.mainForm.get('activityId').setValue(null);
    this.mainForm.get('activityDate').setValue(null);
    this.setActivityOrg = this.setOrganizationId;
    this.mainForm.get('activityOrg').setValue(null);
    this.setActivityPathway = null;
    this.mainForm.get('activityPathway').setValue(null);
    this.setActivityBargainingUnit = null;
    this.mainForm.get('activityBargainingUnit').setValue(null);
    this.setActivityType = null;
    this.mainForm.get('activityType').setValue(null);
    this.setActivityClass = null;
    this.mainForm.get('activityClass').setValue(null);
    this.setActivityDues = null;
    this.mainForm.get('activityDues').setValue(null);
    this.mainForm.get('applicationDate').setValue(null);
    this.preview = [];
    this.previewTotals = {} as RosterProfileActivityBillingDetails;
    if (loadActivities) {
      this.initActivity(!this.canTabWrite);
    }
    this.activityPreview = false;
  }

  previewActivity(): void {
    // if(!this.memberType?.includes('Non-Member') && this.effectiveDate && new Date(this.effectiveDate) > new Date(this.mainForm.get('activityDate').value)) {
    //   this.confirmationService.confirm({
    //     message: 'The activity date cannot be before the effective date.',
    //     acceptVisible: false,
    //     rejectLabel: 'Cancel',
    //     header: 'Error',
    //     icon: 'pi pi-times',
    //     accept: () => {
    //     },
    //     reject: () => {
    //       this.confirmationService.close();
    //     }
    //   });
    // } else {
    this.activityPreview = false;
    this.rosterProfileActivity = null;
    this.preview = null;
    this.previewTotals.DCBillableUnits = 0;
    this.previewTotals.DCDeathBenefitFund = 0;
    this.previewTotals.DCInitiationFee = 0;
    this.previewTotals.DCPCT = 0;
    this.previewTotals.LUBillableUnits = 0;
    this.previewTotals.LUDeathBenefitFund = 0;
    this.previewTotals.LUPCT = 0;
    this.previewTotals.LUInitiationFee = 0;
    this.previewTotals.LUReinstatementFee = 0;
    this.previewTotals.LUCardFee = 0;
    this.previewTotals.TotalCharged = 0;
    this.previewTotals.MemberBillableUnits = 0;
    this.previewTotals.MemberDues = 0;
    this.previewTotals.MemberOtherCharges = 0;
    this.previewTotals.MemberInitiationFee = 0;
    this.previewTotals.MemberReinstatementFee = 0;
    this.previewTotals.MemberCardFee = 0;
    this.previewTotals.TotalChargedMembers = 0;
    const data: RosterProfileActivity = {
      ActivityID: this.setActivityId,
      ActivityDate: this.mainForm.get('activityDate').value,
      ApplicationDate: transformDateTimeToDateOnly(this.mainForm.get('applicationDate').value),
      BargainingUnitID: this.setActivityBargainingUnit,
      OrganizationID: this.setActivityOrg,
      PathwayID: this.setActivityPathway,
      PersonProfileTypeID: this.setActivityType,
      PersonProfileClassificationID: this.setActivityClass,
      PersonProfileDuesGroupID: this.setActivityDues,
      WaiveInitiationFee: this.mainForm.get('waiveInitiationFee').value,
      ResultCode: null,
      ResultDescription: null,
      PersonDOB: this.mainForm.get('dob').value,
      PersonSSN: this.mainForm.get('ssn').value,
      PersonSIN: this.mainForm.get('sin').value
    };
    this.rosterProfileActivitiesService.getRosterActivityPreview(this.PersonID ? this.PersonID : 0, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.rosterProfileActivity = res;
          this.preview = res.BillingDetails;
          this.preview.forEach(x => {
            x.TotalCharged = x.DCPCT + x.DCDeathBenefitFund + x.DCInitiationFee + x.LUDeathBenefitFund + x.LUInitiationFee + x.LUPCT + x.LUReinstatementFee + x.LUCardFee;
            x.TotalChargedMembers = x.MemberDues + x.MemberOtherCharges + x.MemberInitiationFee + x.MemberReinstatementFee + x.MemberCardFee;
            this.previewTotals.DCBillableUnits += x.DCBillableUnits;
            this.previewTotals.DCDeathBenefitFund += x.DCDeathBenefitFund;
            this.previewTotals.DCInitiationFee += x.DCInitiationFee;
            this.previewTotals.DCPCT += x.DCPCT;
            this.previewTotals.LUBillableUnits += x.LUBillableUnits;
            this.previewTotals.LUDeathBenefitFund += x.LUDeathBenefitFund;
            this.previewTotals.LUPCT += x.LUPCT;
            this.previewTotals.LUInitiationFee += x.LUInitiationFee;
            this.previewTotals.LUReinstatementFee += x.LUReinstatementFee;
            this.previewTotals.LUCardFee += x.LUCardFee;
            this.previewTotals.TotalCharged += x.TotalCharged;
            this.previewTotals.MemberBillableUnits += x.MemberBillableUnits;
            this.previewTotals.MemberDues += x.MemberDues;
            this.previewTotals.MemberOtherCharges += x.MemberOtherCharges;
            this.previewTotals.MemberInitiationFee += x.MemberInitiationFee;
            this.previewTotals.MemberReinstatementFee += x.MemberReinstatementFee;
            this.previewTotals.MemberCardFee += x.MemberCardFee;
            this.previewTotals.TotalChargedMembers += x.TotalChargedMembers;
          });
          if (this.rosterProfileActivity.ResultCode < 0) {
            this.activityPreview = false;
            this.showPreviewError();
          } else {
            this.activityPreview = true;
            this.previewShown.emit(this.mainForm.valid && this.activityPreview);
          }
        }, error: () => {
          this.activityPreview = true;
          this.previewShown.emit(this.mainForm.valid && this.activityPreview);
        }
      });
    // }
  }

  showPreviewError() {
    this.confirmationService.confirm({
      message: this.rosterProfileActivity.ResultDescription,
      rejectLabel: 'Continue',
      header: 'Error',
      icon: 'pi pi-times',
      acceptVisible: false,
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  updateRosterCustomeAttributes() {
    this.isSaving = true;
    if (this.isInactiveActivity) {
      //Representation
      if (this.inActiveRosterProfile.CustomAttributes.find(x => x.FieldName === 'Representation')) {
        this.inActiveRosterProfile.CustomAttributes.find(x => x.FieldName === 'Representation').Value =
          this.mainForm.get('representation').value;
      } else {
        this.inActiveRosterProfile.CustomAttributes.push(this.customAttributes.find(x => x.FieldName === 'Representation'));
      }

      //Political Contribution
      if (this.inActiveRosterProfile.CustomAttributes.find(x => x.FieldName === 'Political Contribution')) {
        this.inActiveRosterProfile.CustomAttributes.find(x => x.FieldName === 'Political Contribution').Value =
          this.customAttributes.find(x => x.FieldName === 'Political Contribution')?.Value;
      } else {
        this.inActiveRosterProfile.CustomAttributes.push(this.customAttributes.find(x => x.FieldName === 'Political Contribution'));
      }

      //Dues Checkoff
      if (this.inActiveRosterProfile.CustomAttributes.find(x => x.FieldName === 'Dues Checkoff')) {
        this.inActiveRosterProfile.CustomAttributes.find(x => x.FieldName === 'Dues Checkoff').Value =
          this.customAttributes.find(x => x.FieldName === 'Dues Checkoff')?.Value;
      } else {
        this.inActiveRosterProfile.CustomAttributes.push(this.customAttributes.find(x => x.FieldName === 'Dues Checkoff'));
      }
      this.rosterProfileService.updateRosterProfile(this.PersonID, this.inActiveRosterProfile)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.submitActivity();
          }
        });
    } else {
      this.submitActivity();
    }


  }

  submitActivity(personId?: number, navigateRoute?: string): void {
    let localNavigateRoute: string;
    if (this.newMember) {
      console.log('in new');
      this.PersonID = personId;
      if (navigateRoute) {
        localNavigateRoute = navigateRoute;
      } else {
        localNavigateRoute = `membership/roster/edit/${this.PersonID}`;
      }
      this.rosterProfileActivitiesService.getRosterActivitySubmit(this.PersonID, this.rosterProfileActivity)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.closeAddEdit.emit();
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'The activity has been applied.'});
            this.resetActivity(true);
            this.router.navigateByUrl(localNavigateRoute);
          }
        });
    } else {
      console.log('in existing');
      if (navigateRoute) {
        localNavigateRoute = navigateRoute;
      } else {
        localNavigateRoute = `membership/roster/edit/${this.PersonID}`;
      }
      this.confirmationService.confirm({
        message: 'You are about to submit a new \'' + this.setActivityDesc + '\' activity for processing. Are you sure this is what you want to do?',
        acceptLabel: 'Submit',
        rejectLabel: 'Cancel',
        header: 'New Activity',
        icon: 'pi pi-save',
        accept: () => {
          this.rosterProfileActivitiesService.getRosterActivitySubmit(this.PersonID, this.rosterProfileActivity)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.closeAddEdit.emit();
                this.messageService.add({severity: 'success', summary: 'Success', detail: 'The activity has been applied.'});
                if (this.showSignature && !this.isInactiveActivity) {
                  this.customAttributesService.updateCustomAttributesByPersonID(this.PersonID, this.customAttributes)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe({
                      next: () => {
                        this.router.navigateByUrl(localNavigateRoute);
                        this.ref.close();
                      }
                    });
                } else {
                  this.router.navigateByUrl(localNavigateRoute);
                  this.ref.close();
                }
                this.isSaving = false;
                this.resetActivity(true);
              }
            });
        },
        reject: () => {
          this.confirmationService.close();
          this.isSaving = false;
        }
      });
    }
  }

  activityDateChanged(event: any): void {
    this.initActivityType(false, true);
    this.initActivityClass(false, false);
    this.mainForm.get('activityType').setValue(null);
    this.mainForm.get('activityClass').setValue(null);
    this.activityPreview = false;
  }

  dobChanged(event: any): void {
    this.dob = this.mainForm.get('dob').value;
    this.initActivityType(false, true);
    this.initActivityClass(false, false);
    this.activityPreview = false;
  }

  updateSSNSIN(event: any) {
    if (event === 'ssn') {
      this.mainForm.get('sin').setValue(null);
    } else {
      this.mainForm.get('ssn').setValue(null);
    }
  }

  validatePii(key: string, value: string) {
    this.isSearching = true;
    this.isEditing = false;
    const piiData: PiiValidateModel = {
      SSN: null,
      SIN: null,
      PrimaryEmailAddress: null,
      PersonID: this.PersonID,
      MatchType: key
    };
    piiData[key] = value;
    this.piiValidateService.validatePii(piiData)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.isSearching = false;
          if (res.MatchType) {
            const ref = this.dialogService.open(PiiDuplicateDialogComponent, {
              data: {
                piiData: res,
                value
              },
              header: 'Duplicate User(s) Found',
              width: '60%',
            });
            ref.onClose.subscribe({
              next: () => {
                switch (key) {
                  case 'SSN' : {
                    this.mainForm.get('ssn').setValue(null);
                    break;
                  }
                  case 'SIN' : {
                    this.mainForm.get('sin').setValue(null);
                    break;
                  }
                  case 'PrimaryEmailAddress' : {
                    this.mainForm.get('primaryEmail').setValue(null);
                    break;
                  }
                }
              }
            });
          }
        }
      });
  }

}
