import {BaseDataGridModel} from './data-grid.model';

export interface AddressesGridModel extends BaseDataGridModel {
  Data: AddressModel[];
}

export interface AddressModel {
  AddressID: number;
  ContactCategoryID: number;
  ContactCategoryDescription: string;
  Primary: boolean;
  Address1: string;
  Address2: string;
  Address3: string;
  Address4: string;
  Address5: string;
  Address6: string;
  City: string;
  StateProvinceID: number;
  StateProvinceDescription: string;
  Zip: string;
  ZipPlus4: string;
  CountryID: number;
  CountryDescription: string;
  County: string;
  Latitude: number;
  Longitude: number;
  Verified: boolean;
  FailedCommunication: boolean;
  CanEdit: boolean;
  CanDelete: boolean;
  Ready?: boolean;
  Invalid?: boolean;
  PrimaryContactCategory?: string; //for adding new address while creating person
}

export interface CreateUpdateAddressModel {
  ContactCategoryID: number;
  Primary: boolean;
  Address1: string;
  Address2?: string;
  Address3?: string;
  Address4?: string;
  Address5?: string;
  Address6?: string;
  City?: string;
  StateProvinceID?: number;
  Zip?: string;
  ZipPlus4?: string;
  CountryID?: number;
  County?: string;
  Latitude?: number;
  Longitude?: number;
  Verified?: boolean;
  FailedCommunication?: boolean;
}

export interface SmartyStreetsModel {
  country: string;
  prefix: string;
  city_filter?: string;
  state_filter?: string;
  address_id?: string;
}

export interface VerifyAddressModel {
  addressType: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zipcode: string;
}

export interface SmartyStreetsReturnModel {
  suggestions: SmartyStreetsSuggestionsModel[];
}

export interface SmartyStreetsSuggestionsModel {
  city: string;
  entries?: number;
  secondary?: string;
  state: string;
  street_line: string;
  zipcode: string;
}

export interface SmartyStreetsIntCandidatesReturnModel {
  candidates: SmartyStreetsIntCandidatesModel[];
}

export interface SmartyStreetsIntCandidatesModel {
  entries: number;
  address_text: string;
  address_id: string;
}

export interface SmartyStreetsIntReturnModel {
  candidates: SmartyStreetsIntSuggestionsModel[];
}

export interface SmartyStreetsIntSuggestionsModel {
  street: string;
  locality: string;
  administrative_area: string;
  postal_code: string;
  country_iso3?: string;
}

export interface suggestionsModel {
  items: SmartyStreetsSuggestionsModel[];
}

export interface intCandidatesModel {
  items: SmartyStreetsIntCandidatesModel[];
}
