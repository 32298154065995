<!--<p-card header="Address" class="parent-p-card">-->
<!--  <div class="p-grid p-fluid">-->
<!--    <div class="col-md-12 col-sm-12 col-xs-12">-->
<form [formGroup]="addressForm">
  <div class="p-grid p-fluid parent-form">
    @if (showCategory) {
      <div class="col-md-6 col-sm-6 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjContactCategory" (emitData)="getContactCategoryData($event)"></app-select>
      </div>
    }
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjCountry" (emitData)="getCountryData($event)"></app-select>
    </div>
<!--    <div class="col-md-3 col-sm-3 col-xs-0 input-container"></div>-->
    @if (this.mapDialogHeader && this.longitude && this.latitude) {
    <div class="col-md-3 col-sm-3 col-xs-12 p-mt-2">

        <div class="float-right p-mr-1">
          <button pButton label="Map" (click)="showMap()" class="p-button-info margin-top-20"
                icon="pi pi-map-marker" pTooltip="Show on Map" tooltipPosition="bottom"></button>
        </div>

    </div>
    }
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <label>Street Address@if (required) { <sup class="req">*</sup>}</label>
      @if (suggestAddress) {
        @if (international) {
          <p-autoComplete #autocomplete formControlName="streetAddress" [suggestions]="intAutocompleteEntryData.items" (completeMethod)="searchIntCandidates($event)" (onSelect)="searchIntCandidates($event)" (onFocus)="internationalEntries === true && autocomplete.suggestions?.length > 0" (onHide)="internationalEntries === true && autocomplete.suggestions?.length > 0 && autocomplete.show()" tabindex="0">
            <ng-template let-address pTemplate="addressDisplay">
<!--                  <div class="description-label">-->
                {{address.address_text}}
                @if (address.entries && address.entries > 1) {
                  <span class="p-ml-3" style="color: #069;">({{address.entries}} Entries)</span>
                }
<!--                  </div>-->
<!--                  <div class="icon-button">-->
<!--                    <i class="fas fa-minus-square" (click)="cancelSuggestions()" pTooltip="Cancel" tooltipPosition="right"></i>-->
<!--                  </div>-->
            </ng-template>
          </p-autoComplete>
          @if (required && (addressForm.controls.streetAddress.dirty || addressForm.controls.streetAddress.touched) &&
          addressForm.controls.streetAddress.errors?.required) {
            <span class="error">'Street Address' must be provided</span>
          }
        } @else {
          <p-autoComplete formControlName="streetAddress" [suggestions]="autocompleteData.items" (completeMethod)="search($event)" optionLabel="street_line" (onSelect)="applySuggestion($event)" tabindex="0">
            <ng-template let-address pTemplate="addressDisplay">
              {{address.street_line}}  {{address.city}}, {{address.state}} {{address.zipcode}}
            </ng-template>
          </p-autoComplete>
          @if (required && (addressForm.controls.streetAddress.dirty || addressForm.controls.streetAddress.touched) &&
          addressForm.controls.streetAddress.errors?.required) {
            <span class="error">'Street Address' must be provided</span>
          }
        }
      } @else {
        <input type="text" pInputText formControlName="streetAddress" maxlength="100" (change)="clearLatLong('streetAddress')" tabindex="0">
        @if (required && (addressForm.controls.streetAddress.dirty || addressForm.controls.streetAddress.touched) &&
        addressForm.controls.streetAddress.errors?.required) {
          <span class="error">'Street Address' must be provided</span>
        }
      }
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <label>Suite, Building, Floor, etc.</label>
      <input type="text" pInputText formControlName="streetAddress2" maxlength="100" (change)="clearLatLong('streetAddress2')" tabindex="0">
    </div>
    <div class="col-md-3 col-sm-3 col-xs-12 input-container">
      <label>City/Town@if (required) { <sup class="req">*</sup>}</label>
      <input type="text" pInputText formControlName="city" maxlength="100" (change)="clearLatLong('city')" tabindex="0">
      @if (required && (addressForm.controls.city.dirty || addressForm.controls.city.touched) &&
      addressForm.controls.city.errors?.required) {
        <span class="error">'City' must be provided</span>
      }
    </div>
    <div class="col-md-3 col-sm-3 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjState" (emitData)="getStateData($event)"></app-select>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-12 input-container">
      <label>Postal Code@if (required) { <sup class="req">*</sup>}</label>
      <input type="text" pInputText formControlName="postalCode" maxlength="10" (change)="clearLatLong('postalCode')" tabindex="0">
      @if (required && (addressForm.controls.postalCode.dirty || addressForm.controls.postalCode.touched) &&
      addressForm.controls.postalCode.errors?.required) {
        <span class="error">'Postal Code' must be provided</span>
      }
    </div>
    @if (showZipPlus4 && setCountryId === 1) {
      <div class="col-md-3 col-sm-3 col-xs-12 input-container">
        <label>ZIP+4</label>
        <input type="text" pInputText formControlName="zip4" maxlength="4" (change)="clearLatLong('zip4')" tabindex="0">
      </div>
    }
    @if (showCounty) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <label>County@if (requiredCounty) { <sup class="req">*</sup>}</label>
        <input type="text" pInputText formControlName="county" maxlength="100" (change)="processData()" tabindex="0">
        @if (requiredCounty && (addressForm.controls.county.dirty || addressForm.controls.county.touched) &&
        addressForm.controls.county.errors?.required) {
          <span class="error">'County' must be provided</span>
        }
      </div>
    }
  </div>
</form>
<!--    </div>-->
<!--  </div>-->
<!--</p-card>-->
