import * as FileSaver from 'file-saver';
import moment from 'moment';
import {Userpilot} from 'userpilot';
import * as TableHeadersDefinitions from '../definitions/table-headers.definitions';

export function exportData(fileName: string, data: any[], cols?: any[]): void {
  if (fileName) {
    Userpilot.track(`XLS Data Export: ${fileName}`);
  }
  data.forEach(dataSet => {
    for (const key of Object.keys(dataSet)) {
      if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/gm.test(dataSet[key])) {
        dataSet[key] = moment(dataSet[key]).format('MM-DD-YYYY');
      }
      if (cols) {
        const tempIndex = cols.findIndex(x => x.field === key);
        if (tempIndex !== -1) {
          //console.log('---------');
          // console.log(tempIndex);
          const tempHeader = cols[tempIndex].header;
          //console.log(tempHeader + ' === ' + key);
          if (tempHeader && tempHeader !== undefined && tempHeader !== key) {
            dataSet[tempHeader] = dataSet[key];
            delete dataSet[key];
          }
        } else {
          if (TableHeadersDefinitions.colHeaders) {
            const tempHeader = TableHeadersDefinitions.colHeaders[key];
            if (tempHeader && tempHeader !== undefined && tempHeader !== key) {
              dataSet[tempHeader] = dataSet[key];
              delete dataSet[key];
            }
          }
        }
      } else {
        if (TableHeadersDefinitions.colHeaders) {
          const tempHeader = TableHeadersDefinitions.colHeaders[key];
          if (tempHeader && tempHeader !== undefined && tempHeader !== key) {
            dataSet[tempHeader] = dataSet[key];
            delete dataSet[key];
          }
        }
      }
    }
  });
  import('xlsx').then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const dataFile: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(dataFile, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}

export function exportFormattedData(fileName: string, data: any[], orderHeader: any[]): void {
  if (fileName) {
    Userpilot.track(`XLS Data Export: ${fileName}`);
  }
  data.forEach(dataSet => {
    for (const key of Object.keys(dataSet)) {
      if(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/gm.test(dataSet[key])) {
        dataSet[key] = moment(dataSet[key]).format('MM-DD-YYYY');
      }
    }

  });
  import('xlsx').then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(data, {header:orderHeader});
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const dataFile: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(dataFile, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}

export function exportDataCSV(fileName: string, data: any[]): void {
  if (fileName) {
    Userpilot.track(`CSV Data Export: ${fileName}`);
  }
  data.forEach(dataSet => {
    for (const key of Object.keys(dataSet)) {
      if(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/gm.test(dataSet[key])) {
        dataSet[key] = moment(dataSet[key]).format('MM-DD-YYYY');
      }
    }
  });
  import('xlsx').then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'csv', type: 'array' });
    const EXCEL_TYPE = 'text/plain;charset=UTF-8';
    const EXCEL_EXTENSION = '.csv';
    const dataFile: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(dataFile, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}
