import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {
  CreateChildOrganizationModel,
  EducationalOrganizationsGridModel,
  EmployerMemberGridModel,
  EmployerModel,
  OrganizationBillingConfigurationModel,
  OrganizationExternalAccountsModel,
  OrganizationModel,
  OrganizationPctConfigurationAdjustmentCapListModel,
  OrganizationPctConfigurationFeeExcludeListModel,
  OrganizationPctConfigurationModel,
  OrganizationPctConfigurationTemplateCapListModel,
  OrganizationProjectModel,
  OrganizationsGridModel,
  OrganizationSharableAssigneesModel,
  OrganizationStatisticsModel,
  UpdateOrganizationModel,
  WorksiteOrganizationsGridModel
} from '../models/organizations.model';
import {LookupModel, OrganizationStructureLookupModel} from '../models/lookups.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getOrganizations(): Observable<OrganizationsGridModel> {
    return this.http.get<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/organizations`);
  }

  getOrganizationMerchantAccounts( id: number ): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/merchantaccounts/${id}`);
  }

  getOrganizationsPrimeNG(dataMode: string, filterData, orgId?: number, ): Observable<OrganizationsGridModel> {
    Userpilot.track('Organizations List');
    const url = (orgId) ? `&routeorganizationid=${orgId}` : '';
    return this.http.post<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/organizations?mode=${dataMode}${url}`, filterData);
  }

  getEmployersExpandedExport(filterData, orgId?: number): Observable<ArrayBuffer> {
    const url = (orgId) ? orgId : 0;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/employers/export/primeng/${orgId}?filetype=xlsx`, filterData, requestOptions);
  }

  getTrainingCenterPrimeNG(dataMode: string, filterData): Observable<EducationalOrganizationsGridModel> {
    Userpilot.track('Training Center List');
    return this.http.post<EducationalOrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/educationalorganizationgrid?mode=${dataMode}`, filterData);
  }

  getUnauthRootOrganizationId(tenantName: string, context: string): Observable<number> {
    return this.http.get<number>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/toplevelorganizationId/bytenant/${tenantName}/bycontext/${context}`);
  }

  addChildOrganizationByStructureType(parentOrganizationId: number, structureType: string, modelData: CreateChildOrganizationModel): Observable<OrganizationModel> {
    return this.http.post<OrganizationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${parentOrganizationId}/addChildOrganizationByStructureType/${structureType}`, modelData);
  }

  getEmployers(organizationId: number): Observable<OrganizationsGridModel> {
    return this.http.get<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/employers/${organizationId}`);
  }

  getEmployersPrimeNG(dataMode: string, filterData, organizationId: number ): Observable<OrganizationsGridModel> {
    Userpilot.track('Employer List');
    organizationId = organizationId !== undefined ? organizationId : 0;
    return this.http.post<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/employers/${organizationId}?mode=${dataMode}`, filterData);
  }

  getEmployerMembersPrimeNG(dataMode: string, filterData, organizationId: number ): Observable<EmployerMemberGridModel> {
    organizationId = organizationId !== undefined ? organizationId : 0;
    return this.http.post<EmployerMemberGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/membergrid?mode=${dataMode}`, filterData);
  }

  getWorksites(employerId: number): Observable<WorksiteOrganizationsGridModel> {
    return this.http.get<WorksiteOrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/worksites/${employerId}`);
  }

  getWorksitesByAccess(membership: string): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/worksites/byaccess/${membership}`);
  }

  getWorksitesPrimeNG(dataMode: string, filterData, employerId: number ): Observable<WorksiteOrganizationsGridModel> {
    return this.http.post<WorksiteOrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/worksites/${employerId}?mode=${dataMode}`, filterData);
  }

  deleteEmployer(organizationId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/delete/employer`);
  }

  deleteWorksite(organizationId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/delete/worksite`);
  }

  getOrganizationStatistics(organizationId: number, category: string): Observable<OrganizationStatisticsModel> {
    Userpilot.track('Vital Organization Stats');
    return this.http.get<OrganizationStatisticsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/stats/bycategory/${category}`);
  }

  getOrganizationConfig(organizationId: number, config: string): Observable<string> {
    return this.http.get<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/config/${config}`);
  }

  getOrganization(organizationId: number, category: string, excludeActiveCount?: number): Observable<OrganizationModel> {
    excludeActiveCount = excludeActiveCount === undefined ? 0 : excludeActiveCount;
    return this.http.get<OrganizationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/profile/${organizationId}/${category}?excludeActiveCount=${excludeActiveCount}`);
  }

  getEmployerOrganization(organizationId: number, category: string, excludeActiveCount?: number): Observable<EmployerModel> {
    Userpilot.track('Employer List');
    excludeActiveCount = excludeActiveCount === undefined ? 0 : excludeActiveCount;
    return this.http.get<EmployerModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/profile/${organizationId}/${category}?excludeActiveCount=${excludeActiveCount}`);
  }

  getOrganizationExternalAccount(organizationId: number, externalAccount: string): Observable<OrganizationExternalAccountsModel> {
    return this.http.get<OrganizationExternalAccountsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/organization/${organizationId}/externalaccount/${externalAccount}`);
  }

  updateOrganization(organizationId: number, modelData: UpdateOrganizationModel): Observable<OrganizationModel> {
    return this.http.put<OrganizationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/profile/${organizationId}`, modelData);
  }

  updateOrganizationLogoBanner(organizationId: number, resourceType: string, formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/profile/photo/${organizationId}/${resourceType}`, formData);
  }

  getOrganizationSharableAssignees(organizationId: number, pageEnum: string, context?: string): Observable<OrganizationSharableAssigneesModel[]> {
    const paramContext = (context) ? context : 'membership';
    return this.http.get<OrganizationSharableAssigneesModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/sharableassigneesbyobject/${pageEnum}?context=${paramContext}`);
  }

  createEmployer(organizationId: number, modelData: UpdateOrganizationModel): Observable<OrganizationModel> {
    return this.http.post<OrganizationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/addorganization/${organizationId}/employer`, modelData);
  }

  createOrganizeEmployer(modelData: OrganizationProjectModel): Observable<OrganizationProjectModel> {
    return this.http.post<OrganizationProjectModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/organization/create`, modelData);
  }

  deleteOrganizeEmployer(organizeProjectOrganizationId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/organization/remove/${organizeProjectOrganizationId}`);
  }

  createWorksite(employerId: number, modelData: UpdateOrganizationModel): Observable<OrganizationModel> {
    return this.http.post<OrganizationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/addorganization/${employerId}/worksite`, modelData);
  }

  getEmployerAssignmentsPrimeNG(dataMode: string, organizationId: number, filterData): Observable<OrganizationsGridModel> {
    return this.http.post<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/employerassignment/grid?mode=${dataMode}`, filterData);
  }

  getEmployerAssignmentPickerPrimeNG(dataMode: string, organizationId: number, filterData): Observable<OrganizationsGridModel> {
    return this.http.post<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/employerassignment/pickergrid?mode=${dataMode}`, filterData);
  }

  addEmployerAssignment(organizationId: number, ids: number[]): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/employerassignment/add`, ids);
  }

  removeEmployerAssignment(organizationId: number, ids: number[]): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/employerassignment/remove`, ids);
  }

  getEmployerNotAssignedPickerPrimeNG(dataMode: string, organizationId: number, filterData): Observable<OrganizationsGridModel> {
    return this.http.post<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/employernotassigned/pickergrid?mode=${dataMode}`, filterData);
  }

  getOrganizationVisibility(organizationId: number): Observable<any> {
    return this.http.get<OrganizationSharableAssigneesModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/upstreamgrid`);
  }

  updategetOrganizationVisibility(organizationId: number, upstreamOrgId: number, allowContact: boolean): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/allowcontact/${upstreamOrgId}/${allowContact}`, null);
  }

  getOrganizationEmployer(filterData ): Observable<OrganizationsGridModel> {
    return this.http.post<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/organization/grid`, filterData);
  }

  getOrganizationEmployerPrimeNG(dataMode: string, filterData): Observable<OrganizationsGridModel> {
    return this.http.post<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/organization/grid?mode=${dataMode}`, filterData);
  }

  getOrganizationBillingConfiguration(organizationId: number): Observable<OrganizationBillingConfigurationModel> {
    return this.http.get<OrganizationBillingConfigurationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/memberbilling/config`);
  }

  updateOrganizationBillingConfiguration(organizationId: number, data: OrganizationBillingConfigurationModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/memberbilling/config`, data);
  }

  getOrganizationPctConfiguration(organizationId: number): Observable<OrganizationPctConfigurationModel> {
    return this.http.get<OrganizationPctConfigurationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/organizationbilling/config`);
  }

  getOrganizationPctConfigurationFeeOverridesPrimeNG(dataMode: string, organizationId: number, filterData): Observable<OrganizationsGridModel> {
    return this.http.post<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/organizationbilling/feeexcludepicker?mode=${dataMode}`, filterData);
  }

  getOrganizationPctConfigurationTemplateOverridesPrimeNG(dataMode: string, organizationId: number, moneyAgentId: number, filterData): Observable<OrganizationsGridModel> {
    return this.http.post<OrganizationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/organizationbilling/template/${moneyAgentId}/overridepicker?mode=${dataMode}`, filterData);
  }

  updateOrganizationPctConfiguration(organizationId: number, data: OrganizationPctConfigurationModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/organizationbilling/config`, data);
  }

  updateOrganizationPctConfigurationFeeExcludes(organizationId: number, data: OrganizationPctConfigurationFeeExcludeListModel[]): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/organizationbilling/feeexcludes`, data);
  }

  updateOrganizationPctConfigurationTemplateCaps(organizationId: number, data: OrganizationPctConfigurationTemplateCapListModel[]): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/organizationbilling/templatecaps`, data);
  }

  updateOrganizationPctConfigurationAdjustmentCaps(organizationId: number, data: OrganizationPctConfigurationAdjustmentCapListModel[]): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/organizationbilling/adjustmentcaps`, data);
  }

  getOrganizationStructure(organizationId: number): Observable<OrganizationStructureLookupModel> {
    return this.http.get<OrganizationStructureLookupModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/orgstructsbyorganization/${organizationId}`);
  }
}
