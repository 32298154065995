import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {ContextService} from '../../../../services/context.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {Accordion} from 'primeng/accordion';
import {Router} from '@angular/router';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {AuxiliaryFilterModel, RosterQueryExistingModel} from '../../../../models/roster-query-primeng.model';
import {RosterQueryService} from '../../../../services/roster-queries.service';
import {DistributionListDefinitions} from '../../../definitions/distribution-list.definitions';
import {RosterService} from '../../../../services/roster.service';
import {StudentsService} from '../../../../services/students.service';
import {ProspectsService} from '../../../../services/prospects.service';
import {ProjectReportsService} from '../../../../services/project-reports.service';
import {AuxiliaryListNewEditDialogComponent} from '../auxiliary-list-new-edit-dialog/auxiliary-list-new-edit-dialog.component';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {RouteContextUtility} from '../../../utilities/route-context.utility';
import {PageService} from '../../../../services/page.services';
import {InterfaceObjectItemPermissions} from 'src/app/models/interface-objects.model';
import * as FileSaver from 'file-saver';
import {RosterTabContainerComponent} from '../../../../roster/roster-tab-container/roster-tab-container.component';
import {ProspectsTabContainerComponent} from '../../../../prospects/prospects-tab-container/prospects-tab-container.component';
import {RosterStudentTabContainerComponent} from '../../../../roster-student/roster-student-tab-container/roster-student-tab-container.component';
import {AuxTablePreviewDialogComponent} from '../auxiliary-filter-existing-form/aux-table-preview/aux-table-preview-dialog.component';
import {OrganizationService} from '../../../../services/organizations.service';
import {ProjectsReportTabContainerComponent} from '../../../../project-reports/projects-tab-container/projects-report-tab-container.component';
import {OrganizationModel} from '../../../../models/organizations.model';
import {TenantConfigDataService} from '../../../../services/tenant-config-data.service';
import {InactiveStatusUtility} from '../../../utilities/inactive-status.utility';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';
import {UserRosterPiiAttestationService} from '../../../../services/user-roster-pii-attestation.service';
import {ConfirmExportDialogComponent} from './confirm-exort-dialog/confirm-export-dialog.component';
import {InterfaceConfigListModel} from '../../../../models/interface-config.model';
import {InterfaceConfigService} from '../../../../services/interface-config.service';
import {EmployerTabContainerComponent} from '../../../../employers/employers-tab-container/employer-tab-container.component';
import {OrganizationTabContainerComponent} from '../../../../organizations/organizations-tab-container/organization-tab-container.component';


@Component({
  selector: 'app-auxilary-grid',
  templateUrl: './auxiliary-grid.component.html',
  styleUrls: ['./auxiliary-grid.component.scss']
})

export class AuxiliaryGridComponent implements OnInit, OnDestroy, OnChanges {
  ContextEnumerator: string;
  mainForm: FormGroup;
  @Input() personId: number;
  @Input() isGridPage: boolean;
  canEdit: boolean = true;
  @ViewChild('accordion') accordion: Accordion;
  accordionIndex: any[] = [0];
  projectReports;
  isAdmin: boolean;
  isSaving: boolean = false;
  selorganization: OrganizationModel[];
  organizationList: OrganizationModel[];
  employerList: OrganizationModel[];
  inputObjOrganization: GenericSelectModel;
  setOrganizationId: number;
  inputObjRosterQuery: GenericSelectModel;
  currentFilter = new AuxiliaryFilterModel();
  setRosterQueryId: number;
  isNewDist: boolean = false;
  selectedRosterQuery = {} as RosterQueryExistingModel;
  dataTotal: number = 0;
  cols = [];
  rosterQueryList: any[];
  showTable: boolean = true;
  isRosterTable: boolean = true;
  loading: boolean = false;
  isExporting: boolean;
  canExportPii: boolean;
  exportPii: boolean;
  canDelete: boolean = true;
  selMemberId: number;
  getListRequest;
  getTotalRequest;
  @Input() canPageWrite: boolean;
  @Input() currentPage: string;
  @Input() parentObjectEnum: string;
  selStudent;
  showAdd: boolean = true;
  isPrivacyChecked: boolean;
  listReady: boolean = false;
  openExpand: boolean = false;
  routeContext: string;
  isStatic: boolean;
  blobToken: string;
  inactiveStatuses: string[] = [];
  getProjectListRequest;
  getProjectTotalRequest;
  getProspectListRequest;
  getProspectTotalRequest;
  getListStudentRequest;
  getTotalStudentRequest;
  userPreferences: InterfaceConfigListModel[] = [];
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService, private messageService: MessageService,
              private contextService: ContextService, private dialogService: DialogService, public router: Router,
              private rosterQueryService: RosterQueryService, private distributionListDefinitions: DistributionListDefinitions,
              private confirmationService: ConfirmationService, private changeDetector: ChangeDetectorRef,
              private rosterService: RosterService, private prospectsService: ProspectsService,
              private studentsService: StudentsService, private routeContextUtility: RouteContextUtility,
              private pageService: PageService, private projectReportsService: ProjectReportsService,
              private organizationService: OrganizationService, private tenantConfigDataService: TenantConfigDataService,
              private inactiveStatusUtility: InactiveStatusUtility, public pageRowCountUtility: PageRowCountUtility,
              private userRosterPiiAttestationService: UserRosterPiiAttestationService, private interfaceConfigService: InterfaceConfigService
  ) {
    this.mainForm = this.formBuilder.group({
      organization: new FormControl(false, Validators.required),
      distributionList: new FormControl(null, Validators.required),
      name: new FormControl(null),
    });

    if (this.router.url.includes('/organizations/')) {
      this.isRosterTable = false;
      this.initOrganization(false, 'ORGPROFILE', false);
    } else if (this.router.url.includes('/membership/employers/')) {
      this.isRosterTable = false;
      this.initOrganization(false, 'EMPPROFILE', false);
    } else if (!this.router.url.includes('/project-reports/')) {
      this.currentFilter.sortField = 'LastName';
    }
  }

  _selectedColumns: any[];

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }

  ngOnInit() {
    this.blobToken = this.contextService.contextObject.blobToken;
    this.routeContext = this.routeContextUtility.getRouteContext();
    this.pageService.getPagePermissions('DISTRIBUTIONLIST', true).then((res: InterfaceObjectItemPermissions) => {
      this.canPageWrite = (res) ? res?.Write : false;
    });
    this.inactiveStatusUtility.getPersonProfileInactiveStatuses(this.routeContext).then(x => {
      this.inactiveStatuses = x;
    }).catch(y => {
      this.inactiveStatuses = y;
    });
    this.isAdmin = !this.router.url.includes('my-time');
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.currentFilter.AuxFilters = [];
    this.getUserAttestation();
    this.initRosterQuery(false);
  }

  getUserAttestation() {
    this.userRosterPiiAttestationService.getUserPiiAttestation()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.isPrivacyChecked = res.PiiAttestation;
        }
      });
  }

  processRoute() {
    this.routeContext = this.routeContextUtility.getRouteContext();
    this.ContextEnumerator = this.routeContext.toUpperCase();
    switch (this.routeContext) {
      case 'membership':
        if (this.isRosterTable) {
          this.cols = this.distributionListDefinitions.rosterCols.filter(x => x.visible === true);
          if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_Roster')) {
            this._selectedColumns = JSON.parse(this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_Roster')?.Value);
          } else {
            // console.log('in else');
            this._selectedColumns = this.cols;
          }

        } else {
          console.log(this.parentObjectEnum);
          if (this.parentObjectEnum === 'ORGPROFILE') {
            // console.log('in here');
            if (this.router.url.includes('organizations')) {
              this.cols = this.distributionListDefinitions.organizationCol.filter(x => x.visible === true);
              if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_Organization')) {
                this._selectedColumns = JSON.parse(this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_Organization')?.Value);
              } else {
                this._selectedColumns = this.cols;
              }
            } else {
              this.cols = this.distributionListDefinitions.employersCols.filter(x => x.visible === true);
              if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_Employers')) {
                this._selectedColumns = JSON.parse(this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_Employers')?.Value);
              } else {
                this._selectedColumns = this.cols;
              }
            }
          }
        }

        break;
      case 'training':
        this.cols = this.distributionListDefinitions.rosterStudentCols.filter(x => x.visible === true);
        if (this.isRosterTable) {
          this.cols = this.distributionListDefinitions.rosterStudentCols.filter(x => x.visible === true);
          if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_StudentRoster')) {
            this._selectedColumns = JSON.parse(this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_StudentRoster')?.Value);
          } else {
            // console.log('in else');
            this._selectedColumns = this.cols;
          }
        }
        break;
      case 'organize':
        this.cols = this.distributionListDefinitions.prospectCols.filter(x => x.visible === true);
        if (this.isRosterTable) {
          this.cols = this.distributionListDefinitions.rosterCols.filter(x => x.visible === true);
          if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_Prospects')) {
            this._selectedColumns = JSON.parse(this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_Prospects')?.Value);
          } else {
            // console.log('in else');
            this._selectedColumns = this.cols;
          }
        }
        break;
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPage) {
      if (this.currentPage === 'projectReports') {
        this.routeContext = this.routeContextUtility.getRouteContext();
        this.ContextEnumerator = this.routeContext.toUpperCase();
        this.cols = this.distributionListDefinitions.projectReportsCols.filter(x => x.visible === true);
        if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_ProjectReports')) {
          this._selectedColumns = JSON.parse(this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'AuxColumns_ProjectReports')?.Value);
        } else {
          this._selectedColumns = this.cols;
        }
        // this.getProjectList();
        // this.getProjectsTotal();
      } else {
        this.processRoute();
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }


  initRosterQuery(disable: boolean): void {
    this.inputObjRosterQuery = {
      labelText: 'Distribution List',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setRosterQueryId,
      data: null,
      disabled: disable,
      canWrite: true,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getRosterQueriesLookupData(null, this.routeContext).then((lookupData) => {
      this.inputObjRosterQuery.data = lookupData;
      this.inputObjRosterQuery = Object.assign({}, this.inputObjRosterQuery);
    });
  }

  setRosterQueryData(event: any): void {
    if (event && event[0]) {
      this.setRosterQueryId = event[0].ID;
    } else {
      this.setRosterQueryId = 0;
    }
    this.mainForm.get('distributionList').setValue(this.setRosterQueryId);
    if (this.setRosterQueryId && this.setRosterQueryId !== 0) {
      this.getExistingRosterQuery(this.setRosterQueryId);
    }
  }

  getExistingRosterQuery(setRosterQueryId: number) {
    this.showTable = false;
    this.rosterQueryService.getRosterQueryPrimeNg(setRosterQueryId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.mainForm.get('name').setValue(res.Description);
          this.currentFilter.AuxFilters = [];
          if (res.Filters.length < 1) {
            this.isStatic = true;
          } else {
            res.Filters.forEach(filter => {
              this.currentFilter.AuxFilters.push(filter);
            });
            this.setRosterQueryId = 0;
          }
          this.listReady = true;
          this.selectedRosterQuery = res;
          this.changeDetector.detectChanges();
          this.showTable = true;
          this.changeDetector.detectChanges();
        }
      });
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: this.setOrganizationId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
      this.setOrganizationId = this.inputObjOrganization.data[1].ID;
    });

  }

  setOrganizationData(event: any): void {
    if (event && event[0]) {
      this.setOrganizationId = (event[0].ID !== null) ? event[0].ID : -1;
    } else {
      this.setOrganizationId = -1;
    }
  }

  findTableName(table): string {
    let rtn: string;
    switch (table) {
      case 'ATTRIBUTE':
      case 'STUDENTATTRIBUTE':
      case 'EMPATTRIBUTE':
      case 'ORGATTRIBUTE' :
        rtn = 'CUSTOM FIELDS';
        break;
      case 'EDUCATIONPOSITION':
      case 'UNIONPOSITION':
        rtn = 'POSITIONS';
        break;
      case 'STUDENT':
        rtn = 'STUDENT ROSTER';
        break;
      case 'PROJECTEMPLOYER':
        rtn = 'EMPLOYER';
        break;
      case 'COMMUNICATIONSTATUS':
        rtn = 'COMMUNICATION';
        break;
      case 'VOTER':
        rtn = 'VOTER';
        break;
      case 'STUDENTCLASS':
        rtn = 'CLASSES';
        break;
      case 'STUDENTPROGRAM':
        rtn = 'PROGRAMS';
        break;
      // case 'SURVEYRESPONSE':
      //   rtn = 'SURVEYS';
      //   break;
      default:
        rtn = table;
        break;
    }
    return rtn;
  }

  showPreview(tableName) {
    return tableName === 'UNIONPOSITION' || tableName === 'EDUCATIONPOSITION' || tableName === 'PROJECTEMPLOYER' ||
      tableName === 'VOTER' || tableName === 'STUDENTCLASS' || tableName === 'STUDENTPROGRAM' || tableName === 'ATTRIBUTE' || tableName === 'STUDENTATTRIBUTE' || tableName === 'EMPATTRIBUTE';
  }

  openAuxPreview(filter): void {
    this.findTableName(filter.Enumerator);
    const ref = this.dialogService.open(AuxTablePreviewDialogComponent, {
      data: {
        relatedTable: filter.Enumerator,
        selectedRosterQuery: filter,
        tableName: this.findTableName(filter.Enumerator)
      },
      header: 'Filter Preview',
      width: '90%',
    });
  }

  getRosterList() {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.loading = true;
    this.getListRequest = this.rosterService.getRosterPrimeNG('DATA', this.currentFilter, this.setRosterQueryId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.rosterQueryList = res.Data;
          this.loading = false;
        }
      });
  }

  getRosterTotal() {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.rosterService.getRosterPrimeNG('TOTAL', this.currentFilter, this.setRosterQueryId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  getStudentList() {
    this.loading = true;
    if (this.getListStudentRequest) {
      this.getListStudentRequest.unsubscribe();
    }
    this.getListStudentRequest = this.studentsService.getStudentsPrimeNG('DATA', this.currentFilter, this.setRosterQueryId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.rosterQueryList = res.Data;
          this.loading = false;
        }
      });
  }

  getStudentTotal() {
    if (this.getTotalStudentRequest) {
      this.getTotalStudentRequest.unsubscribe();
    }
    this.getTotalStudentRequest = this.studentsService.getStudentsPrimeNG('TOTAL', this.currentFilter, this.setRosterQueryId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  getProspectsTotal() {
    if (this.getProspectTotalRequest) {
      this.getProspectTotalRequest.unsubscribe();
    }
    this.getProspectTotalRequest = this.prospectsService.getProspectsPrimeNG('TOTAL', this.currentFilter, this.setRosterQueryId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  getProjectList() {
    if (this.getProjectListRequest) {
      this.getProjectListRequest.unsubscribe();
    }
    this.loading = true;
    this.getProjectListRequest = this.projectReportsService.getProjectReportsPrimeNG('DATA', this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.rosterQueryList = res.Data;
          this.loading = false;
        }
      });
  }

  getProjectsTotal() {
    if (this.getProjectTotalRequest) {
      this.getProjectTotalRequest.unsubscribe();
    }
    this.getProjectTotalRequest = this.projectReportsService.getProjectReportsPrimeNG('TOTAL', this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  getOrganizationTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.organizationService
      .getOrganizationsPrimeNG('TOTAL', this.currentFilter, this.setOrganizationId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  getEmployerTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.organizationService
      .getEmployersPrimeNG('TOTAL', this.currentFilter, this.setOrganizationId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  getEmployerList(): void {
    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.organizationService
      .getEmployersPrimeNG('DATA', this.currentFilter, this.setOrganizationId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.employerList = res.Data;
          console.log(this.employerList);
          this.loading = false;
        }, error:
          (err) => {
            console.warn(err);
          }
      });
  }


  getProspectsList() {
    if (this.getProspectListRequest) {
      this.getProspectListRequest.unsubscribe();
    }
    this.loading = true;
    this.getProspectListRequest = this.prospectsService.getProspectsPrimeNG('DATA', this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.rosterQueryList = res.Data;
          this.loading = false;
        }
      });
  }


  getOrganizationData(): void {
    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.organizationService
      .getOrganizationsPrimeNG('DATA', this.currentFilter, this.setOrganizationId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.organizationList = res.Data;
          this.loading = false;
        }, error:
          (e) => {
            console.warn(e);
          }
      });
  }

  getEmployerData(): void {
    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.organizationService
      .getOrganizationsPrimeNG('DATA', this.currentFilter, this.setOrganizationId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.organizationList = res.Data;
          this.loading = false;
        }, error:
          (err) => {
            console.warn(err);
          }
      });
  }

  loadTable(event?) {
    this.loading = true;
    if (event) {
      if (event.rows) {
        this.currentFilter.rows = event.rows;
      } else {
        this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
      }
      if (event.first) {
        this.currentFilter.first = event.first;
      } else {
        this.currentFilter.first = 0;
      }
      if (event.sortField) {
        this.currentFilter.sortField = event.sortField;
      }
      if (event.sortField) {
        this.currentFilter.sortOrder = event.sortOrder;
      } else {
        this.currentFilter.sortOrder = 1;
      }
      if (event.AuxFilters && event.AuxFilters.length > 0) {
        //if table exists
        const columnName = Object.keys(event.AuxFilters[0].FilterPrimeNG.filters)[0];
        if (this.currentFilter.AuxFilters.length > 0 && this.currentFilter.AuxFilters.find(x => x.Enumerator === event.AuxFilters[0].Enumerator)) {
          const currentTableFilter = this.currentFilter.AuxFilters.find(x => x.Enumerator === event.AuxFilters[0].Enumerator);
          if (!currentTableFilter.FilterPrimeNG.filters[columnName]) {
            currentTableFilter.FilterPrimeNG.filters[columnName] = [];
          }
          this.currentFilter.AuxFilters.find(x => x.Enumerator === event.AuxFilters[0].Enumerator).FilterPrimeNG
            .filters[columnName] = event.AuxFilters[0].FilterPrimeNG.filters[columnName];
        } else {
          event.AuxFilters.forEach(filter => {
            this.currentFilter.AuxFilters.push(filter);
          });
        }

      }
      this.openExpand = true;
    }
    if (this.parentObjectEnum !== 'ORGPROFILE') {
      switch (this.ContextEnumerator) {
        case 'MEMBERSHIP' : {
          this.getRosterList();
          this.getRosterTotal();
          break;
        }
        case 'TRAINING' : {
          this.getStudentList();
          this.getStudentTotal();
          break;
        }
        case 'ORGANIZE' : {
          if (this.currentPage !== 'projectReports') {
            this.getProspectsList();
            this.getProspectsTotal();
          } else {

            this.getProjectList();
            this.getProjectsTotal();
          }

        }
      }
    }

    if (!this.isRosterTable && this.parentObjectEnum === 'ORGPROFILE') {
      if (this.router.url.includes('organizations')) {
        this.getOrganizationData();
        this.getOrganizationTotal();
      } else {
        this.getEmployerList();
        this.getEmployerTotal();
      }
    }

  }

  deleteFilter(deleteFilter) {
    const workingArray: any[] = this.currentFilter.AuxFilters;
    const deleteFilterIndex: number = this.currentFilter.AuxFilters.find(table => table.Enumerator === deleteFilter.table).FilterPrimeNG.filters[deleteFilter.column].findIndex(filter => filter.value === deleteFilter.value);

    workingArray.find(table => table.Enumerator === deleteFilter.table)
      .FilterPrimeNG.filters[deleteFilter.column].splice(deleteFilterIndex, 1);

    //if column has empty filters after removal need to remove the column from filter
    if (workingArray.find(table => table.Enumerator === deleteFilter.table)
      .FilterPrimeNG.filters[deleteFilter.column].length === 0) {
      delete workingArray.find(table => table.Enumerator === deleteFilter.table)
        .FilterPrimeNG.filters[deleteFilter.column];
    }
    //if table no longer has filters remove from list
    const isEmptyFilter = Object.keys(workingArray.find(table => table.Enumerator === deleteFilter.table).FilterPrimeNG.filters).length;
    if (isEmptyFilter === 0) {
      const deleteTableIndex = workingArray.findIndex(table => table.Enumerator === deleteFilter.table);
      workingArray.splice(deleteTableIndex, 1);
    }

    this.currentFilter.AuxFilters = workingArray;

    this.loadTable(this.currentFilter);
  }

  showAddFilter() {
    this.showAdd = !this.showAdd;
  }

  onRowSelect(event: any) {
    this.canDelete = event.data.CanDelete;
    this.selMemberId = event.data.PersonID;
  }

  onRowUnselect(event: any) {
    this.selMemberId = undefined;
  }

  goToEdit(selectedItem) {
    let name;
    let id;
    sessionStorage.setItem('sessionViewType', 'Grid');
    let component;
    if (this.routeContext === 'membership' && this.isRosterTable) {
      component = RosterTabContainerComponent;
      name = selectedItem.FirstName + ' ' + selectedItem.LastName;
      id = selectedItem.PersonID;
    }
    if (this.routeContext === 'training' && this.isRosterTable) {
      component = RosterStudentTabContainerComponent;
      name = selectedItem.FirstName + ' ' + selectedItem.LastName;
      id = selectedItem.PersonID;
    }
    if (this.routeContext === 'organize') {
      if (this.currentPage !== 'projectReports') {
        component = ProspectsTabContainerComponent;
        name = selectedItem.FirstName + ' ' + selectedItem.LastName;
        id = selectedItem.PersonID;
      } else {
        console.log(selectedItem);
        component = ProjectsReportTabContainerComponent;
        name = selectedItem.ProjectName;
        id = selectedItem.OrganizeProjectID;
      }

    }
    if (!this.isRosterTable && this.router.url.includes('organization')) {
      component = OrganizationTabContainerComponent;
      name = selectedItem.Name;
      id = selectedItem.ID;
    }
    if (!this.isRosterTable && this.router.url.includes('employer')) {
      component = EmployerTabContainerComponent;
      name = selectedItem.Name;
      id = selectedItem.ID;
    }

    const ref = this.dialogService.open(component, {
      data: {
        id,
        isEdit: true,
        isDialog: true
      },
      header: name,
      width: '90%',
      height: '90%'
    });
  }

  processNewDistList(newQueryID) {
    this.rosterQueryService.getRosterQueryPrimeNg(newQueryID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.selectedRosterQuery = res;
          this.isNewDist = false;
          this.setOrganizationId = this.selectedRosterQuery.OrganizationID;
          this.setRosterQueryId = newQueryID;
          this.initRosterQuery(false);
          this.mainForm.get('name').setValue(this.selectedRosterQuery.Description);
        }
      });
  }


  closeAllAccordionTabs() {
    if (this.accordion) {
      this.accordionIndex = [];
    }
  }

  openAllAccordionTabs() {
    this.accordionIndex = [];
    this.accordion.tabs.forEach(tab => {
      this.accordionIndex.push(tab.findTabIndex());
    });
  }

  confirmExport() {
    console.log(this.canExportPii);
    const ref = this.dialogService.open(ConfirmExportDialogComponent, {
      data: {
        organizationID: this.setOrganizationId,
        canExportPii: this.canExportPii,
        isRoster: this.isRosterTable
      },
      header: 'Data Export',
      width: '500px',
      height: '200px'
    });
    ref.onClose.subscribe({
      next: (res) => {
        if (res !== null && res !== undefined) {
          this.exportPii = res;
          this.exportAll();
        }
      }
    });
    // this.confirmationService.confirm({
    //   message: 'Please be aware that exporting times may vary (even up to several minutes). Thank you for your patience.',
    //   acceptLabel: 'Continue',
    //   rejectLabel: 'Cancel',
    //   header: 'Data Export',
    //   icon: 'pi pi-download',
    //   accept: () => {
    //     this.exportAll();
    //   },
    //   reject: () => {
    //     this.confirmationService.close();
    //   }
    // });
  }

  export(): void {

    this.isExporting = true;
    if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
      const arr = JSON.parse(JSON.stringify(this.rosterQueryList));
      const dataToExport = removeKeysFromArray(arr, ['PersonID', 'OrganizationID', 'HideContactInfo', 'HistoricReadOnly', 'PersonProfileID']);
      exportData('roster', dataToExport);
      this.isExporting = false;
    } else {
      switch (this.ContextEnumerator) {
        case 'MEMBERSHIP' : {
          const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
          exportFilter.first = 0;
          exportFilter.rows = null;
          this.rosterService
            .getRosterPrimeNG('DATA', exportFilter, this.setOrganizationId)
            .pipe(take(1))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, ['PersonID', 'OrganizationID', 'HideContactInfo', 'HistoricReadOnly', 'PersonProfileID']);
                exportData('roster', dataToExport);
                this.isExporting = false;
              }
            });
          break;
        }
        case 'TRAINING' : {
          const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
          exportFilter.first = 0;
          exportFilter.rows = null;
          this.studentsService
            .getStudentsPrimeNG('DATA', exportFilter, this.setOrganizationId)
            .pipe(take(1))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, ['PersonID', 'OrganizationID', 'HideContactInfo', 'HistoricReadOnly', 'PersonProfileID']);
                exportData('students', dataToExport);
              }
            });
          break;
        }

        case 'ORGANIZE' : {
          const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
          exportFilter.first = 0;
          exportFilter.rows = null;
          this.prospectsService
            .getProspectsPrimeNG('DATA', exportFilter)
            .pipe(take(1))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, ['PersonID', 'OrganizationID', 'HideContactInfo', 'HistoricReadOnly', 'PersonProfileID']);
                exportData('prospects', dataToExport);
              }
            });
          break;
        }
      }

    }
  }

  exportAll() {
    this.isExporting = true;
    if (this.ContextEnumerator !== 'ORGANIZE' && this.exportPii) {
      this.currentFilter.IncludePii = this.exportPii;
    }
    if (this.isRosterTable) {
      // roster export
      switch (this.ContextEnumerator) {
        case 'MEMBERSHIP' : {
          this.rosterService.getRosterExpandedExport(this.currentFilter, this.setRosterQueryId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.downloadFile(res, 'Roster.xlsx', 'text/plain;charset=utf-8');
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'Exported Successfully'
                });
                this.isExporting = false;
              }, error: (e) => {
                console.debug(e);
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
              }
            });
          break;
        }
        case 'TRAINING' : {
          this.studentsService.getStudentsExpandedExport(this.currentFilter, this.setRosterQueryId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.downloadFile(res, 'Students.xlsx', 'text/plain;charset=utf-8');
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'Exported Successfully'
                });
                this.isExporting = false;
              }, error: (e) => {
                console.debug(e);
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
              }
            });
          break;
        }
        case 'ORGANIZE' : {
          if (this.currentPage !== 'projectReports') {
            this.prospectsService.getProspectsExpandedExport(this.currentFilter, this.setRosterQueryId)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (res) => {
                  this.downloadFile(res, 'Prospects.xlsx', 'text/plain;charset=utf-8');
                  this.messageService.add({
                    severity: 'success', summary: 'Success',
                    detail: 'Exported Successfully'
                  });
                  this.isExporting = false;
                }, error: (e) => {
                  console.debug(e);
                  this.messageService.add({
                    severity: 'error', summary: 'Error',
                    detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                  });
                }
              });
          } else {
            this.projectReportsService.exportProtectReports(this.currentFilter)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (res) => {
                  this.downloadFile(res, 'project-reports.xlsx', 'text/plain;charset=utf-8');
                  this.isExporting = false;
                }
              });
          }

          break;
        }
      }
    } else {
      // organization export
      if (this.router.url.includes('organizations')) {
        if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
          const arr = JSON.parse(JSON.stringify(this.organizationList));
          const dataToExport = removeKeysFromArray(arr, ['ID', 'OrganizationReferenceID', 'StatusID', 'TypeID', 'IsEmployer', 'IsReferenced', 'Address3', 'Address4', 'Address5', 'Address6', 'StateProvinceID', 'CountryID', 'Verified', 'FailedCommunication', 'PhonePhoneTypeID', 'PhoneVerified', 'PhoneFailedCommunication', 'EmailVerified', 'EmailFailedCommunication', 'UTStatusID', 'Level',
            'CanProvisionSMSNumber', 'ProvisionSMSNumber', 'SMSNumber', 'ProvisionSMSNumberPrefix', 'ParentName', 'OrganizationTypeRank', 'organizationCategoryEnum', 'UsingDuesCollection', 'ConfigurationList', 'HasIndependentChildren', 'PathwayDescriptionList', 'BondAmount_N', 'CharterLocation', 'CharterStateProvinceID', 'ByLawApprovalDate',
            'NextElectionDate', 'MeetingDetails', 'StatusReason', 'StatusEffectiveDate', 'PrimaryTrade', 'PrimaryTradeID', 'ClassificationID', 'ClassificationDescription', 'CanEditName', 'AllowWaitListOption', 'BusinessName', 'WebsiteUri', 'PrimaryContact', 'HomeOrganizationID', 'HomeOrganizationName', 'HomeOrganizationAbbreviation', 'EmployerTypeID',
            'EmployerType', 'EnterpriseTypeID', 'EnterpriseType', 'MinorityStatusID', 'MinorityStatus', 'Subdivision', 'Subsidiary', 'Language9a', 'PensionParticipating', 'JoinDate', 'DoNotSendJournal', 'Identifier']);
          exportData('organizations', dataToExport);
          this.isExporting = false;
        } else {
          const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
          exportFilter.first = 0;
          exportFilter.rows = null;
          this.organizationService
            .getOrganizationsPrimeNG('DATA', exportFilter, this.setOrganizationId)
            .pipe(take(1))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, ['ID', 'OrganizationReferenceID', 'StatusID', 'TypeID', 'IsEmployer', 'IsReferenced', 'Address3', 'Address4', 'Address5', 'Address6', 'StateProvinceID', 'CountryID', 'Verified', 'FailedCommunication', 'PhonePhoneTypeID', 'PhoneVerified', 'PhoneFailedCommunication', 'EmailVerified', 'EmailFailedCommunication', 'UTStatusID', 'Level',
                  'CanProvisionSMSNumber', 'ProvisionSMSNumber', 'SMSNumber', 'ProvisionSMSNumberPrefix', 'ParentName', 'OrganizationTypeRank', 'organizationCategoryEnum', 'UsingDuesCollection', 'ConfigurationList', 'HasIndependentChildren', 'PathwayDescriptionList', 'BondAmount_N', 'CharterLocation', 'CharterStateProvinceID', 'ByLawApprovalDate',
                  'NextElectionDate', 'MeetingDetails', 'StatusReason', 'StatusEffectiveDate', 'PrimaryTrade', 'PrimaryTradeID', 'ClassificationID', 'ClassificationDescription', 'CanEditName', 'AllowWaitListOption', 'BusinessName', 'WebsiteUri', 'PrimaryContact', 'HomeOrganizationID', 'HomeOrganizationName', 'HomeOrganizationAbbreviation', 'EmployerTypeID',
                  'EmployerType', 'EnterpriseTypeID', 'EnterpriseType', 'MinorityStatusID', 'MinorityStatus', 'Subdivision', 'Subsidiary', 'Language9a', 'PensionParticipating', 'JoinDate', 'DoNotSendJournal', 'Identifier']);
                exportData('organizations', dataToExport);
                this.isExporting = false;
              }, error: (e) => {
                console.debug(e);
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
              }
            });
        }
      } else if (this.router.url.includes('employers')) {
        this.organizationService.getEmployersExpandedExport(this.currentFilter, this.setOrganizationId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.downloadFile(res, 'Employers.xlsx', 'text/plain;charset=utf-8');
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'Exported Successfully'
              });
              this.isExporting = false;
            }, error: (e) => {
              console.debug(e);
              this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          });
      } else {
        this.isExporting = true;
        if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
          const arr = JSON.parse(JSON.stringify(this.employerList));
          // eslint-disable-next-line max-len
          const dataToExport = removeKeysFromArray(arr, ['Number', 'CharterDate', 'CharterDescription', 'LM', 'Rank', 'LogoUrl', 'BannerUrl', 'BankRoutingNumber', 'BankAccountNumber', 'PACRoutingNumber', 'PACAccountNumber', 'ISEmployer', 'EsReferenced', 'Country', 'Latitude', 'Longitude', 'Verified', 'FailedCommunication', 'PhonePhoneTypeID', 'PhoneVerifeid', 'PhoneFailedCommunication', 'EmailVerified', 'EmailFailedCommunciation', 'ExternalAccounts', 'DirectAccess', 'CanProvisionSMSNum',
            'ProvisionSMSNumber', 'ProvisionSMSNumberPrefix', 'ParentName', 'OrganizationtypeRank', 'OrganizationCategoryEnum', 'HasIndependentChildren', 'PathwayDescriptionLIst', 'BondAmount_N', 'CharterLocation', 'CharterStateProvinceID', 'ByLawApprovalDate', 'NextElectionDate', 'MeetingDetails', 'StatusReason', 'StatusEffectiveDate', 'PrimaryTradeID', 'ClassificationID', 'ClassificationDescription', 'CanEditName', 'AllowWaitListOption', 'BusinessName', 'WebsiteUri', 'HomeOrganizationID',
            'HomeOrganizationName', 'HomeOrganizationAbbreviation', 'EmployerTypeID', 'EmployerType', 'EnterpriseTypeID', 'EnterpriseType', 'MinorityStatusID', 'MinorityStatus', 'Subdivision', 'Subsidiary', 'Language9a', 'PensionParticipating', 'JoinDate', 'DoNotSendJournal', 'Identifier', 'OrganizationReferenceID', 'StatusID', 'TypeID', 'RegisteredCount_N', 'WorkSiteCount_N', 'IsEmployer', 'IsReferenced', 'Address4', 'Address5', 'Address6', 'StateProvinceID', 'CountryID', 'PhoneVerified',
            'EmailedFailedCommunication', 'UTStatusID', 'UTStatusDescription', 'SortString', 'Level', 'OrganizationType', 'CanProvisionSMSNumber', 'SMSNumber', 'OrganizationTypeRank', 'organizationCategoryEnum', 'UsingDuesCollection', 'ConfigurationList', 'PathwayDescriptionList', 'PrimaryTrade']);
          exportData('employers', dataToExport);
          this.isExporting = false;
        } else {
          const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
          exportFilter.first = 0;
          exportFilter.rows = null;
          this.organizationService
            .getEmployersPrimeNG('DATA', exportFilter, this.setOrganizationId)
            .pipe(take(1))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                // eslint-disable-next-line max-len
                const dataToExport = removeKeysFromArray(arr, ['Number', 'CharterDate', 'CharterDescription', 'LM', 'Rank', 'LogoUrl', 'BannerUrl', 'BankRoutingNumber', 'BankAccountNumber', 'PACRoutingNumber', 'PACAccountNumber', 'ISEmployer', 'EsReferenced', 'Country', 'Latitude', 'Longitude', 'Verified', 'FailedCommunication', 'PhonePhoneTypeID', 'PhoneVerifeid', 'PhoneFailedCommunication', 'EmailVerified', 'EmailFailedCommunciation', 'ExternalAccounts', 'DirectAccess',
                  'CanProvisionSMSNum', 'ProvisionSMSNumber', 'ProvisionSMSNumberPrefix', 'ParentName', 'OrganizationtypeRank', 'OrganizationCategoryEnum', 'HasIndependentChildren', 'PathwayDescriptionLIst', 'BondAmount_N', 'CharterLocation', 'CharterStateProvinceID', 'ByLawApprovalDate', 'NextElectionDate', 'MeetingDetails', 'StatusReason', 'StatusEffectiveDate', 'PrimaryTradeID', 'ClassificationID', 'ClassificationDescription', 'CanEditName', 'AllowWaitListOption', 'BusinessName',
                  'WebsiteUri', 'HomeOrganizationID', 'HomeOrganizationName', 'HomeOrganizationAbbreviation', 'EmployerTypeID', 'EmployerType', 'EnterpriseTypeID', 'EnterpriseType', 'MinorityStatusID', 'MinorityStatus', 'Subdivision', 'Subsidiary', 'Language9a', 'PensionParticipating', 'JoinDate', 'DoNotSendJournal', 'Identifier', 'OrganizationReferenceID', 'StatusID', 'TypeID', 'RegisteredCount_N', 'WorkSiteCount_N', 'IsEmployer', 'IsReferenced', 'Address4', 'Address5', 'Address6',
                  'StateProvinceID', 'CountryID', 'PhoneVerified', 'EmailedFailedCommunication', 'UTStatusID', 'UTStatusDescription', 'SortString', 'Level', 'OrganizationType', 'CanProvisionSMSNumber', 'SMSNumber', 'OrganizationTypeRank', 'organizationCategoryEnum', 'UsingDuesCollection', 'ConfigurationList', 'PathwayDescriptionList', 'PrimaryTrade']);
                exportData('employers', dataToExport);
                this.isExporting = false;
              }
            });
        }
      }

    }
  }

  downloadFile(buffer, fileName, fileType) {
    import('file-saver').then(() => {
      const data: Blob = new Blob([buffer], {
        type: fileType
      });
      FileSaver.saveAs(data, fileName);
    });
  }

  saveCurrentDist() {
    const ref = this.dialogService.open(AuxiliaryListNewEditDialogComponent, {
      data: {
        filter: this.currentFilter,
        contextEnumerator: this.ContextEnumerator
      },
      header: 'Save as Distribution List',
      width: '60%',
      height: '250px'
    });
  }

  isStatusInActive(status): any {
    return this.inactiveStatuses.indexOf(status) > -1;
  }

  saveColumns() {
    this.interfaceConfigService.getUserInterfaceConfigList().pipe(take(1)).subscribe({
      next: (res) => {
        this.userPreferences = res;
        switch (this.routeContext) {
          case 'membership':
            if (this.isRosterTable) {
              this.userPreferences.push({Key: 'AuxColumns_Roster', Value: JSON.stringify(this._selectedColumns)});
            } else {
              this.userPreferences.push({Key: 'AuxColumns_Organization', Value: JSON.stringify(this._selectedColumns)});
            }
            break;
          case 'training':
            this.userPreferences.push({Key: 'AuxColumns_StudentRoster', Value: JSON.stringify(this._selectedColumns)});
            break;
          case 'organize':
            this.userPreferences.push({Key: 'AuxColumns_Prospects', Value: JSON.stringify(this._selectedColumns)});
            break;
        }

        if (this.currentPage === 'projectReports') {
          this.userPreferences.push({Key: 'AuxColumns_ProjectReports', Value: JSON.stringify(this._selectedColumns)});
        }

        this.interfaceConfigService.updateUserInterfaceAll(this.userPreferences).pipe(take(1)).subscribe({
          next: () => {
            const context = this.contextService.contextObject;
            context.UserPreferences = res;
            this.contextService.contextObject = context;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Settings have been updated successfully'
            });
          }
        });
      }
    });
  }

  // getAvatarClass(img: boolean, status: string): any {
  //   let str: string = '';
  //   if (img === false) {
  //     str = '-no-image';
  //   }
  //   switch (true) {
  //     case status === 'Deceased':
  //       return 'deceased-status' + str;
  //     case status === 'Suspended':
  //       return 'suspended-status' + str;
  //     case this.inactiveStatuses.indexOf(status) > -1:
  //       return 'inactive-status' + str;
  //     case this.inactiveStatuses.indexOf(status) === -1:
  //       return 'active-status' + str;
  //   }
  // }
}
