import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FreshdeskTicketCreateModel} from '../../../models/freshdesk-ticket.model';
import {Platform} from '@angular/cdk/platform';
import {Router} from '@angular/router';
import {ContextService} from '../../../services/context.service';
import {FreshdeskService} from '../../../services/freshdesk.service';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';

@Component({
  templateUrl: './contact-support-dialog.component.html',
  styleUrls: ['./contact-support-dialog.component.scss']
})

export class ContactSupportDialogComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  isSaving:boolean;
  browser: string;
  version: string;
  freshDeskData = {} as FreshdeskTicketCreateModel;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private formBuilder: FormBuilder,
              private platform: Platform, private router: Router, private contextService: ContextService,
              private freshdeskService: FreshdeskService, private messageService: MessageService) {
    this.mainForm = this.formBuilder.group({
      name: new FormControl(null),
      requester_id: new FormControl(null),
      email:  new FormControl(null, [Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      phone: new FormControl(null),
      subject: new FormControl(null),
      type: new FormControl(null),
      status: new FormControl(null),
      priority: new FormControl(null),
      description: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.browser = this.getBrowserName();
    this.version = this.getBrowserVersion();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getBrowserName() {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('Chrome') > -1) {
      return 'Chrome';
    } else if (userAgent.indexOf('Firefox') > -1) {
      return 'Firefox';
    } else if (userAgent.indexOf('Safari') > -1) {
      return 'Safari';
    } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
      return 'Internet Explorer';
    } else if (userAgent.indexOf('Edge') > -1) {
      return 'Microsoft Edge';
    } else {
      return 'Unknown Browser';
    }
  }

  getBrowserVersion() {
    const userAgent = navigator.userAgent;
    const regex = /(?:(?:MSIE|Trident\/[0-9.]+).*rv:([0-9.]+))|(?:Chrome\/([0-9.]+))|(?:Firefox\/([0-9.]+))|(?:Safari\/([0-9.]+))/;
    const match = userAgent.match(regex);

    if (match) {
      return match[1] || match[2] || match[3] || match[4];
    } else {
      return 'Unknown Version';
    }
  }

  cancel() {

  }

  processData() {
    this.freshDeskData.custom_fields = [];
    this.freshDeskData.custom_fields  = {
      cf_operating_system: navigator.userAgent.toLowerCase(),
      cf_browser: this.getBrowserName(),
      cf_browser_version: this.getBrowserVersion(),
      cf_system_url: window.location.href,
      cf_tenant_name: this.contextService.contextObject.tenantName,
      cf_user_role: 'N/A',
      cf_package_level: 'N/A',
    };
    this.freshDeskData.name = this.mainForm.get('name').value;
    this.freshDeskData.email = this.mainForm.get('email').value;
    this.freshDeskData.phone = this.mainForm.get('phone').value;
    this.freshDeskData.subject = this.mainForm.get('subject').value;
    this.freshDeskData.description = this.mainForm.get('description').value;
    this.freshDeskData.status = 2;
    this.freshDeskData.priority = 1;
    this.freshDeskData.source = 9;
    this.saveData();
  }

  saveData() {
    this.freshdeskService.createTicket(this.freshDeskData).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
        this.mainForm.markAsPristine();
        this.isSaving = false;
      }, error: (e) => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
        this.isSaving = false;
        console.debug(e);
      }
    });
  }

  closeDialog(res?) {
    this.ref.close(res);
    this.ref.destroy();
  }
}
