import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DistributionListDefinitions} from '../../../definitions/distribution-list.definitions';
import {ColumnsModel} from '../../../../models/columns.model';
import {AttributeSelectModel, AuxiliaryFilterModel, RosterQueryExistingModel, RosterQueryFilterModel} from '../../../../models/roster-query-primeng.model';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {DatePipe} from '@angular/common';
import {AttributesService} from '../../../../services/attributes.service';
import {CommunicationsService} from '../../../../services/communications.service';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {DistListLookupService} from '../../../../services/dist-list-lookups.service';
import {DistListLookupsModel} from '../../../../models/dist-list-lookups.model';
import {SurveysService} from '../../../../services/surveys.service';

@Component({
  selector: 'app-auxiliary-filter-form',
  templateUrl: './auxiliary-filter-form.component.html',
  styleUrls: ['./auxiliary-filter-form.component.scss']
})

export class AuxiliaryFilterFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  @Input() isEdit: boolean;
  @Input() canEdit: boolean = true;
  primaryTable: string;
  ContextEnumerator: string;
  isAdmin: boolean;
  tables: any[];
  columns: any[] = [];
  matchType: any[];
  currentRosterQuery = {} as RosterQueryExistingModel;
  selectedColumnField: string;
  selectedColumnLabel: string;
  selectedColumnID: number;
  selectedAttributeId: number;
  surveys: any[] = [];
  @Input() organizationId: number;
  @Input() isRosterTable: boolean = true;
  @Input() description: string;
  @Input() selectedRosterQuery = {} as RosterQueryExistingModel;
  @Output() updateFilter = new EventEmitter<number>();
  @Output() deleteFilterFromParent = new EventEmitter<any>();
  @Output() updateLiveFilter = new EventEmitter<any>();
  @Input() selectedFilter = {} as RosterQueryFilterModel;
  @Input() selectedTable: string;
  @Input() isNewDist: boolean;
  @Input() isNewFilter: boolean;
  @Input() selectedColumn: string;
  @Input() isGridPage;
  @Input() currentPage: string;
  isPicker: boolean = false;
  genericInputObj: GenericSelectModel;
  selectedGenericItems: any[] = [];
  genericSelectObj: GenericSelectModel;
  selectedGenericItem;
  booleanInputObj: GenericSelectModel;
  setBooleanValue: boolean;
  yesNoInputObj: GenericSelectModel;
  setYesNoValue: boolean;
  InterfaceObjectEnum = 'DISTRIBUTIONLIST';
  category: string;
  isDate: boolean;
  startDate: Date;
  isBoolean: boolean;
  isYesNo: boolean;
  isctYesNo: boolean;
  isSelect: boolean;
  valueDescription: string;
  public attributesOptions: AttributeSelectModel [] = [];
  setAttributeID: number;
  currentFilter = {} as PrimeTableFilterModel;
  distListParams = {} as DistListLookupsModel;
  selectedIsID: boolean;
  columnsLabel: string = 'Columns';
  selectedColumnEnumType: string;
  selectedSurveyItem;
  selectedSurveyLabel: string;
  selectedSurveyID: number;
  selectedSurveyDescription: string;
  selectedQuestionDescription: string;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private router: Router, private distributionListDefinitions: DistributionListDefinitions,
              private datePipe: DatePipe, private attributesService: AttributesService, private surveysService: SurveysService,
              private communicationsService: CommunicationsService, private distListLookupService: DistListLookupService) {
    this.mainForm = this.formBuilder.group({
      table: new FormControl(false, Validators.required),
      survey: new FormControl(null),
      column: new FormControl(null, Validators.required),
      matchType: new FormControl(null, Validators.required),
      value: new FormControl(null, Validators.required),
      startValue: new FormControl(null),
      endValue: new FormControl(null),
    });
  }

  ngOnInit(): void {
    //set context
    if (this.router.url.includes('membership')) {
      this.tables = this.distributionListDefinitions.memberTableOptions;
      this.ContextEnumerator = 'MEMBERSHIP';
      this.category = 'union';
      this.columns = this.distributionListDefinitions.rosterCols;
    }
    if (this.router.url.includes('training')) {
      this.tables = this.distributionListDefinitions.studentTableOptions;
      this.ContextEnumerator = 'TRAINING';
      this.category = 'education';
    }
    if (this.router.url.includes('organize')) {
      if (this.currentPage !== 'projectReports') {
        this.tables = this.distributionListDefinitions.organizeTableOptions;
        this.columns = this.distributionListDefinitions.prospectCols;
      } else {
        this.tables = this.distributionListDefinitions.projectReportsTableOptions;
      }

      this.ContextEnumerator = 'ORGANIZE';
      this.category = 'organize';
    }
    if (this.router.url.includes('organizations')) {
      this.tables = this.distributionListDefinitions.organizationTableOptions;
      this.columns = this.distributionListDefinitions.organizationCol;
    }
    if (this.router.url.includes('employers')) {
      this.tables = this.distributionListDefinitions.employerTableOptions;
      this.columns = this.distributionListDefinitions.employersCols;
    }
    this.matchType = this.distributionListDefinitions.matchTextTypes;
    if (this.tables && this.isNewFilter) {
      this.mainForm.get('table').setValue(this.tables[0].value);
      this.getColumns();
    }

    //Separate primary and Auxiliary filter
    if (this.router.url.includes('/roster/')) {
      this.primaryTable = 'ROSTER';
    }
    if (this.router.url.includes('/students/')) {
      this.primaryTable = 'STUDENT';
    }
    if (this.router.url.includes('/prospects/')) {
      this.primaryTable = 'PROSPECTS';
    }

    //if table changes go get new columns for drop down
    // this.mainForm.get('table').valueChanges.subscribe({next:() => {
    //   this.getColumns();
    // }});

    this.currentFilter.rows = null;
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnChanges(changes: SimpleChanges) {
    //pull in parent org and description for saving purposes of new query
    if (this.isNewFilter === true) {
      this.selectedRosterQuery.Description = this.description;
      this.selectedRosterQuery.OrganizationID = this.organizationId;
    }
    if (changes.selectedRosterQuery) {
      this.currentRosterQuery = this.selectedRosterQuery;
    }
  }

  getCurrentRosterQuery() {

  }

  //Based on table selection get column list
  getColumns() {
     console.log(this.mainForm.get('table').value);
    this.columnsLabel = 'Columns';

    switch (this.mainForm.get('table').value) {
      case 'ROSTER' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.rosterCols));
        break;
      }
      case 'UNIONPOSITION' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.positionCols));
        break;
      }
      case 'EMPLOYMENT' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.employmentCols));
        break;
      }
      case 'EMPLOYER' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.employersCols));
        break;
      }
      case 'VOTER' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.voterCols));
        break;
      }
      case 'STUDENTCLASS' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.classCols));
        break;
      }
      case 'STUDENTPROGRAM' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.programCols));
        break;
      }
      case 'STUDENT' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.rosterStudentCols));
        break;
      }
      case 'SURVEYRESPONSE' : {
        this.columnsLabel = 'Question (Optional)';
        this.mainForm.get('column').clearValidators();
        this.mainForm.get('matchType').clearValidators();
        this.mainForm.get('value').clearValidators();
        this.loadSurveys().then(x => {
          if (x && x[0]?.id) {
            this.mainForm.get('survey').setValue(x[0].id);
            this.getSurveySelectData(x[0].id);
          } else {
            this.mainForm.get('survey').setValue(null);
            this.getSurveySelectData(null);
          }
        });
        break;
      }
      case 'EDUCATIONPOSITION' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.positionCols));
        // this.category = 'education';
        break;
      }
      case 'PROJECT' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.projectReportsCols));
        break;
      }
      case 'PROJECTEMPLOYER' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.organizationEmployerCols));
        break;
      }
      case 'COMMUNICATIONSTATUS' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.communicationCols));
        break;
      }
      case 'CERTIFICATION' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.certificationCols));
        break;
      }
      case 'ATTRIBUTE' :
      case 'STUDENTATTRIBUTE' : {
        if (this.mainForm.get('table').value === 'STUDENTATTRIBUTE') {
          this.category = 'education';
        } else if (this.mainForm.get('table').value === 'ATTRIBUTE') {
          this.category = 'union';
        }
        this.attributesService.getRosterAvailableAttributesKGrid(this.category)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              const attributes: any[] = res;
              this.columns.length = 0;
              this.attributesOptions = [];
              attributes.forEach(attribute => {
                if (attribute.DataTypeEnumerator === 'PICKLIST') {
                  this.attributesOptions.push({header: attribute.Description, field: attribute.Description, id: attribute.CustomFieldID, columnType: attribute.DataTypeEnumerator});
                } else if (attribute.DataTypeEnumerator === 'YESNO') {
                  this.attributesOptions.push({header: attribute.Description, field: attribute.Description, columnType: attribute.DataTypeEnumerator});
                } else {
                  this.attributesOptions.push({header: attribute.Description, field: attribute.Description, columnType: attribute.FilterDataTypeEnumerator});
                }
              });
              this.columns = JSON.parse(JSON.stringify(this.attributesOptions));
              this.mainForm.get('column').setValue(this.columns[0].field);
              this.getMatchTypes();
            }
          });
        break;
      }
      case 'ORGATTRIBUTE' : {
        this.attributesService.getOrgAvailableAttributes('union', this.router.url.includes('employers') ? 'employer' : null)
          .pipe(take(1))
          .subscribe(res => {
            const attributes: any[] = res;
            this.columns.length = 0;
            this.attributesOptions = [];
            attributes.forEach(attribute => {
              if (attribute.DataTypeEnumerator === 'PICKLIST') {
                this.attributesOptions.push({header: attribute.Description, field: attribute.Description, id: attribute.CustomFieldID, columnType: attribute.DataTypeEnumerator});
              } else if (attribute.DataTypeEnumerator === 'YESNO') {
                this.attributesOptions.push({header: attribute.Description, field: attribute.Description, columnType: attribute.DataTypeEnumerator});
              } else {
                this.attributesOptions.push({header: attribute.Description, field: attribute.Description, columnType: attribute.FilterDataTypeEnumerator});
              }
            });
            console.log(this.attributesOptions);
            this.columns = JSON.parse(JSON.stringify(this.attributesOptions));
            this.mainForm.get('column').setValue(this.columns[0].field);
            this.getMatchTypes();
          });
        break;
      }
    }
    // if(this.isNewFilter) {
    //   this.mainForm.get('column').setValue(this.columns[0].field);
    // }
    this.mainForm.get('column').setValue(this.columns[0].field);

    if (this.mainForm.get('table').value === 'COMMUNICATIONSTATUS' && !this.isNewDist) {
      this.columns = this.columns.filter(x => x.field === 'SendpublishStatusDescription');
      this.mainForm.get('column').setValue('SendpublishStatusDescription');
      this.getMatchTypes();
    }
    if (this.mainForm.get('table').value !== 'ATTRIBUTE' && this.mainForm.get('table').value !== 'STUDENTATTRIBUTE' && this.mainForm.get('table').value !== 'ORGATTRIBUTE') {
      // console.log('in here');
      this.getMatchTypes();
    }
  }

  //load match types depending on what columntype is selected
  getMatchTypes(event?) {
    if (!event && this.columns) {
      this.selectedColumnField = this.columns[0].field;
      this.selectedColumnID = (this.columns[0]?.id) ? this.columns[0]?.id : null;
      if (this.selectedSurveyID === null) {
        this.mainForm.get('value').setValidators(Validators.required);
      } else {
        this.mainForm.get('value').clearValidators();
      }
    } else if (event === undefined) {
      this.mainForm.get('value').clearValidators();
    } else {
      this.selectedColumnField = event.value;
      if (this.mainForm.get('survey').value) {
        this.selectedColumnID = (event?.value) ? event?.value : null;
        this.mainForm.get('column').setValue(event.value);
      } else {
        this.selectedColumnID = (event?.id) ? event?.id : null;
      }

      if (this.selectedColumnField !== null) {
        this.mainForm.get('value').setValidators(Validators.required);
      } else {
        this.mainForm.get('value').clearValidators();
      }
    }
    this.mainForm.get('value').updateValueAndValidity();
    const selectedColumn: ColumnsModel = this.columns.filter(x => x.field === this.selectedColumnField)[0];
    this.selectedColumnLabel = (this.mainForm.get('table').value === 'SURVEYRESPONSE') ? 'Response' : selectedColumn.header;
    this.selectedQuestionDescription = selectedColumn.header;
    this.selectedIsID = selectedColumn.isID;
    switch (selectedColumn.columnType) {
      case 'text' :
      case 'STRING' : {
        this.matchType = this.distributionListDefinitions.matchTextTypes;
        this.isPicker = false;
        this.isSelect = false;
        this.isDate = false;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        //give the dropdown time to load before selecting first value
        setTimeout(() => {
          this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchTextTypes[0].value);
        }, 500);
        break;
      }
      case 'Range' :
      case 'number' :
      case 'numeric' :
      case 'NUMBER' : {
        this.matchType = this.distributionListDefinitions.matchNumberTypes;
        this.isPicker = false;
        this.isSelect = false;
        this.isDate = false;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        //give the dropdown time to load before selecting first value
        setTimeout(() => {
          this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchNumberTypes[0].value);
        }, 500);
        break;
      }
      case 'date' :
      case 'DATETIME' : {
        this.matchType = this.distributionListDefinitions.matchDateTypes;
        this.isPicker = false;
        this.isSelect = false;
        this.isDate = true;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchDateTypes[0].value);
        setTimeout(() => {
          this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchDateTypes[0].value);
        }, 500);
        break;
      }
      case 'select' : {
        this.matchType = this.distributionListDefinitions.matchTextTypes;
        this.isPicker = false;
        this.isDate = false;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.isSelect = true;

        this.mainForm.get('matchType').setValue('equals');
        this.initGenericSelect(false);
        break;
      }
      case 'picker' :
      case 'Custom Pick List' :
      case 'PICKLIST' : {
        this.selectedGenericItems = [];
        if (this.mainForm.get('table').value === 'ATTRIBUTE' || this.mainForm.get('table').value === 'STUDENTATTRIBUTE' || this.mainForm.get('table').value === 'ORGATTRIBUTE') {
          this.setAttributeID = this.attributesOptions.find(x => x.field === event.value)?.id;
        }
        this.isPicker = true;
        this.matchType = this.distributionListDefinitions.matchPickerTypes;
        this.isSelect = false;
        this.isDate = false;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchTextTypes[0].value);
        this.initGenericMulti(false);
        break;
      }
      case 'boolean' : {
        this.isPicker = false;
        this.isSelect = false;
        this.isDate = false;
        this.isBoolean = true;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.initBoolean(false);
        this.mainForm.get('matchType').setValue('equals');
        break;
      }

      case 'Yes/No' :
      case 'YESNO' : {
        this.isYesNo = true;
        this.isPicker = false;
        this.isSelect = false;
        this.isDate = false;
        this.isBoolean = false;
        this.isctYesNo = false;
        this.initYesNo(false);
        this.mainForm.get('matchType').setValue('equals');
        break;
      }

      case 'ctYESNO' : {
        this.isctYesNo = true;
        this.isYesNo = false;
        this.isPicker = false;
        this.isSelect = false;
        this.isDate = false;
        this.isBoolean = false;
        this.initYesNo(false);
        this.mainForm.get('matchType').setValue('equals');
        break;
      }

      default : {
        this.matchType = this.distributionListDefinitions.matchTextTypes;
        this.isPicker = false;
        break;
      }
    }
    if (this.mainForm.get('table').value === 'ATTRIBUTE' || this.mainForm.get('table').value === 'STUDENTATTRIBUTE' || this.mainForm.get('table').value === 'ORGATTRIBUTE') {
      this.selectedColumnEnumType = this.attributesOptions.find(x => x.field === this.selectedColumnField)?.columnType;
    } else {
      this.selectedColumnEnumType = null;
    }

    //set default match type on load

    this.mainForm.get('matchType').setValue(this.selectedFilter.matchMode);
    if (this.isDate || this.isPicker || this.isBoolean) {
      this.mainForm.get('value').setValue(null);
    }
    this.mainForm.get('startValue').setValue(null);
    this.mainForm.get('endValue').setValue(null);
  }

  setValueValidators(event: any) {
    if (event.value === 'between' || event.value === 'daysfrompresentbetween' || event.value === 'monthsfrompresentbetween') {
      this.mainForm.get('value').clearValidators();
      this.mainForm.get('startValue').setValidators(Validators.required);
      this.mainForm.get('endValue').setValidators(Validators.required);
    } else {
      this.mainForm.get('value').setValidators(Validators.required);
      this.mainForm.get('startValue').clearValidators();
      this.mainForm.get('endValue').clearValidators();
    }
    if (event.value === 'isnullorempty' || event.value === 'isnotnullorempty') {
      this.mainForm.get('value').clearValidators();
    } else if (event.value !== 'between' && event.value !== 'daysfrompresentbetween' && event.value !== 'monthsfrompresentbetween') {
      this.mainForm.get('value').setValidators(Validators.required);
    }

    this.mainForm.controls.startValue.updateValueAndValidity();
    this.mainForm.controls.endValue.updateValueAndValidity();
    this.mainForm.controls.value.updateValueAndValidity();
  }

  initGenericMulti(disable: boolean): void {
    // we load the full object into the selection array for multi here. We have to decide to use id or description for filtering
    this.distListParams.columnName = this.selectedColumnLabel;
    this.distListParams.tableName = this.mainForm.get('table').value;
    this.distListParams.interfaceObjectEnum = this.InterfaceObjectEnum;
    this.distListParams.contextEnumerator = this.ContextEnumerator;
    this.distListParams.currentFilter = this.currentFilter;
    this.distListParams.organizationID = this.organizationId;
    this.distListParams.category = this.category;
    this.distListParams.setAttributeID = this.setAttributeID;

    if (this.selectedColumnLabel) {
      if (this.mainForm.get('table').value === 'ATTRIBUTE' || this.mainForm.get('table').value === 'STUDENTATTRIBUTE' || this.mainForm.get('table').value === 'ORGATTRIBUTE') {
        this.attributesService.getAttribute(this.setAttributeID)
          .pipe(take(1))
          .subscribe({
            next: (result) => {
              this.genericInputObj.data = result.Options;
              this.genericInputObj = Object.assign({}, this.genericInputObj);
            }
          });

      } else {
        this.distListLookupService.getDistLookups(this.distListParams).then(res => {
          this.genericInputObj.data = res;
          this.genericInputObj = Object.assign({}, this.genericInputObj);
          if (this.isNewFilter && !this.isNewDist) {

            if (this.currentRosterQuery.Filters && this.currentRosterQuery.Filters.find(x => x.Enumerator === this.mainForm.get('table').value)) {
              const currentTableFilter = this.currentRosterQuery.Filters.find(x => x.Enumerator === this.mainForm.get('table').value);
              if (currentTableFilter.FilterPrimeNG.filters) {
                // When we filter on ID and not Description
                if (this.selectedIsID && this.selectedColumnField !== 'OrganizationName') {
                  currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value].forEach(x => {
                    const selectedObject = this.genericInputObj.data.find(item => item.ID === x.value);
                    this.selectedGenericItems.push(selectedObject);
                  });
                  // Exception for filtering on org id ancestor
                } else if (this.selectedIsID && this.selectedColumnField === 'OrganizationName' && this.mainForm.get('table').value !== 'PROJECTEMPLOYER') {
                  currentTableFilter.FilterPrimeNG.filters.MembershipAncestorOrganizationID.forEach(x => {
                    const selectedObject = this.genericInputObj.data.find(item => item.ID === x.value);
                    this.selectedGenericItems.push(selectedObject);
                  });
                  // filtering on bargaining unit
                } else if (this.selectedIsID && this.selectedColumnField === 'BargainingUnitDescription') {
                  delete currentTableFilter.FilterPrimeNG.filters.BargainingUnitDescription;
                  currentTableFilter.FilterPrimeNG.filters.BargainingUnitID = [];
                  currentTableFilter.FilterPrimeNG.filters.BargainingUnitID.forEach(x => {
                    const selectedObject = this.genericInputObj.data.find(item => item.ID === x.value);
                    this.selectedGenericItems.push(selectedObject);
                  });
                  // filtering on description
                } else if (currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value]) {
                  currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value].forEach(x => {
                    const selectedObject = this.genericInputObj.data.find(item => item.Description === x.ValueDescription);
                    this.selectedGenericItems.push(selectedObject);
                  });
                }
              }
            }
          }
        });
      }
    }

    this.genericInputObj = {
      labelText: this.selectedColumnLabel,
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.selectedGenericItems,
      disabled: disable
    };

  }

  loadSurveys() {
    return new Promise((resolve, reject) => {
      this.surveysService.getSurveysPrimeNG('DATA', this.organizationId, this.ContextEnumerator, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (res.Data.length > 0) {
              res.Data.forEach(x => {
                if (!x.CanEdit) {
                  this.surveys.push({
                    id: x.SurveyID,
                    field: x.Description,
                    header: x.Description,
                    columnType: 'select'
                  });
                }
              });
              resolve(this.surveys);
            } else {
              this.surveys.push({
                id: null,
                field: '--',
                header: '--',
                columnType: 'select'
              });
              resolve(this.surveys);
            }
          }
        });
    });
  }

  getSurveySelectData(event: any): void {
    console.log('in here');
    this.columns.length = 0;
    if (event && event.value) {
      this.selectedSurveyItem = (event.value !== null) ? event.value : 0;
    } else if (event) {
      this.selectedSurveyItem = (event !== null) ? event : 0;
    } else {
      this.selectedSurveyItem = null;
    }
    if (this.selectedSurveyItem) {
      this.selectedSurveyID = this.selectedSurveyItem;
      this.mainForm.markAsDirty();
      this.mainForm.get('survey').setValue(this.selectedSurveyItem);
      this.surveysService.getSurvey(this.selectedSurveyItem)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.selectedSurveyDescription = res.Description;
            this.columns.push({
              id: null,
              field: null,
              header: '--',
              columnType: null
            });
            res.Questions.forEach(x => {
              this.columns.push({
                id: x.ID,
                field: x.ID,
                header: x.Description,
                columnType: x.DataTypeDescription
              });
            });
            this.mainForm.get('column').setValue(null);
            this.getMatchTypes();
          }
        });
    } else {
      this.selectedColumnField = null;
    }
  }


  getGenericMultiData(event: any): void {
    if (event) {
      this.selectedGenericItems = event;
    } else {
      this.selectedGenericItems = null;
    }
    if (this.selectedGenericItems && this.selectedGenericItems.length > 0) {
      this.mainForm.markAsDirty();
      this.mainForm.get('value').setValue(this.selectedGenericItems);
    }
  }

  initGenericSelect(disable: boolean): void {
    this.genericSelectObj = {
      labelText: this.selectedColumnLabel,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: (this.selectedSurveyID !== null),
      selectFirstValue: false,
      initSelected: this.selectedGenericItem,
      disabled: disable,
      emitChangeOnLoad: true
    };
    switch (this.selectedColumnLabel) {
      case 'Communication' : {
        this.communicationsService.getCommunicationsPrimeNG('DATA', this.ContextEnumerator, this.currentFilter, this.organizationId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.genericSelectObj.data = [];
              res.Data.forEach(x => {
                this.genericSelectObj.data.push({Description: x.Title, ID: x.ID});
              });
              //this.genericInputObj.data = res;
              this.genericSelectObj = Object.assign({}, this.genericSelectObj);
            }
          });
        break;
      }
      default : {
        this.genericSelectObj.data = [];
        if (this.columns.filter(x => x.field === this.selectedColumnField)[0]?.id) {
          this.surveysService.getSurvey(this.columns.filter(x => x.field === this.selectedColumnField)[0]?.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.genericSelectObj.data = [];
                res.Questions.forEach(x => {
                  this.genericSelectObj.data.push({Description: x.Description, ID: x.ID});
                });
                //this.genericInputObj.data = res;
                this.genericSelectObj = Object.assign({}, this.genericSelectObj);
              }
            });
        }
        break;
      }
    }
  }

  getGenericSelectData(event: any): void {
    if (event && event[0]) {
      this.selectedGenericItem = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.selectedGenericItem = null;
    }
    if (this.selectedGenericItem) {
      this.mainForm.markAsDirty();
      this.mainForm.get('matchType').setValue('equals');
      this.mainForm.get('value').setValue(this.selectedGenericItem);
      this.valueDescription = event[0].Description;
    }
  }

  initBoolean(disable: boolean): void {
    this.booleanInputObj = {
      labelText: this.selectedColumnLabel,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable
    };
    this.booleanInputObj.data = this.distributionListDefinitions.booleanValues;
  }

  getBooleanData(event: any): void {
    this.setBooleanValue = event[0].Description === 'Yes';
    this.mainForm.get('value').setValue(this.setBooleanValue);
  }

  initYesNo(disable: boolean): void {
    this.yesNoInputObj = {
      labelText: this.selectedColumnLabel,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: (this.selectedSurveyID !== null),
      selectFirstValue: false,
      initSelected: null,
      disabled: disable
    };
    this.yesNoInputObj.data = this.distributionListDefinitions.yesNoValues;
  }

  getYesNoData(event: any): void {
    // console.log(event[0].ID);
    if (this.mainForm.get('survey').value) {
      this.setYesNoValue = event[0].Description;
    } else {
      this.setYesNoValue = event[0].Description === 'Yes';
    }

    if (event[0].ID === 'isEmpty') {
      this.mainForm.get('matchType').setValue('isnullorempty');
    } else {
      this.mainForm.get('matchType').setValue('equals');
    }
    this.mainForm.get('value').setValue(this.setYesNoValue);
    this.valueDescription = event[0].Description;
  }

  getctYesNoData(event: any): void {
    this.mainForm.get('matchType').setValue('equals');
    this.mainForm.get('value').setValue(event[0].Description);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  processData() {
    //format date for saving
    let formattedValue: string = this.mainForm.get('value').value;
    let formattedStartValue: string = this.mainForm.get('startValue').value;
    let formattedEndValue: string = this.mainForm.get('endValue').value;

    if (this.isDate) {
      if (this.mainForm.get('matchType').value === 'between') {
        formattedStartValue = this.datePipe.transform(this.mainForm.get('startValue').value, 'yyyy-MM-dd');
        formattedEndValue = this.datePipe.transform(this.mainForm.get('endValue').value, 'yyyy-MM-dd');
      } else if (this.mainForm.get('matchType').value === 'daysfrompresentbetween' || this.mainForm.get('matchType').value === 'monthsfrompresentbetween') {
        formattedStartValue = this.mainForm.get('startValue').value;
        formattedEndValue = this.mainForm.get('endValue').value;
      } else if (this.mainForm.get('matchType').value !== 'daysfrompresent' && this.mainForm.get('matchType').value !== 'monthsfrompresent') {
        formattedValue = this.datePipe.transform(this.mainForm.get('value').value, 'yyyy-MM-dd');
      }
    }
    console.log(this.mainForm.get('column').value);
    let formattedFilters: RosterQueryFilterModel[];
    console.log(this.currentRosterQuery);
    if (this.currentRosterQuery?.Filters?.find(x => x.Enumerator === this.mainForm.get('table').value)) {
      // console.log('in existing table');
      const index = this.currentRosterQuery.Filters.findIndex(x => x.Enumerator === this.mainForm.get('table').value);
      if (this.currentRosterQuery.Filters[index].FilterPrimeNG.filters[this.mainForm.get('column').value]) {
        // console.log('in existing column');
        formattedFilters = this.currentRosterQuery.Filters[index].FilterPrimeNG.filters[this.mainForm.get('column').value];
        // console.log(formattedFilters);
      } else {
        formattedFilters = [];
      }
    } else {
      formattedFilters = [];
    }

    if (this.isPicker) {
      if (this.selectedColumnField === 'OrganizationName' || this.selectedColumnField === 'BargainingUnitDescription') {
        if (this.selectedColumnField === 'OrganizationName' && this.mainForm.get('table').value !== 'PROJECTEMPLOYER') {
          this.selectedColumnField = 'MembershipAncestorOrganizationID';
        }
        if (this.selectedColumnField === 'OrganizationName' && this.mainForm.get('table').value === 'PROJECTEMPLOYER') {
          this.selectedColumnField = 'OrganizationID';
        }
        if (this.selectedColumnField === 'BargainingUnitDescription') {
          this.selectedColumnField = 'BargainingUnitID';
        }
        if (this.mainForm.get('matchType').value === 'equals') {
          // console.log('1');
          this.selectedGenericItems.forEach(arrayItem => {
            arrayItem.Description = arrayItem.Description.replaceAll('-', '');
            formattedFilters.push({
              matchMode: 'equals',
              value: arrayItem.ID,
              operator: 'or',
              HeaderName: this.selectedColumnLabel,
              FilterDataTypeEnumerator: 'NUMBER',
              ValueDescription: arrayItem.Description,
              isID: this.selectedIsID
            });
          });
        } else {
          // console.log('2');
          formattedFilters.push({
            matchMode: this.mainForm.get('matchType').value,
            value: null,
            operator: 'or',
            HeaderName: this.selectedColumnLabel,
            FilterDataTypeEnumerator: 'NUMBER',
            ValueDescription: null,
            isID: this.selectedIsID
          });
        }
      } else if (this.selectedColumnField === 'OrganizationID' && this.mainForm.get('table').value !== 'PROJECTEMPLOYER') {
        if (this.mainForm.get('matchType').value === 'equals') {
          // console.log('2');
          this.selectedGenericItems.forEach(arrayItem => {
            formattedFilters.push({
              matchMode: 'equals',
              value: arrayItem.ID,
              operator: 'or',
              HeaderName: this.selectedColumnLabel,
              FilterDataTypeEnumerator: 'NUMBER',
              ValueDescription: arrayItem.Description,
              isID: this.selectedIsID
            });
          });
        } else {
          // console.log('3');
          formattedFilters.push({
            matchMode: this.mainForm.get('matchType').value,
            value: null,
            operator: 'or',
            HeaderName: this.selectedColumnLabel,
            FilterDataTypeEnumerator: 'NUMBER',
            ValueDescription: null,
            isID: this.selectedIsID
          });
        }
      } else {
        if (this.mainForm.get('matchType').value === 'equals') {
          // console.log('4');
          this.selectedGenericItems.forEach(arrayItem => {
            formattedFilters.push({
              matchMode: this.selectedColumnLabel === 'Organizers' ? 'contains' : 'equals',
              value: arrayItem.Description,
              operator: 'or',
              HeaderName: this.selectedColumnLabel,
              FilterDataTypeEnumerator: 'STRING',
              ValueDescription: arrayItem.Description,
              isID: this.selectedIsID
            });
          });
        } else {
          // console.log('5');
          formattedFilters.push({
            matchMode: this.mainForm.get('matchType').value,
            value: null,
            operator: 'or',
            HeaderName: this.selectedColumnLabel,
            FilterDataTypeEnumerator: 'NUMBER',
            ValueDescription: null,
            isID: this.selectedIsID
          });
        }
      }
    } else if (this.isBoolean) {
      formattedFilters.push({
        matchMode: 'equals',
        value: this.setBooleanValue,
        operator: 'or'
      });
    } else if (this.isYesNo) {
      // console.log('in yes no');
      formattedFilters.push({
        matchMode: this.mainForm.get('matchType').value,
        value: this.setYesNoValue,
        operator: 'or',
        HeaderName: this.selectedColumnLabel,
        FilterDataTypeEnumerator: this.selectedColumnEnumType,
        ValueDescription: this.valueDescription ? this.valueDescription : this.setYesNoValue.toString()
      });
    } else if (this.mainForm.get('matchType').value === 'between' || this.mainForm.get('matchType').value === 'monthsfrompresentbetween' || this.mainForm.get('matchType').value === 'daysfrompresentbetween') {
      formattedFilters.push({
        matchMode: this.mainForm.get('matchType').value,
        value: formattedStartValue,
        endValue: formattedEndValue,
        operator: 'or',
        HeaderName: this.selectedColumnLabel,
        FilterDataTypeEnumerator: this.selectedColumnEnumType,
        ValueDescription: null
      });
    } else {
      // manage surveys
      if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && (this.mainForm.get('column').value === undefined || this.mainForm.get('column').value === null)) {
        // survey only
        formattedFilters.push({
          matchMode: 'equals',
          value: this.selectedSurveyID,
          operator: 'or',
          HeaderName: 'Title',
          FilterDataTypeEnumerator: this.selectedColumnEnumType,
          ValueDescription: this.selectedSurveyDescription
        });
      } else {
        formattedFilters.push({
          matchMode: this.mainForm.get('matchType').value,
          value: formattedValue,
          operator: 'or',
          HeaderName: this.selectedColumnLabel,
          FilterDataTypeEnumerator: this.selectedColumnEnumType,
          ValueDescription: this.valueDescription ? this.valueDescription : formattedValue
        });
      }

    }

    if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && (this.mainForm.get('column').value === undefined || this.mainForm.get('column').value === null)) {
      // survey only
      this.selectedRosterQuery.ContextEnumerator = this.ContextEnumerator;
      this.selectedRosterQuery.Filters = [];
      this.selectedRosterQuery.Filters.push({
        Enumerator: this.mainForm.get('table').value,
        FilterPrimeNG: {
          globalOperator: 'and',
          filters: {
            ['SurveyID']: formattedFilters
          }
        }
      });
    } else if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && this.mainForm.get('column').value !== null) {
      // survey and question
      this.selectedRosterQuery.ContextEnumerator = this.ContextEnumerator;
      this.selectedRosterQuery.Filters = [];
      this.selectedRosterQuery.Filters.push({
        Enumerator: this.mainForm.get('table').value,
        FilterPrimeNG: {
          globalOperator: 'and',
          filters: {
            ['SurveyID']: [{
              matchMode: 'equals',
              value: this.selectedSurveyID,
              operator: 'or',
              HeaderName: 'Title',
              FilterDataTypeEnumerator: this.selectedColumnEnumType,
              ValueDescription: this.selectedSurveyDescription
            }],
            ['QuestionID']: [{
              matchMode: 'equals',
              value: this.selectedColumnID,
              operator: 'or',
              HeaderName: 'Question',
              FilterDataTypeEnumerator: this.selectedColumnEnumType,
              ValueDescription: this.selectedQuestionDescription
            }],
            ['Value']: formattedFilters
          }
        }
      });
    } else {

      //getting a new dist list formatted for post call
      this.selectedRosterQuery.ContextEnumerator = this.ContextEnumerator;
      this.selectedRosterQuery.Description = 'temp';
      this.selectedRosterQuery.OrganizationID = 0;
      this.selectedRosterQuery.Filters = [];
      this.selectedRosterQuery.Filters.push({
        Enumerator: this.mainForm.get('table').value,
        FilterPrimeNG: {
          globalOperator: 'and',
          filters: {
            [this.selectedColumnField]: formattedFilters
          }
        }
      });
    }
    const convertedFilter = {} as AuxiliaryFilterModel;
    convertedFilter.AuxFilters = [];
    this.selectedRosterQuery.Filters.forEach(filter => {
      convertedFilter.AuxFilters.push({
        Enumerator: filter.Enumerator,
        FilterPrimeNG: filter.FilterPrimeNG
      });
    });
    this.mainForm.get('value').setValue(null);
    this.mainForm.get('column').setValue(null);
    this.mainForm.get('survey').setValue(null);
    if (this.router.url.includes('membership')) {
      this.mainForm.get('table').setValue('ROSTER');
    }
    if (this.router.url.includes('training')) {
      this.mainForm.get('table').setValue('STUDENT');
    }
    if (this.router.url.includes('organize')) {
      this.mainForm.get('table').setValue('PROSPECTS');
    }
    if (this.router.url.includes('organizations')) {
      this.mainForm.get('table').setValue('ORGANIZATIONS');
    }
    if (this.router.url.includes('employers')) {
      this.mainForm.get('table').setValue('EMPLOYERS');
    }
     this.surveys.length = 0;
    this.columns.length = 0;
    this.selectedSurveyItem = null;
    this.updateLiveFilter.emit(convertedFilter);
    this.getColumns();
  }

  showMatch() {
    if (this.isNewFilter) {
      return !this.isBoolean && !this.isYesNo && !this.isctYesNo && !this.isSelect;
    } else {
      return true;
    }
  }

  showValue() {
    if (!this.isYesNo && !this.isctYesNo) {
      return this.mainForm.controls.matchType.value !== 'between' && !this.isPicker && !this.isSelect
        && this.mainForm.controls.matchType.value !== 'monthsfrompresentbetween' && this.mainForm.controls.matchType.value !== 'daysfrompresentbetween'
        && !this.isBoolean && this.mainForm.controls.matchType.value !== 'isnullorempty'
        && this.mainForm.controls.matchType.value !== 'isnotnullorempty';
    } else {
      return !this.isNewFilter;
    }
  }
}
