import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {DistributionListDefinitions} from '../../../definitions/distribution-list.definitions';
import {ColumnsModel} from '../../../../models/columns.model';
import {AttributeSelectModel, RosterQueryExistingModel, RosterQueryFilterModel} from '../../../../models/roster-query-primeng.model';
import {RosterQueryService} from '../../../../services/roster-queries.service';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {DatePipe} from '@angular/common';
import {AttributesService} from '../../../../services/attributes.service';
import {CommunicationsService} from '../../../../services/communications.service';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {DistListLookupService} from '../../../../services/dist-list-lookups.service';
import {DistListLookupsModel} from '../../../../models/dist-list-lookups.model';
import {SurveysService} from '../../../../services/surveys.service';


@Component({
  selector: 'app-distribution-list-filter-form',
  templateUrl: './distribution-filter-form.component.html',
  styleUrls: ['./distribution-filter-form.component.scss']
})

export class DistributionFilterFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  @Input() isEdit: boolean;
  @Input() canEdit: boolean = true;
  primaryTable: string;
  ContextEnumerator: string;
  isAdmin: boolean;
  tables: any[];
  columns: any[] = [];
  matchType: any[];
  currentRosterQuery = {} as RosterQueryExistingModel;
  selectedColumnField: string;
  selectedColumnLabel: string;
  selectedColumnEnumType: string;
  selectedColumnID: number;
  ancestorOrgId: [];
  @Input() organizationId: number;
  @Input() description: string;
  @Output() updateFilter = new EventEmitter<number>();
  @Output() updateLiveFilter = new EventEmitter<any>();
  @Input() isNewDist: boolean;
  @Input() isNewFilter: boolean;
  @Input() isGridPage: string;
  @Input() selectedRosterQuery = {} as RosterQueryExistingModel;
  @Input() isDialog: boolean;
  @Input() setTableFromDialog: string;
  @Input() setColumnFromDialog: string;
  @Input() setValueFromDialog: string;
  isPicker: boolean = false;
  genericInputObj: GenericSelectModel;
  selectedGenericItems: any[] = [];
  genericSelectObj: GenericSelectModel;
  selectedGenericItem;
  booleanInputObj: GenericSelectModel;
  setBooleanValue: boolean;
  yesNoInputObj: GenericSelectModel;
  setYesNoValue: boolean;
  InterfaceObjectEnum = 'DISTRIBUTIONLIST';
  category: string = 'union';
  isDate: boolean;
  startDate: Date;
  isBoolean: boolean;
  isYesNo: boolean;
  isctYesNo: boolean;
  isSelect: boolean;
  valueDescription: string;
  public attributesOptions: AttributeSelectModel [] = [];
  setAttributeID: number;
  currentFilter = {} as PrimeTableFilterModel;
  distListParams = {} as DistListLookupsModel;
  selectedIsID: boolean;
  columnsLabel: string = 'Columns';
  surveys: any[];
  responseOptions: any[];
  surveySelectObj: GenericSelectModel;
  selectedSurveyItem;
  selectedSurveyLabel: string;
  selectedSurveyID: number;
  selectedSurveyDescription: string;
  selectedQuestionDescription: string;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private messageService: MessageService,
              private router: Router, private distributionListDefinitions: DistributionListDefinitions,
              private rosterQueryService: RosterQueryService, private datePipe: DatePipe,
              private attributesService: AttributesService, private communicationsService: CommunicationsService,
              private distListLookupService: DistListLookupService, private surveysService: SurveysService) {
    this.mainForm = this.formBuilder.group({
      table: new FormControl(false, Validators.required),
      survey: new FormControl(null),
      column: new FormControl(null, Validators.required),
      matchType: new FormControl(null, Validators.required),
      value: new FormControl(null, Validators.required),
      startValue: new FormControl(null),
      endValue: new FormControl(null),
    });
  }

  ngOnInit(): void {
    //set context
    if (this.router.url.includes('membership')) {
      this.tables = this.distributionListDefinitions.memberTableOptions;
      this.ContextEnumerator = 'MEMBERSHIP';
      this.category = 'union';
    }
    if (this.router.url.includes('training')) {
      this.tables = this.distributionListDefinitions.studentTableOptions;
      this.ContextEnumerator = 'TRAINING';
      this.category = 'education';
    }
    if (this.router.url.includes('organize')) {
      this.tables = this.distributionListDefinitions.organizeTableOptions;
      this.ContextEnumerator = 'ORGANIZE';
      this.category = 'organize';
    }
    this.matchType = this.distributionListDefinitions.matchTextTypes;
    if (this.tables && this.isNewFilter && !this.isDialog) {
      this.mainForm.get('table').setValue(this.tables[0].value);
      this.getColumns();
    }
    //Separate primary and Auxiliary filter
    if (this.router.url.includes('/roster/')) {
      this.primaryTable = 'ROSTER';
    }
    if (this.router.url.includes('/students/')) {
      this.primaryTable = 'STUDENT';
    }
    if (this.router.url.includes('/prospects/')) {
      this.primaryTable = 'PROSPECTS';
    }

    this.currentFilter.rows = null;
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnChanges(changes: SimpleChanges) {
    //pull in parent org and description for saving purposes of new query
    this.selectedRosterQuery.Description = this.description;
    this.selectedRosterQuery.OrganizationID = this.organizationId;

    if (changes.selectedRosterQuery) {
      this.currentRosterQuery = this.selectedRosterQuery;
    }
    //if new list from comm or survey
    if (changes.isDialog || changes.setTableFromDialog || changes.selectedRosterQuery) {
      if (this.isDialog && this.setTableFromDialog && !this.currentRosterQuery.Filters) {
        if (this.router.url.includes('membership')) {
          this.tables = this.distributionListDefinitions.communicationTableOptions;
          this.ContextEnumerator = 'MEMBERSHIP';
          this.category = 'union';
        }
        if (this.router.url.includes('training')) {
          this.tables = this.distributionListDefinitions.communicationTableOptions;
          this.ContextEnumerator = 'TRAINING';
          this.category = 'education';
        }
        if (this.router.url.includes('organize')) {
          this.tables = this.distributionListDefinitions.prospectCols;
          this.ContextEnumerator = 'ORGANIZE';
          this.category = 'organize';
        }
        this.mainForm.get('table').setValue(this.setTableFromDialog);
        this.selectedGenericItem = this.setValueFromDialog;
        this.getColumns();
        this.mainForm.get('column').setValue(this.setColumnFromDialog);
        this.mainForm.get('value').setValue(this.setValueFromDialog);
        this.mainForm.get('matchType').setValue('equals');
        this.mainForm.get('matchType').disable();
        this.mainForm.get('table').disable();
        this.mainForm.get('column').disable();
        this.mainForm.get('value').disable();
      }
      if (this.isDialog && this.setTableFromDialog && this.currentRosterQuery.Filters) {
        this.tables = this.distributionListDefinitions.communicationTableOptions;
        this.mainForm.get('table').setValue(this.setTableFromDialog);
        this.getColumns();
      }
    }
  }

  //Based on table selection get column list
  getColumns() {
    this.selectedGenericItem = null;
    this.valueDescription = null;
    this.selectedSurveyID = null;
    this.selectedSurveyDescription = null;
    this.selectedQuestionDescription = null;
    this.mainForm.get('value').setValue(null);
    this.columnsLabel = 'Columns';
    this.mainForm.get('column').setValidators(Validators.required);
    this.mainForm.get('matchType').setValidators(Validators.required);
    this.mainForm.get('value').setValidators(Validators.required);
    this.surveys = [];
    switch (this.mainForm.get('table').value) {
      case 'ROSTER' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.rosterCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        break;
      }
      case 'UNIONPOSITION' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.positionCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        break;
      }
      case 'VOTER' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.voterCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        break;
      }
      case 'STUDENTCLASS' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.classCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        break;
      }
      case 'STUDENTPROGRAM' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.programCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        break;
      }
      case 'SURVEYRESPONSE' : {
        this.columnsLabel = 'Question (Optional)';
        this.mainForm.get('column').clearValidators();
        this.mainForm.get('matchType').clearValidators();
        this.mainForm.get('value').clearValidators();
        this.loadSurveys().then(x => {
          if (x && x[0]?.id) {
            this.mainForm.get('survey').setValue(x[0].id);
            this.getSurveySelectData(x[0].id);
          } else {
            this.mainForm.get('survey').setValue(null);
            this.getSurveySelectData(null);
          }
        });
        break;
      }
      case 'STUDENT' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.rosterStudentCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        break;
      }
      case 'EMPLOYMENT' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.employmentCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        break;
      }
      case 'EDUCATIONPOSITION' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.positionCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        // this.category = 'education';
        break;
      }
      case 'PROSPECT' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.prospectCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        break;
      }
      case 'COMMUNICATIONSTATUS' : {
        this.columns = this.distributionListDefinitions.communicationCols;
        if (this.currentRosterQuery.Filters && !this.isDialog) {
          if (this.currentRosterQuery.Filters.find(x => x.Enumerator === 'COMMUNICATIONSTATUS')) {
            const commFilter = this.currentRosterQuery.Filters.find(x => x.Enumerator === 'COMMUNICATIONSTATUS');
            if (commFilter.FilterPrimeNG.filters.CommunicationID) {
              this.columns = this.columns.filter(x => x.field !== 'CommunicationID');
              this.mainForm.get('column').setValue(this.columns[0].field);
            }
          }
        } else {
          this.mainForm.get('column').setValue(this.columns[0].field);
        }
        break;
      }
      case 'CERTIFICATION' : {
        this.columns = JSON.parse(JSON.stringify(this.distributionListDefinitions.certificationCols));
        this.mainForm.get('column').setValue(this.columns[0].field);
        break;
      }
      case 'ATTRIBUTE' :
      case 'STUDENTATTRIBUTE' : {
        this.columnsLabel = 'Custom Fields';
        if (this.mainForm.get('table').value === 'STUDENTATTRIBUTE') {
          this.category = 'education';
        } else {
          this.category = 'union';
        }
        this.attributesService.getRosterAvailableAttributesKGrid(this.category)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              const attributes: any[] = res;
              this.columns.length = 0;
              this.attributesOptions = [];
              attributes.forEach(attribute => {
                if (attribute.DataTypeEnumerator === 'PICKLIST') {
                  this.attributesOptions.push({
                    header: attribute.Description,
                    field: attribute.Description,
                    id: attribute.CustomFieldID,
                    columnType: attribute.DataTypeEnumerator
                  });
                } else if (attribute.DataTypeEnumerator === 'YESNO') {
                  this.attributesOptions.push({
                    header: attribute.Description,
                    field: attribute.Description,
                    columnType: attribute.DataTypeEnumerator
                  });
                } else {
                  this.attributesOptions.push({
                    header: attribute.Description,
                    field: attribute.Description,
                    columnType: attribute.FilterDataTypeEnumerator
                  });
                }
              });
              this.columns = JSON.parse(JSON.stringify(this.attributesOptions));
              this.mainForm.get('column').setValue(this.columns[0].field);
              this.getMatchTypes();
            }
          });
        break;
      }
      case 'EMPATTRIBUTE' :
      case 'ORGATTRIBUTE' : {
        // console.log('in here now');
        this.columnsLabel = 'Custom Fields';
        this.attributesService.getOrgAvailableAttributes('union', this.mainForm.get('table').value === 'EMPATTRIBUTE' ? 'employer' : null)
          .pipe(take(1))
          .subscribe(res => {
            const attributes: any[] = res;
            this.columns.length = 0;
            this.attributesOptions = [];
            attributes.forEach(attribute => {
              if (attribute.DataTypeEnumerator === 'PICKLIST') {
                this.attributesOptions.push({
                  header: attribute.Description,
                  field: attribute.Description,
                  id: attribute.CustomFieldID,
                  columnType: attribute.DataTypeEnumerator
                });
              } else if (attribute.DataTypeEnumerator === 'YESNO') {
                this.attributesOptions.push({
                  header: attribute.Description,
                  field: attribute.Description,
                  columnType: attribute.DataTypeEnumerator
                });
              } else {
                this.attributesOptions.push({
                  header: attribute.Description,
                  field: attribute.Description,
                  columnType: attribute.FilterDataTypeEnumerator
                });
              }
            });
            this.columns = JSON.parse(JSON.stringify(this.attributesOptions));
            this.mainForm.get('column').setValue(this.columns[0].field);
            this.getMatchTypes();
          });
        break;
      }
    }
    this.mainForm.get('column').updateValueAndValidity();
    this.mainForm.get('matchType').updateValueAndValidity();
    this.mainForm.get('value').updateValueAndValidity();
    if (this.mainForm.get('table').value === 'COMMUNICATIONSTATUS' && this.isDialog && !this.isNewDist) {
      this.columns = this.columns.filter(x => x.field === 'SendpublishStatusDescription');
      this.mainForm.get('column').setValue('SendpublishStatusDescription');
      this.getMatchTypes();
    }

    if (this.mainForm.get('table').value !== 'ATTRIBUTE' && this.mainForm.get('table').value !== 'STUDENTATTRIBUTE') {
      this.getMatchTypes();
    }
  }

  loadSurveys() {
    return new Promise((resolve, reject) => {
      this.surveysService.getSurveysPrimeNG('DATA', this.organizationId, this.ContextEnumerator, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (res.Data.length > 0) {
              res.Data.forEach(x => {
                if (!x.CanEdit) {
                  this.surveys.push({
                    id: x.SurveyID,
                    field: x.Description,
                    header: x.Description,
                    columnType: 'select'
                  });
                }
              });
              resolve(this.surveys);
            } else {
              this.surveys.push({
                id: null,
                field: '--',
                header: '--',
                columnType: 'select'
              });
              resolve(this.surveys);
            }
          }
        });
    });
  }

  //load match types depending on what columntype is selected
  getMatchTypes(event?) {
    if (!event && this.columns) {
      this.selectedColumnField = this.columns[0].field;
      this.selectedColumnID = (this.columns[0]?.id) ? this.columns[0]?.id : null;
      if (this.selectedSurveyID === null) {
        this.mainForm.get('value').setValidators(Validators.required);
      } else {
        this.mainForm.get('value').clearValidators();
      }
    } else if (event === undefined) {
      this.mainForm.get('value').clearValidators();
    } else {
      this.selectedColumnField = event.value;
      if (this.mainForm.get('survey').value) {
        this.selectedColumnID = (event?.value) ? event?.value : null;
        this.mainForm.get('column').setValue(event.value);
      } else {
        this.selectedColumnID = (event?.id) ? event?.id : null;
      }

      if (this.selectedColumnField !== null) {
        this.mainForm.get('value').setValidators(Validators.required);
      } else {
        this.mainForm.get('value').clearValidators();
      }
    }
    this.mainForm.get('value').updateValueAndValidity();
    const selectedColumn: ColumnsModel = this.columns.filter(x => x.field === this.selectedColumnField)[0];
    this.selectedColumnLabel = (this.mainForm.get('table').value === 'SURVEYRESPONSE') ? 'Response' : selectedColumn.header;
    this.selectedQuestionDescription = selectedColumn.header;
    this.selectedIsID = selectedColumn.isID;
    switch (selectedColumn.columnType) {
      case 'text' :
      case 'STRING' : {
        this.matchType = this.distributionListDefinitions.matchTextTypes;
        this.isPicker = false;
        this.isSelect = false;
        this.isDate = false;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchTextTypes[0].value);
        break;
      }
      case 'Range' :
      case 'number' :
      case 'numeric' :
      case 'NUMBER' : {
        this.matchType = this.distributionListDefinitions.matchNumberTypes;
        this.isPicker = false;
        this.isDate = false;
        this.isSelect = false;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchNumberTypes[0].value);
        break;
      }
      case 'date' :
      case 'DATETIME' : {
        this.matchType = this.distributionListDefinitions.matchDateTypes;
        this.isPicker = false;
        this.isDate = true;
        this.isSelect = false;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchDateTypes[0].value);
        break;
      }
      case 'select' : {
        this.matchType = this.distributionListDefinitions.matchTextTypes;
        this.isPicker = false;
        this.isDate = false;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.isSelect = true;
        this.mainForm.get('matchType').setValue('equals');
        this.initGenericSelect(this.isDialog);
        break;
      }
      case 'picker' :
      case 'Custom Pick List' :
      case 'PICKLIST' : {
        if (this.mainForm.get('table').value === 'ATTRIBUTE' || this.mainForm.get('table').value === 'STUDENTATTRIBUTE') {
          this.setAttributeID = this.attributesOptions.find(x => x.field === event.value).id;
        }
        console.log('in picker');
        this.isPicker = true;
        this.matchType = this.distributionListDefinitions.matchPickerTypes;
        this.isDate = false;
        this.isBoolean = false;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.isSelect = false;
        this.selectedGenericItems = [];
        this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchTextTypes[0].value);
        // if (this.isNewFilter && !this.isNewDist) {
        //   if (this.currentRosterQuery.Filters && this.currentRosterQuery.Filters.find(x => x.Enumerator === this.mainForm.get('table').value)) {
        //     const currentTableFilter = this.currentRosterQuery.Filters.find(x => x.Enumerator === this.mainForm.get('table').value);
        //     if (currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value]) {
        //       currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value].forEach(x => {
        //         this.selectedGenericItems.push(x.value);
        //       });
        //     }
        //   }
        //   this.initGenericMulti(false);
        //   //this.currentRosterQuery.Filters.
        // } else {
        this.initGenericMulti(false);
        // }
        break;
      }
      case 'boolean' : {
        this.isPicker = false;
        this.isDate = false;
        this.isBoolean = true;
        this.isYesNo = false;
        this.isctYesNo = false;
        this.initBoolean(false);
        this.mainForm.get('matchType').setValue('equals');
        break;
      }

      case 'Yes/No' :
      case 'YESNO' : {
        this.isYesNo = true;
        this.isPicker = false;
        this.isDate = false;
        this.isBoolean = false;
        this.isctYesNo = false;
        this.initYesNo(false);
        this.mainForm.get('matchType').setValue('equals');
        break;
      }

      case 'ctYESNO' : {
        this.isctYesNo = true;
        this.isYesNo = false;
        this.isPicker = false;
        this.isDate = false;
        this.isBoolean = false;
        this.initYesNo(false);
        this.mainForm.get('matchType').setValue('equals');
        break;
      }

      default : {
        this.mainForm.get('matchType').setValue(this.distributionListDefinitions.matchTextTypes[0].value);
        this.isPicker = false;
        break;
      }
    }
    if (this.mainForm.get('table').value === 'ATTRIBUTE' || this.mainForm.get('table').value === 'STUDENTATTRIBUTE') {
      this.selectedColumnEnumType = this.attributesOptions.find(x => x.field === this.selectedColumnField).columnType;
    } else {
      this.selectedColumnEnumType = null;
    }

    if (this.isDate || this.isPicker || this.isBoolean) {
      this.mainForm.get('value').setValue(null);
    }
    this.mainForm.get('startValue').setValue(null);
    this.mainForm.get('endValue').setValue(null);

  }

  setValueValidators(event: any) {
    if (event.value === 'between' || event.value === 'daysfrompresentbetween' || event.value === 'monthsfrompresentbetween') {
      this.mainForm.get('value').clearValidators();
      this.mainForm.get('startValue').setValidators(Validators.required);
      this.mainForm.get('endValue').setValidators(Validators.required);
    } else {
      this.mainForm.get('value').setValidators(Validators.required);
      this.mainForm.get('startValue').clearValidators();
      this.mainForm.get('endValue').clearValidators();
    }
    if (event.value === 'isnullorempty' || event.value === 'isnotnullorempty') {
      this.mainForm.get('value').clearValidators();
    } else if (event.value !== 'between' && event.value !== 'daysfrompresentbetween' && event.value !== 'monthsfrompresentbetween') {
      this.mainForm.get('value').setValidators(Validators.required);
    }

    this.mainForm.controls.startValue.updateValueAndValidity();
    this.mainForm.controls.endValue.updateValueAndValidity();
    this.mainForm.controls.value.updateValueAndValidity();
  }

  initGenericMulti(disable: boolean): void {
    // we load the full object into the selection array for multi here. We have to decide to use id or description for filtering
    this.distListParams.columnName = this.selectedColumnLabel;
    this.distListParams.tableName = this.mainForm.get('table').value;
    this.distListParams.interfaceObjectEnum = this.InterfaceObjectEnum;
    this.distListParams.contextEnumerator = this.ContextEnumerator;
    this.distListParams.currentFilter = this.currentFilter;
    this.distListParams.organizationID = this.organizationId;
    this.distListParams.category = this.category;
    this.distListParams.setAttributeID = this.setAttributeID;
    this.distListParams.surveyID = null;
    this.distListParams.surveyQuestionID = null;
    if (this.mainForm.get('table').value === 'SURVEYRESPONSE') {
      this.distListParams.surveyID = this.selectedSurveyID;
      if (this.mainForm.get('column').value !== null) {
        this.distListParams.surveyQuestionID = this.mainForm.get('column').value;
      }
    }
    if (this.selectedColumnLabel) {
      if (this.mainForm.get('table').value === 'ATTRIBUTE' || this.mainForm.get('table').value === 'STUDENTATTRIBUTE' || this.mainForm.get('table').value === 'ORGATTRIBUTE') {
        this.attributesService.getAttribute(this.setAttributeID)
          .pipe(take(1))
          .subscribe({
            next: (result) => {
              this.genericInputObj.data = result.Options;
              this.genericInputObj = Object.assign({}, this.genericInputObj);
            }
          });

      } else {
        this.distListLookupService.getDistLookups(this.distListParams).then(res => {
          this.genericInputObj.data = res;
          this.genericInputObj = Object.assign({}, this.genericInputObj);
          if (this.isNewFilter && !this.isNewDist) {
            if (this.currentRosterQuery.Filters && this.currentRosterQuery.Filters.find(x => x.Enumerator === this.mainForm.get('table').value)) {
              const currentTableFilter = this.currentRosterQuery.Filters.find(x => x.Enumerator === this.mainForm.get('table').value);
              if (currentTableFilter.FilterPrimeNG.filters) {
                // When we filter on ID and not Description
                if (this.selectedIsID && this.selectedColumnField !== 'OrganizationName' && this.selectedColumnField !== 'BargainingUnitDescription') {
                  currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value].forEach(x => {
                    const selectedObject = this.genericInputObj.data.find(item => item.ID === x.value);
                    this.selectedGenericItems.push(selectedObject);
                  });
                  // Exception for filtering on org id ancestor
                } else if (this.selectedIsID && this.selectedColumnField === 'OrganizationName') {
                  currentTableFilter.FilterPrimeNG.filters.MembershipAncestorOrganizationID.forEach(x => {
                    const selectedObject = this.genericInputObj.data.find(item => item.ID === x.value);
                    this.selectedGenericItems.push(selectedObject);
                  });
                  // filtering on id for bargaining description
                } else if (this.selectedIsID && this.selectedColumnField === 'BargainingUnitDescription') {
                  delete currentTableFilter.FilterPrimeNG.filters.BargainingUnitDescription;
                  currentTableFilter.FilterPrimeNG.filters.BargainingUnitID = [];
                  currentTableFilter.FilterPrimeNG.filters.BargainingUnitID.forEach(x => {
                    const selectedObject = this.genericInputObj.data.find(item => item.ID === x.value);
                    this.selectedGenericItems.push(selectedObject);
                  });
                  // filtering on description
                } else if (currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value]) {
                  currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value].forEach(x => {
                    const selectedObject = this.genericInputObj.data.find(item => item.Description === x.ValueDescription);
                    this.selectedGenericItems.push(selectedObject);
                  });
                }
              }
            }
          }
        });
      }
    }
    this.genericInputObj = {
      labelText: this.selectedColumnLabel,
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.selectedGenericItems,
      disabled: disable
    };
  }

  getGenericMultiData(event: any): void {
    if (event) {
      this.selectedGenericItems = event;
    } else {
      this.selectedGenericItems = null;
    }
    if (this.selectedGenericItems && this.selectedGenericItems.length > 0) {
      this.mainForm.markAsDirty();
      this.mainForm.get('value').setValue(this.selectedGenericItems);
    }
  }

  debug() {
    console.log(this.mainForm);
  }

  initGenericSelect(disable: boolean): void {
    this.genericSelectObj = {
      labelText: this.selectedColumnLabel,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: (this.selectedSurveyID !== null),
      selectFirstValue: false,
      initSelected: this.selectedGenericItem,
      disabled: disable,
      emitChangeOnLoad: true
    };
    switch (this.selectedColumnLabel) {
      case 'Communication' : {
        this.communicationsService.getCommunicationsPrimeNG('DATA', this.ContextEnumerator, this.currentFilter, this.organizationId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.genericSelectObj.data = [];
              res.Data.forEach(x => {
                this.genericSelectObj.data.push({Description: x.Title, ID: x.ID});
              });
              //this.genericInputObj.data = res;
              this.genericSelectObj = Object.assign({}, this.genericSelectObj);
            }
          });
        break;
      }
      default : {
        this.genericSelectObj.data = [];
        if (this.columns.filter(x => x.field === this.selectedColumnField)[0]?.id) {
          this.surveysService.getSurvey(this.columns.filter(x => x.field === this.selectedColumnField)[0]?.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.genericSelectObj.data = [];
                res.Questions.forEach(x => {
                  this.genericSelectObj.data.push({Description: x.Description, ID: x.ID});
                });
                //this.genericInputObj.data = res;
                this.genericSelectObj = Object.assign({}, this.genericSelectObj);
              }
            });
        }
        break;
      }
    }
  }

  getGenericSelectData(event: any): void {
    if (event && event[0]) {
      this.selectedGenericItem = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.selectedGenericItem = null;
    }
    if (this.selectedGenericItem) {
      this.mainForm.markAsDirty();
      this.mainForm.get('matchType').setValue('equals');
      this.mainForm.get('value').setValue(this.selectedGenericItem);
      this.valueDescription = event[0].Description;
    }
  }

  getSurveySelectData(event: any): void {
    this.columns.length = 0;
    if (event && event.value) {
      this.selectedSurveyItem = (event.value !== null) ? event.value : 0;
    } else if (event) {
      this.selectedSurveyItem = (event !== null) ? event : 0;
    } else {
      this.selectedSurveyItem = null;
    }
    if (this.selectedSurveyItem) {
      this.selectedSurveyID = this.selectedSurveyItem;
      this.mainForm.markAsDirty();
      this.mainForm.get('survey').setValue(this.selectedSurveyItem);
      this.surveysService.getSurvey(this.selectedSurveyItem)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.selectedSurveyDescription = res.Description;
            this.columns.push({
              id: null,
              field: null,
              header: '--',
              columnType: null
            });
            res.Questions.forEach(x => {
              this.columns.push({
                id: x.ID,
                field: x.ID,
                header: x.Description,
                columnType: x.DataTypeDescription
              });
            });
            this.mainForm.get('column').setValue(null);
            this.getMatchTypes();
          }
        });
    } else {
      this.selectedColumnField = null;
    }
  }

  initBoolean(disable: boolean): void {
    this.booleanInputObj = {
      labelText: this.selectedColumnLabel,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable
    };
    this.booleanInputObj.data = this.distributionListDefinitions.booleanValues;
  }

  getBooleanData(event: any): void {
    this.setBooleanValue = event[0].Description === 'Yes';
    this.mainForm.get('value').setValue(this.setBooleanValue);
    this.valueDescription = event[0].Description;
  }

  initYesNo(disable: boolean): void {
    this.yesNoInputObj = {
      labelText: this.selectedColumnLabel,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: (this.selectedSurveyID !== null),
      selectFirstValue: false,
      initSelected: this.setYesNoValue !== null ? this.setYesNoValue : null,
      disabled: false
    };
    this.yesNoInputObj.data = this.distributionListDefinitions.yesNoValues;
  }

  getYesNoData(event: any): void {
    // console.log(event[0].ID);
    if (this.mainForm.get('survey').value) {
      this.setYesNoValue = event[0].Description;
    } else {
      this.setYesNoValue = event[0].Description === 'Yes';
    }

    if (event[0].ID === 'isEmpty') {
      this.mainForm.get('matchType').setValue('isnullorempty');
    } else {
      this.mainForm.get('matchType').setValue('equals');
    }
    this.mainForm.get('value').setValue(this.setYesNoValue);
    this.valueDescription = event[0].Description;
  }

  getctYesNoData(event: any): void {
    if (event[0].ID === 'isEmpty') {
      this.mainForm.get('matchType').setValue('isnullorempty');
    } else {
      this.mainForm.get('matchType').setValue('equals');
    }
    this.mainForm.get('value').setValue(event[0].Description);
    this.valueDescription = event[0].Description;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  processData() {
    // console.log('date = ' + this.isDate + ' picker = ' + this.isPicker + ' boolean = ' + this.isBoolean);
    //format date for saving
    let formattedValue: string = this.mainForm.get('value').value;
    let formattedStartValue: string = this.mainForm.get('startValue').value;
    let formattedEndValue: string = this.mainForm.get('endValue').value;

    if (this.isDate) {
      if (this.mainForm.get('matchType').value === 'between') {
        formattedStartValue = this.datePipe.transform(this.mainForm.get('startValue').value, 'yyyy-MM-dd');
        formattedEndValue = this.datePipe.transform(this.mainForm.get('endValue').value, 'yyyy-MM-dd');
      } else if (this.mainForm.get('matchType').value === 'daysfrompresentbetween' || this.mainForm.get('matchType').value === 'monthsfrompresentbetween') {
        formattedStartValue = this.mainForm.get('startValue').value;
        formattedEndValue = this.mainForm.get('endValue').value;
      } else if (this.mainForm.get('matchType').value !== 'daysfrompresent' && this.mainForm.get('matchType').value !== 'monthsfrompresent') {
        formattedValue = this.datePipe.transform(this.mainForm.get('value').value, 'yyyy-MM-dd');
      }
    }
    let formattedFilters: RosterQueryFilterModel[];
    if (this.currentRosterQuery?.Filters?.find(x => x.Enumerator === this.mainForm.get('table').value)) {
      const index = this.currentRosterQuery.Filters.findIndex(x => x.Enumerator === this.mainForm.get('table').value);
      if (this.currentRosterQuery.Filters[index].FilterPrimeNG.filters[this.mainForm.get('column').value]) {
        // console.log('in existing column');
        formattedFilters = this.currentRosterQuery.Filters[index].FilterPrimeNG.filters[this.mainForm.get('column').value];
        // console.log(formattedFilters);
      } else {
        formattedFilters = [];
      }
    } else {
      formattedFilters = [];
    }

    //iterate over picklist values for column
    if (this.isPicker) {
      // console.log('0');
      if (this.selectedColumnField === 'OrganizationName' || this.selectedColumnField === 'BargainingUnitDescription') {
        if (this.selectedColumnField === 'OrganizationName') {
          this.selectedColumnField = 'MembershipAncestorOrganizationID';
        }
        if (this.selectedColumnField === 'BargainingUnitDescription') {
          this.selectedColumnField = 'BargainingUnitID';
        }
        if (this.mainForm.get('matchType').value === 'equals') {
          // console.log('1');
          this.selectedGenericItems.forEach(arrayItem => {
            arrayItem.Description = arrayItem.Description.replaceAll('-', '');
            formattedFilters.push({
              matchMode: 'equals',
              value: arrayItem.ID,
              operator: 'or',
              HeaderName: this.selectedColumnLabel,
              FilterDataTypeEnumerator: 'NUMBER',
              ValueDescription: arrayItem.Description,
              isID: this.selectedIsID
            });

          });
        } else {
          // console.log('2');
          formattedFilters.push({
            matchMode: this.mainForm.get('matchType').value,
            value: null,
            operator: 'or',
            HeaderName: this.selectedColumnLabel,
            FilterDataTypeEnumerator: 'NUMBER',
            ValueDescription: null,
            isID: this.selectedIsID
          });
        }
      } else {
        // console.log('3');
        if (this.mainForm.get('matchType').value === 'equals') {
          this.selectedGenericItems.forEach(arrayItem => {
            formattedFilters.push({
              matchMode: 'equals',
              value: arrayItem.Description,
              operator: 'or',
              HeaderName: this.selectedColumnLabel,
              FilterDataTypeEnumerator: 'STRING',
              ValueDescription: arrayItem.Description,
              isID: this.selectedIsID
            });
          });
        } else {
          // console.log('4');
          formattedFilters.push({
            matchMode: this.mainForm.get('matchType').value,
            value: null,
            operator: 'or',
            HeaderName: this.selectedColumnLabel,
            FilterDataTypeEnumerator: 'NUMBER',
            ValueDescription: null,
            isID: this.selectedIsID
          });
        }
      }
    } else if (this.isBoolean) {
      formattedFilters.push({
        matchMode: 'equals',
        value: this.setBooleanValue,
        operator: 'or',
        HeaderName: this.selectedColumnLabel,
        FilterDataTypeEnumerator: this.selectedColumnEnumType,
        ValueDescription: this.valueDescription ? this.valueDescription : this.setBooleanValue.toString()
      });
    } else if (this.isYesNo) {
      // console.log('in yes no');
      formattedFilters.push({
        matchMode: this.mainForm.get('matchType').value,
        value: this.setYesNoValue,
        operator: 'or',
        HeaderName: this.selectedColumnLabel,
        FilterDataTypeEnumerator: this.selectedColumnEnumType,
        ValueDescription: this.valueDescription ? this.valueDescription : this.setYesNoValue.toString()
      });
    } else if (this.mainForm.get('matchType').value === 'between' || this.mainForm.get('matchType').value === 'monthsfrompresentbetween' || this.mainForm.get('matchType').value === 'daysfrompresentbetween') {
      formattedFilters.push({
        matchMode: this.mainForm.get('matchType').value,
        value: formattedStartValue,
        endValue: formattedEndValue,
        operator: 'or',
        HeaderName: this.selectedColumnLabel,
        FilterDataTypeEnumerator: this.selectedColumnEnumType,
        ValueDescription: null
      });
    } else {
      // manage surveys
      if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && (this.mainForm.get('column').value === undefined || this.mainForm.get('column').value === null)) {
        // survey only
        formattedFilters.push({
          matchMode: 'equals',
          value: this.selectedSurveyID,
          operator: 'or',
          HeaderName: 'Title',
          FilterDataTypeEnumerator: this.selectedColumnEnumType,
          ValueDescription: this.selectedSurveyDescription
        });
      } else {
        formattedFilters.push({
          matchMode: this.mainForm.get('matchType').value,
          value: formattedValue,
          operator: 'or',
          HeaderName: this.selectedColumnLabel,
          FilterDataTypeEnumerator: this.selectedColumnEnumType,
          ValueDescription: this.valueDescription ? this.valueDescription : formattedValue
        });
      }

    }
    // console.log(formattedFilters);
    //getting a new dist list formatted for post call
    if (this.isNewDist && (this.mainForm.get('table').value !== 'ATTRIBUTE' && this.mainForm.get('table').value !== 'STUDENTATTRIBUTE')) {
      // manage surveys
      if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && (this.mainForm.get('column').value === undefined || this.mainForm.get('column').value === null)) {
        // survey only
        this.selectedRosterQuery.ContextEnumerator = this.ContextEnumerator;
        this.selectedRosterQuery.Filters = [];
        this.selectedRosterQuery.Filters.push({
          Enumerator: this.mainForm.get('table').value,
          FilterPrimeNG: {
            globalOperator: 'and',
            filters: {
              ['SurveyID']: formattedFilters
            }
          }
        });
      } else if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && this.mainForm.get('column').value !== null) {
        // survey and question
        this.selectedRosterQuery.ContextEnumerator = this.ContextEnumerator;
        this.selectedRosterQuery.Filters = [];
        this.selectedRosterQuery.Filters.push({
          Enumerator: this.mainForm.get('table').value,
          FilterPrimeNG: {
            globalOperator: 'and',
            filters: {
              ['SurveyID']: [{
                matchMode: 'equals',
                value: this.selectedSurveyID,
                operator: 'or',
                HeaderName: 'Title',
                FilterDataTypeEnumerator: this.selectedColumnEnumType,
                ValueDescription: this.selectedSurveyDescription
              }],
              ['QuestionID']: [{
                matchMode: 'equals',
                value: this.selectedColumnID,
                operator: 'or',
                HeaderName: 'Question',
                FilterDataTypeEnumerator: this.selectedColumnEnumType,
                ValueDescription: this.selectedQuestionDescription
              }],
              ['Value']: formattedFilters
            }
          }
        });
      } else {
        this.selectedRosterQuery.ContextEnumerator = this.ContextEnumerator;
        this.selectedRosterQuery.Filters = [];
        this.selectedRosterQuery.Filters.push({
          Enumerator: this.mainForm.get('table').value,
          FilterPrimeNG: {
            globalOperator: 'and',
            filters: {
              [this.selectedColumnField]: formattedFilters
            }
          }
        });
      }
      if (this.isDate || this.isPicker || this.isBoolean) {
        this.mainForm.get('value').setValue(null);
      }
      this.saveData();
    } else if (this.isNewDist && (this.mainForm.get('table').value === 'ATTRIBUTE' || this.mainForm.get('table').value === 'STUDENTATTRIBUTE')) {
      this.selectedRosterQuery.ContextEnumerator = this.ContextEnumerator;
      this.selectedRosterQuery.Filters = [];
      this.selectedRosterQuery.Filters.push({
        Enumerator: this.mainForm.get('table').value,
        FilterPrimeNG: {
          globalOperator: 'and',
          filters: {
            [this.selectedColumnField]: formattedFilters
          }
        }
      });
      if (this.isDate || this.isPicker || this.isBoolean) {
        this.mainForm.get('value').setValue(null);
      }
      console.log(this.selectedRosterQuery.Filters);
      this.saveData();
    } else {
      //getting format ready for the table already has a filter on it
      if (this.currentRosterQuery.Filters.find(x => x.Enumerator === this.mainForm.get('table').value)) {
        const currentTableFilter = this.currentRosterQuery.Filters.find(x => x.Enumerator === this.mainForm.get('table').value);
        if (!currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value] && this.selectedColumnField !== 'MembershipAncestorOrganizationID') {
          if (this.mainForm.get('table').value !== 'SURVEYRESPONSE') {
            currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value] = [];
          }
        }
        if (this.selectedColumnField === 'MembershipAncestorOrganizationID') {
          currentTableFilter.FilterPrimeNG.filters.MembershipAncestorOrganizationID = formattedFilters;
        } else if (this.selectedColumnField === 'BargainingUnitID') {
          currentTableFilter.FilterPrimeNG.filters.BargainingUnitID = formattedFilters;
        } else {
          if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && (this.mainForm.get('column').value === undefined || this.mainForm.get('column').value === null)) {
            if (currentTableFilter.FilterPrimeNG.filters.SurveyID) {
              formattedFilters.forEach(x => {
                currentTableFilter.FilterPrimeNG.filters.SurveyID.push(x);
              });
            } else {
              currentTableFilter.FilterPrimeNG.filters.SurveyID = formattedFilters;
            }
          } else if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && this.mainForm.get('column').value !== null) {
            if (currentTableFilter.FilterPrimeNG.filters.SurveyID) {
              currentTableFilter.FilterPrimeNG.filters.SurveyID.push({
                matchMode: 'equals',
                value: this.selectedSurveyID,
                operator: 'or',
                HeaderName: 'Title',
                FilterDataTypeEnumerator: this.selectedColumnEnumType,
                ValueDescription: this.selectedSurveyDescription
              });
            } else {
              currentTableFilter.FilterPrimeNG.filters.SurveyID = [{
                matchMode: 'equals',
                value: this.selectedSurveyID,
                operator: 'or',
                HeaderName: 'Title',
                FilterDataTypeEnumerator: this.selectedColumnEnumType,
                ValueDescription: this.selectedSurveyDescription
              }];
            }
            if (currentTableFilter.FilterPrimeNG.filters.QuestionID) {
              currentTableFilter.FilterPrimeNG.filters.QuestionID.push({
                matchMode: 'equals',
                value: this.selectedColumnID,
                operator: 'or',
                HeaderName: 'Question',
                FilterDataTypeEnumerator: this.selectedColumnEnumType,
                ValueDescription: this.selectedQuestionDescription
              });
            } else {
              currentTableFilter.FilterPrimeNG.filters.QuestionID = [{
                matchMode: 'equals',
                value: this.selectedColumnID,
                operator: 'or',
                HeaderName: 'Question',
                FilterDataTypeEnumerator: this.selectedColumnEnumType,
                ValueDescription: this.selectedQuestionDescription
              }];
            }
            if (currentTableFilter.FilterPrimeNG.filters.Value) {
              formattedFilters.forEach(x => {
                currentTableFilter.FilterPrimeNG.filters.Value.push(x);
              });
            } else {
              currentTableFilter.FilterPrimeNG.filters.Value = formattedFilters;
            }
          } else {
            currentTableFilter.FilterPrimeNG.filters[this.mainForm.get('column').value] = formattedFilters;
          }
        }

        if (!this.isGridPage) {
          if (this.isDate || this.isPicker || this.isBoolean) {
            this.mainForm.get('value').setValue(null);
          }
          this.saveData();
        } else {
          this.updateLiveFilter.emit(this.currentRosterQuery);
        }
      } else {
        //getting format ready if table does not have filter on it
        if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && (this.mainForm.get('column').value === undefined || this.mainForm.get('column').value === null)) {
          this.currentRosterQuery.Filters.push({
            Enumerator: this.mainForm.get('table').value,
            FilterPrimeNG: {
              globalOperator: 'and',
              filters: {
                ['SurveyID']: formattedFilters
              }
            }
          });
        } else if (this.mainForm.get('table').value === 'SURVEYRESPONSE' && this.mainForm.get('column').value !== null) {
          this.currentRosterQuery.Filters.push({
            Enumerator: this.mainForm.get('table').value,
            FilterPrimeNG: {
              globalOperator: 'and',
              filters: {
                ['SurveyID']: [{
                  matchMode: 'equals',
                  value: this.selectedSurveyID,
                  operator: 'or',
                  HeaderName: 'Title',
                  FilterDataTypeEnumerator: this.selectedColumnEnumType,
                  ValueDescription: this.selectedSurveyDescription
                }],
                ['QuestionID']: [{
                  matchMode: 'equals',
                  value: this.selectedColumnID,
                  operator: 'or',
                  HeaderName: 'Question',
                  FilterDataTypeEnumerator: this.selectedColumnEnumType,
                  ValueDescription: this.selectedQuestionDescription
                }],
                ['Value']: formattedFilters
              }
            }
          });
        } else {
          // console.log('in here');
          this.currentRosterQuery.Filters.push({
            Enumerator: this.mainForm.get('table').value,
            FilterPrimeNG: {
              globalOperator: 'and',
              filters: {
                [this.selectedColumnField]: formattedFilters
              }
            }
          });
        }
        if (this.isDate || this.isPicker || this.isBoolean) {
          this.mainForm.get('value').setValue(null);
        }
        this.saveData();


      }
    }
  }

  saveData(filters?: any[]) {
    if (this.isNewDist) {
      this.rosterQueryService.createRosterQueryPrimeNg(this.selectedRosterQuery)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'New Distribution List has been added.'
            });
            this.updateFilter.emit(res.RosterQueryID);
            this.isEdit = true;
            this.mainForm.get('value').setValue(null);
            this.mainForm.get('column').setValue(null);
            this.mainForm.get('survey').setValue(null);
            this.mainForm.get('table').setValue('ROSTER');
            this.surveys.length = 0;
            this.columns.length = 0;
            this.selectedSurveyItem = null;
            this.getColumns();
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Something went wrong.'});
          }
        });
    } else {
      const saveArray: RosterQueryExistingModel = JSON.parse(JSON.stringify(this.currentRosterQuery));
      if (filters) {
        saveArray.Filters = filters;
      }
      this.rosterQueryService.updateRosterQueryPrimeNg(this.selectedRosterQuery.RosterQueryID, saveArray)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Distribution List has been updated'
            });
            this.updateFilter.emit(this.currentRosterQuery.RosterQueryID);
            this.isEdit = true;
            this.mainForm.get('value').setValue(null);
            this.mainForm.get('column').setValue(null);
            this.mainForm.get('survey').setValue(null);
            if (this.router.url.includes('membership')) {
              this.mainForm.get('table').setValue('ROSTER');
            }
            if (this.router.url.includes('training')) {
              this.mainForm.get('table').setValue('STUDENT');
            }
            if (this.router.url.includes('organize')) {
              this.mainForm.get('table').setValue('PROSPECTS');
            }
            this.surveys.length = 0;
            this.columns.length = 0;
            this.selectedSurveyItem = null;
            this.getColumns();
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Something went wrong.'});
          }
        });
    }
  }

  showMatch() {
    if (this.isNewFilter) {
      return !this.isBoolean && !this.isYesNo && !this.isctYesNo && !this.isSelect;
    } else {
      return true;
    }
  }

  showValue() {
    if (!this.isYesNo && !this.isctYesNo) {
      return this.mainForm.controls.matchType.value !== 'between' && !this.isPicker && !this.isSelect
        && this.mainForm.controls.matchType.value !== 'monthsfrompresentbetween' && this.mainForm.controls.matchType.value !== 'daysfrompresentbetween'
        && !this.isBoolean && this.mainForm.controls.matchType.value !== 'isnullorempty'
        && this.mainForm.controls.matchType.value !== 'isnotnullorempty';
    } else {
      return !this.isNewFilter;
    }
  }
}
