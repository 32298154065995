import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';

import {AddressesGridModel, AddressModel, CreateUpdateAddressModel} from '../../../../../models/addresses.model';
import {AddressesService} from '../../../../../services/addresses.service';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {NotZeroValidation} from '../../../../utilities/form.utility';
import {PageModel} from '../../../../../models/page.model';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PageService} from '../../../../../services/page.services';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-addresses-form',
  templateUrl: './addresses-form.component.html',
  styleUrls: ['./addresses-form.component.scss']
})
export class AddressesFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  currentAddress = {} as AddressModel;
  address = {} as AddressModel;
  addresses: AddressesGridModel;
  loading: boolean = false;
  updateAddress = {} as CreateUpdateAddressModel;
  inputObjContactCategory: GenericSelectModel;
  setContactCategoryId: number;
  primary: boolean = false;
  undeliverable: boolean = false;
  isSaving: boolean;
  addressData: AddressModel = {} as AddressModel;
  rtn: any[];
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() addressID: number;
  @Input() locationHeader: string;
  @Input() userCall: boolean = false;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();
  isDialog: boolean = false;

  private pageInfo = {} as PageModel;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService,
              private lookupsDataService: LookupsDataService,
              private addressesService: AddressesService,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig, private pageService: PageService,
              private route: ActivatedRoute
  ) {
    this.mainForm = this.formBuilder.group({
      category: new FormControl(null, [Validators.required, NotZeroValidation()]),
      primary: new FormControl(false),
      undeliverable: new FormControl(false)
    });
  }

  ngOnInit(): void {
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.addressID = this.pageInfo.ContentID;
          this.DBEntityID = this.pageInfo.DBEntityID;
          this.DBEntity = this.pageInfo.DBEntity;
          this.locationHeader = this.pageInfo.header;
          this.canTabWrite = this.pageInfo.canTabWrite;
          this.isEdit = this.pageInfo.isEdit;
          // this.isPage = this.pageInfo.isPage;
          this.isDialog = this.pageInfo.isDialog;
          // this.ContentID = this.pageInfo.ContentID;
          this.userCall = this.pageInfo.userCall;

          if (this.isEdit) {
            this.initForm(this.isEdit);
          } else {
            this.setHomeDefault().then(() => {
              this.initSelects();
              this.addressData.CountryID = null;
              this.addressData.Address1 = null;
              this.addressData.Address2 = null;
              this.addressData.City = null;
              this.addressData.StateProvinceID = null;
              this.addressData.Zip = null;
              this.addressData.ZipPlus4 = null;
              this.addressData.County = null;
              this.addressData.Latitude = null;
              this.addressData.Longitude = null;
              this.addressData.Ready = true;
            });
          }

          if (this.canTabWrite) {
            this.mainForm.enable();
          } else {
            this.mainForm.disable();
          }
        }
      }
    });
    // if (this.canTabWrite) {
    //   this.mainForm.markAsPristine();
    //   this.mainForm.markAsUntouched();
    // } else {
    //   this.mainForm.disable();
    // }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.isEdit) {
    //   if (this.isEdit) {
    //     this.initForm(this.isEdit);
    //   } else {
    //     this.initSelects();
    //     this.addressData.CountryID = null;
    //     this.addressData.Address1 = null;
    //     this.addressData.Address2 = null;
    //     this.addressData.City = null;
    //     this.addressData.StateProvinceID = null;
    //     this.addressData.Zip = null;
    //     this.addressData.ZipPlus4 = null;
    //     this.addressData.County = null;
    //     this.addressData.Latitude = null;
    //     this.addressData.Longitude = null;
    //     this.addressData.Ready = true;
    //   }
    // }
    // if (changes.canTabWrite) {
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
    // }
  }

  initForm(isEdit: boolean): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.loading = true;
    if (isEdit) {
      if (this.DBEntity && !this.userCall) {
        this.addressesService.getPersonAddress(this.DBEntityID, this.addressID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.address = res;
              this.currentAddress = res;
              this.setContactCategoryId = this.currentAddress.ContactCategoryID;
              this.mainForm.get('category').setValue(this.currentAddress.ContactCategoryID);
              this.primary = this.currentAddress.Primary;
              this.mainForm.get('primary').setValue(this.currentAddress.Primary);
              this.undeliverable = this.currentAddress.FailedCommunication;
              this.mainForm.get('undeliverable').setValue(this.currentAddress.FailedCommunication);
              this.initContactCategory(false);

              if (this.primary) {
                this.mainForm.get('primary').disable();
              } else {
                this.mainForm.get('primary').enable();
              }

              this.addressData.CountryID = this.currentAddress.CountryID;
              this.addressData.Address1 = this.currentAddress.Address1;
              this.addressData.Address2 = this.currentAddress.Address2;
              this.addressData.City = this.currentAddress.City;
              this.addressData.StateProvinceID = this.currentAddress.StateProvinceID;
              this.addressData.Zip = this.currentAddress.Zip;
              this.addressData.ZipPlus4 = this.currentAddress.ZipPlus4;
              this.addressData.County = this.currentAddress.County;
              this.addressData.Latitude = this.currentAddress.Latitude;
              this.addressData.Longitude = this.currentAddress.Longitude;
              this.addressData.Ready = true;
              this.loading = false;
            }
          });
      } else {
        this.addressesService.getUserPersonAddress(this.addressID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.address = res;
              this.currentAddress = res;
              this.setContactCategoryId = this.currentAddress.ContactCategoryID;
              this.mainForm.get('category').setValue(this.currentAddress.ContactCategoryID);
              this.primary = this.currentAddress.Primary;
              this.mainForm.get('primary').setValue(this.currentAddress.Primary);
              this.undeliverable = this.currentAddress.FailedCommunication;
              this.mainForm.get('undeliverable').setValue(this.currentAddress.FailedCommunication);
              this.initContactCategory(false);

              if (this.primary) {
                this.mainForm.get('primary').disable();
              } else {
                this.mainForm.get('primary').enable();
              }

              this.addressData.CountryID = this.currentAddress.CountryID;
              this.addressData.Address1 = this.currentAddress.Address1;
              this.addressData.Address2 = this.currentAddress.Address2;
              this.addressData.City = this.currentAddress.City;
              this.addressData.StateProvinceID = this.currentAddress.StateProvinceID;
              this.addressData.Zip = this.currentAddress.Zip;
              this.addressData.ZipPlus4 = this.currentAddress.ZipPlus4;
              this.addressData.County = this.currentAddress.County;
              this.addressData.Latitude = this.currentAddress.Latitude;
              this.addressData.Longitude = this.currentAddress.Longitude;
              this.addressData.Ready = true;
              this.loading = false;
            }
          });
      }
    } else {
      this.initSelects();
      this.loading = false;
    }
  }

  initSelects() {
    this.initContactCategory(false);
  }

  setHomeDefault() {
    return new Promise((resolve, reject) => {
      this.lookupsDataService.getContactCategoriesLookupData().then((lookupData: any[]) => {
        lookupData.forEach(x => {
          if (x.Description === 'Home') {
            this.setContactCategoryId = x.ID;
          }
        });
        resolve(null);
      });
    });
  }

  initContactCategory(disable: boolean): void {
    console.log(this.setContactCategoryId);
    this.inputObjContactCategory = {
      labelText: 'Category',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setContactCategoryId,
      disabled: disable,
      canWrite: this.canTabWrite,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getContactCategoriesLookupData().then((lookupData) => {
      this.inputObjContactCategory.data = lookupData;
      this.inputObjContactCategory = Object.assign({}, this.inputObjContactCategory);
    });
  }

  getContactCategoryData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setContactCategoryId = event[0].ID;
    } else {
      this.setContactCategoryId = 0;
    }
    this.mainForm.get('category').setValue(this.setContactCategoryId);
    this.mainForm.markAsDirty();
  }

  processData(): void {
    this.isSaving = true;
    this.updateAddress.ContactCategoryID = this.setContactCategoryId;
    this.updateAddress.Primary = this.mainForm.get('primary').value;
    this.updateAddress.FailedCommunication = this.mainForm.get('undeliverable').value;
    this.updateAddress.CountryID = this.addressData.CountryID;
    this.updateAddress.Address1 = this.addressData.Address1;
    this.updateAddress.Address2 = this.addressData.Address2;
    this.updateAddress.City = this.addressData.City;
    this.updateAddress.StateProvinceID = this.addressData.StateProvinceID;
    this.updateAddress.Zip = this.addressData.Zip;
    this.updateAddress.ZipPlus4 = this.addressData.ZipPlus4;
    this.updateAddress.County = this.addressData.County;
    this.updateAddress.Latitude = this.addressData.Latitude;
    this.updateAddress.Longitude = this.addressData.Longitude;
    this.saveForm();
  }

  saveForm(): void {
    if (!this.isEdit) {
      if (this.DBEntity && !this.userCall) {
        this.addressesService.createPersonAddress(this.DBEntityID, this.updateAddress)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The address has been added.'
              });
              this.isSaving = false;
              this.closeAddEdit.emit(true);
              if (this.isDialog) {
                this.ref.close('save');
              }
            }
          });
      } else {
        this.addressesService.createUserPersonAddress(this.updateAddress)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The address has been added.'
              });
              this.isSaving = false;
              this.closeAddEdit.emit(true);
              if (this.isDialog) {
                this.ref.close('save');
              }
            }
          });
      }
    } else {
      if (this.DBEntity && !this.userCall) {
        this.addressesService.updatePersonAddress(this.DBEntityID, this.addressID, this.updateAddress)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The address has been updated.'
              });
              this.mainForm.markAsPristine();
              this.mainForm.markAsUntouched();
              this.isSaving = false;
              this.closeAddEdit.emit(true);
              if (this.isDialog) {
                this.ref.close('save');
              }
            }
          });
      } else {
        this.addressesService.updateUserPersonAddress(this.addressID, this.updateAddress)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The address has been updated.'
              });
              this.mainForm.markAsPristine();
              this.mainForm.markAsUntouched();
              this.isSaving = false;
              this.closeAddEdit.emit(true);
              if (this.isDialog) {
                this.ref.close('save');
              }
            }
          });
      }
    }
  }

  cancel(): void {
    this.addressData = {} as AddressModel;
    if (!this.isEdit) {
      if (this.isDialog) {
        this.ref.destroy();
      } else {
        this.mainForm.reset();
        this.closeAddEdit.emit();
      }
    } else {
      this.initForm(this.isEdit);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  setAddress(addressChanges: AddressModel): void {
    if (addressChanges) {
      this.mainForm.markAsDirty();
      this.addressData.CountryID = addressChanges.CountryID;
      this.addressData.Address1 = addressChanges.Address1;
      this.addressData.Address2 = addressChanges.Address2;
      this.addressData.City = addressChanges.City;
      this.addressData.StateProvinceID = addressChanges.StateProvinceID;
      this.addressData.Zip = addressChanges.Zip;
      this.addressData.ZipPlus4 = addressChanges.ZipPlus4;
      this.addressData.County = addressChanges.County;
      this.addressData.Latitude = addressChanges.Latitude;
      this.addressData.Longitude = addressChanges.Longitude;
    }
  }
}
