export const colHeaders = {
  LastName: 'Last Name',
  FirstName: 'First Name',
  MemberNumber: 'Member #',
  OrganizationName: 'Organization',
  Description: 'Description',
  MemberType: 'Member Type',
  MemberStatus: 'Status',
  PaymentType: 'Payment Type',
  PaymentStatus: 'Payment Status',
  PaymentDate: 'Paid Date',
  PaymentRecordedDate: 'Recorded Date',
  PaymentClearedDate: 'Cleared Date',
  TransactionDate: 'Transaction Date',
  TransactionDate_D: 'Transaction Date',
  ClearedTransactionAmount: 'Amount',
  Amount_N: 'Amount',
  Memo: 'Memo',
  EmployerName: 'Employer',
  OrganizationAbbreviation: 'Organization',
  ReceiptNumber: 'Transaction #',
  PaymentStatusDetails: 'Payment Status',
  TransactionType: 'Transaction Type',
  TransactionAmount: 'Transaction Amount',
  TotalPaymentAmount: 'Total Payment Amount',
  AppliedToMonth: 'Applied To Month',
  AppliedToYear: 'Applied To Year',
  AppliedToBillingType: 'Applied To Billing Type',
  IntendedBillingType: 'Intended Billing Type',
  PaidThroughBillingPeriod: 'Paid Through Billing Period',
  PaidThroughDate_D: 'Paid Through Date',
  ResourceReferenceID: 'Resource Reference ID',
  OrganizationID: 'Organization'
};

