import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ContextService} from './context.service';
import {FreshdeskTicketCreateModel} from '../models/freshdesk-ticket.model';

@Injectable({
  providedIn: 'root',
})
export class FreshdeskService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }


  createTicket(dataModel: FreshdeskTicketCreateModel,): Observable<FreshdeskTicketCreateModel> {
    return this.http.post<FreshdeskTicketCreateModel>('https://uniontrack.freshdesk.com/api/v2/tickets', dataModel,
      {headers : new HttpHeaders({Authorization: 'Basic WG5XZFBlU0dOdk9XWktwS3VOYzp0ZXN0', 'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          Accept: 'application/json,text/xml' })});
  }
}
