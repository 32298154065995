<div class="row">
  @if (contextVisible) {
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-10">
      <p-dropdown [options]="contexts" [(ngModel)]="selectedContext" optionLabel="Description" optionValue="ID"
                  [disabled]="contextDisabled" [styleClass]="'full-width'" (onChange)="updateContext()">
      </p-dropdown>
    </div>
  }
  @switch (selectedContext) {
    @case ('MEMBERSHIP') {
      @if (tcORGDROPDOWNDISPLAY) {
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-10">
          <p-dropdown [options]="orgMNames" [(ngModel)]="selectedMOrgId" [optionLabel]="tcORGDROPDOWNDISPLAY"
                      optionValue="ID" [filter]="true" [filterBy]="tcORGDROPDOWNDISPLAY"
                      showClear="true" placeholder="Select a Perspective" [styleClass]="'full-width'"
                      (onChange)="updatePerspective()">
            @switch (tcORGDROPDOWNDISPLAY) {
              @case ('Description') {
                <ng-template let-orgs pTemplate="orgs">
                  <div class="org-names">
                    <div [ngClass]="'level-'+orgs.Level" [pTooltip]="orgs.Description"
                         tooltipPosition="bottom">{{ orgs.Description }}
                    </div>
                  </div>
                </ng-template>
              }
              @case ('Abbreviation') {
                <ng-template let-orgs pTemplate="orgs">
                  <div class="org-names">
                    <div [ngClass]="'level-'+orgs.Level" [pTooltip]="orgs.Description"
                         tooltipPosition="bottom">{{ orgs.Abbreviation }}
                    </div>
                  </div>
                </ng-template>
              }
            }
          </p-dropdown>
        </div>
      }
    }
    @case ('TRAINING') {
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-10">
        <p-dropdown [options]="orgTNames" [(ngModel)]="selectedTOrgId" optionLabel="Description" optionValue="ID"
                    [filter]="true" filterBy="Description"
                    showClear="true" placeholder="Select a Perspective" [styleClass]="'full-width'"
                    (onChange)="updatePerspective()">
          <ng-template let-orgs pTemplate="orgs">
            <div class="org-names">
              <div [ngClass]="'level-'+orgs.Level" pTooltip="{{orgs.Description}}">{{ orgs.Description }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    }
    @case ('ORGANIZE') {
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-10">
        <p-dropdown [options]="orgMNames" [(ngModel)]="selectedOOrgId" optionLabel="Description" optionValue="ID"
                    [filter]="true" filterBy="Description"
                    showClear="true" placeholder="Select a Perspective" [styleClass]="'full-width'"
                    (onChange)="updatePerspective()">
          <ng-template let-orgs pTemplate="orgs">
            <div class="org-names">
              <div [ngClass]="'level-'+orgs.Level" pTooltip="{{orgs.Description}}">{{ orgs.Description }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    }
  }
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-align-right">
    <button (click)="close()" icon="pi pi-times" pButton></button>
  </div>
</div>
