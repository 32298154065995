import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RosterProfileEmploymentModel} from '../../../../../models/roster-profile-employment.model';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {NotZeroValidation, transformDateTimeToDateOnly} from '../../../../utilities/form.utility';
import {RosterProfileEmploymentService} from '../../../../../services/roster-profile-employment.service';
import {RouteContextUtility} from '../../../../utilities/route-context.utility';
import {ContextService} from '../../../../../services/context.service';
import {TenantConfigurations} from '../../../../tenant-configurations/configurations';
import {TenantConfigDataService} from '../../../../../services/tenant-config-data.service';

@Component({
  selector: 'app-employment-default-form',
  templateUrl: './add-edit-employment-default.component.html',
  styleUrls: ['./add-edit-employment-default.component.scss']
})
export class EmploymentDefaultFormComponent implements OnInit, OnChanges, OnDestroy {
  pageTitle = 'Add/Edit Employment';
  mainForm: FormGroup;
  rosterEmployment = {} as RosterProfileEmploymentModel;
  isDirty: boolean;
  isSaving: boolean = false;
  startDate: Date;
  endDate: Date;
  existingMessage: boolean = false;
  routeContext: string;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() PersonID: number;
  @Input() organizationID: number;
  @Input() employmentId: number;
  currentFilter = new PrimeTableFilterModel();
  setEmployerId: number;
  setJobTitleId: number;
  setWorkSiteId: number;
  setTradeId: number;
  setContractId: number;
  setWorkLevelId: number;
  setWageLevelId: number;
  setShiftId: number;
  inputObjEmployer: GenericSelectModel;
  inputObjJobTitle: GenericSelectModel;
  inputObjWorkSite: GenericSelectModel;
  inputObjTrade: GenericSelectModel;
  inputObjWorkLevel: GenericSelectModel;
  inputObjWageLevel: GenericSelectModel;
  inputObjShift: GenericSelectModel;
  inputObjContract: GenericSelectModel;
  tenantConfigurations = TenantConfigurations;
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private confirmationService: ConfirmationService, private messageService: MessageService,
              private formBuilder: FormBuilder, private rosterProfileEmploymentService: RosterProfileEmploymentService,
              private lookupsDataService: LookupsDataService, private routeContextUtility: RouteContextUtility,
              private contextService: ContextService, private tenantConfigDataService: TenantConfigDataService
  ) {
    this.mainForm = this.formBuilder.group({
      primaryDues: new FormControl(null),
      employer: new FormControl(null, [Validators.required, NotZeroValidation()]),
      occupation: new FormControl(null),
      workSite: new FormControl(null),
      trade: new FormControl(null),
      contract: new FormControl(null),
      workLevel: new FormControl(null),
      wageLevel: new FormControl(null),
      employmentEIN: new FormControl(null),
      shift: new FormControl(null),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null),
      jobTitle: new FormControl(null),
    });
  }

  ngOnInit(): void {
    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    this.routeContext = this.routeContextUtility.getRouteContext();
    this.getStudentInfo();
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  getStudentInfo(): void {
    switch (this.routeContext) {
      case 'organize':
        if (this.isEdit) {
          this.getStudentEmployment();
        } else {
          this.initEmployer(false);
          if (this.setEmployerId !== 0) {
            this.initWorkSite(!this.canTabWrite);
            this.initContract(false);
          } else {
            this.initWorkSite(true);
            this.initContract(true);
          }
          this.initJobTitle(false);
          this.initTrade(false);
          this.initWorkLevel(false);
          this.initWageLevel(false);
          this.initShift(false);
        }
        break;
      default:
        if (this.isEdit) {
          this.getStudentEmployment();
        } else {
          this.initEmployer(false);
          if (this.setEmployerId !== 0) {
            this.initWorkSite(!this.canTabWrite);
            this.initContract(false);
          } else {
            this.initWorkSite(true);
            this.initContract(true);
          }
          this.initJobTitle(false);
          this.initTrade(false);
          this.initWorkLevel(false);
          this.initWageLevel(false);
          this.initShift(false);
        }
        break;
    }
  }

  getStudentEmployment(): void {
    this.rosterProfileEmploymentService.getRosterEmployment(this.PersonID, this.employmentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.rosterEmployment = res;
          this.initForm();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Employment data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initForm(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.setEmployerId = this.rosterEmployment.EmployerOrganizationID;
    this.mainForm.get('employer').setValue(this.rosterEmployment.EmployerOrganizationID);
    this.setJobTitleId = this.rosterEmployment.OccupationID;
    this.mainForm.get('occupation').setValue(this.rosterEmployment.OccupationID);
    this.setWorkSiteId = this.rosterEmployment.WorksiteOrganizationID;
    this.mainForm.get('workSite').setValue(this.rosterEmployment.WorksiteOrganizationID);
    this.setTradeId = this.rosterEmployment.TradeID;
    this.setContractId = this.rosterEmployment.ContractID;
    this.setWorkLevelId = this.rosterEmployment.WorkLevelID;
    this.mainForm.get('workLevel').setValue(this.rosterEmployment.WorkLevelID);
    this.setWageLevelId = this.rosterEmployment.WageLevelID;
    this.mainForm.get('wageLevel').setValue(this.rosterEmployment.WageLevelID);
    this.setShiftId = this.rosterEmployment.ShiftID;
    this.mainForm.get('primaryDues').setValue(this.rosterEmployment.PrimaryEmployment);
    this.mainForm.get('employmentEIN').setValue(this.rosterEmployment.EmploymentNumber);
    if (this.rosterEmployment.StartDate_D) {
      this.startDate = new Date(this.rosterEmployment.StartDate_D);
      this.mainForm.get('startDate').setValue(this.startDate);
    }
    if (this.rosterEmployment.EndDate_D) {
      this.endDate = new Date(this.rosterEmployment.EndDate_D);
      this.mainForm.get('endDate').setValue(this.endDate);
    }
    this.initEmployer(!this.canTabWrite);
    if (this.setEmployerId !== 0) {
      this.initWorkSite(!this.canTabWrite);
      this.initContract(false);
    } else {
      this.initWorkSite(true);
      this.initContract(true);
    }
    this.initJobTitle(!this.canTabWrite);
    this.initWorkLevel(!this.canTabWrite);
    this.initWageLevel(!this.canTabWrite);
    this.initShift(!this.canTabWrite);
  }

  initEmployer(disable: boolean): void {
    this.inputObjEmployer = {
      labelText: 'Employer',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setEmployerId,
      disabled: disable
    };
    if (this.organizationID) {
      this.lookupsDataService.getOrganizationsByParentLookupData('UnionEmployer', this.organizationID, this.rosterEmployment.EmployerOrganizationID).then((lookupData) => {
        this.inputObjEmployer.data = lookupData;
        this.inputObjEmployer = Object.assign({}, this.inputObjEmployer);
      });
    }
  }

  initJobTitle(disable: boolean): void {
    this.inputObjJobTitle = {
      labelText: this.tenantConfigDataService.getStringValue('STRINGREPLACEOCCUPATION'),
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setJobTitleId,
      disabled: disable,
      customSelect: 'ShowInactiveIcon'
    };
    if (this.organizationID) {
      this.lookupsDataService.getOccupationsLookupData(this.organizationID).then((lookupData) => {
        this.inputObjJobTitle.data = lookupData;
        this.inputObjJobTitle = Object.assign({}, this.inputObjJobTitle);
      });
    }
  }

  initWorkSite(disable: boolean): void {
    let worksitesLabel: string = 'Worksite';
    if (this.contextService.contextObject.tenantName.toLowerCase() === 'iupat') {
      worksitesLabel = this.tenantConfigurations.IUPATWORKSITESLABEL;
    }
    this.inputObjWorkSite = {
      labelText: worksitesLabel,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setWorkSiteId,
      disabled: disable
    };
    if (!disable && this.setEmployerId) {
      this.lookupsDataService.getOrganizationsByParentLookupData('Employer', this.setEmployerId).then((lookupData) => {
        this.inputObjWorkSite.data = lookupData;
        this.inputObjWorkSite = Object.assign({}, this.inputObjWorkSite);
      });
    }
  }

  initTrade(disable: boolean): void {
    this.inputObjTrade = {
      labelText: 'Trade',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setTradeId,
      disabled: disable
    };
    this.lookupsDataService.getTradesLookupData().then((lookupData) => {
      this.inputObjTrade.data = lookupData;
      this.inputObjTrade = Object.assign({}, this.inputObjTrade);
    });
  }

  initContract(disable: boolean): void {
    this.inputObjContract = {
      labelText: 'Contract',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setContractId,
      disabled: disable
    };
    if (!disable && this.setEmployerId) {
      this.lookupsDataService.getContractsLookupData(this.setEmployerId).then((lookupData) => {
        this.inputObjContract.data = lookupData;
        this.inputObjContract = Object.assign({}, this.inputObjContract);
      });
    }
  }

  initWorkLevel(disable: boolean): void {
    this.inputObjWorkLevel = {
      labelText: 'Work Level',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setWorkLevelId,
      disabled: disable
    };
    this.lookupsDataService.getWorkLevelsLookupData().then((lookupData) => {
      this.inputObjWorkLevel.data = lookupData;
      this.inputObjWorkLevel = Object.assign({}, this.inputObjWorkLevel);
    });
  }

  initWageLevel(disable: boolean): void {
    this.inputObjWageLevel = {
      labelText: 'Wage Level',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setWageLevelId,
      disabled: disable
    };
    this.lookupsDataService.getWageLevelsLookupData().then((lookupData) => {
      this.inputObjWageLevel.data = lookupData;
      this.inputObjWageLevel = Object.assign({}, this.inputObjWageLevel);
    });
  }

  initShift(disable: boolean): void {
    this.inputObjShift = {
      labelText: 'Shift',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setShiftId,
      disabled: disable,
      customSelect: 'ShowInactiveIcon'
    };
    if (this.organizationID) {
      this.lookupsDataService.getShiftsLookupData(this.organizationID).then((lookupData) => {
        this.inputObjShift.data = lookupData;
        this.inputObjShift = Object.assign({}, this.inputObjShift);
      });
    }
  }

  getEmployerData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setEmployerId = event[0].ID;
    } else {
      this.setEmployerId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('employer').setValue(this.setEmployerId);
    if (this.setEmployerId !== 0) {
      this.initWorkSite(false);
      this.initContract(false);
    } else {
      this.initWorkSite(true);
      this.initContract(true);
    }
  }

  getJobTitleData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setJobTitleId = event[0].ID;
    } else {
      this.setJobTitleId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('occupation').setValue(this.setJobTitleId);
  }

  getWorkSiteData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setWorkSiteId = event[0].ID;
    } else {
      this.setWorkSiteId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('workSite').setValue(this.setWorkSiteId);
  }

  getWorkLevelData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setWorkLevelId = event[0].ID;
    } else {
      this.setWorkLevelId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('workLevel').setValue(this.setWorkLevelId);
  }

  getWageLevelData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setWageLevelId = event[0].ID;
    } else {
      this.setWageLevelId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('wageLevel').setValue(this.setWageLevelId);
  }

  getShiftData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setShiftId = event[0].ID;
    } else {
      this.setShiftId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('shift').setValue(this.setShiftId);
  }

  getContractData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setContractId = event[0].ID;
    } else {
      this.setContractId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('contract').setValue(this.setContractId);
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.rosterEmployment.ParentOrganizationID = this.organizationID;
      this.rosterEmployment.PrimaryEmployment = this.mainForm.get('primaryDues').value;
      this.rosterEmployment.EmployerOrganizationID = this.setEmployerId;
      this.rosterEmployment.OccupationID = this.setJobTitleId;
      this.rosterEmployment.JobTitle = this.mainForm.get('jobTitle').value;
      this.rosterEmployment.ContractID = this.mainForm.get('contract').value;
      this.rosterEmployment.EmploymentNumber = this.mainForm.get('employmentEIN').value;
      if (this.setWorkSiteId !== 0) {
        this.rosterEmployment.WorksiteOrganizationID = this.setWorkSiteId;
      }
      this.rosterEmployment.WorkLevelID = this.setWorkLevelId;
      this.rosterEmployment.WageLevelID = this.setWageLevelId;
      this.rosterEmployment.ShiftID = this.setShiftId;
      this.rosterEmployment.StartDate_D = transformDateTimeToDateOnly(this.mainForm.get('startDate').value);
      this.rosterEmployment.EndDate_D = transformDateTimeToDateOnly(this.mainForm.get('endDate').value);
      this.saveForm();
    }
  }

  saveForm(): void {
    if (!this.isEdit) {
      this.rosterProfileEmploymentService.createRosterEmployment(this.PersonID, this.rosterEmployment)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Employment has been saved'
            });
            this.closeAddEdit.emit();
            this.isSaving = false;
          }, error: () => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
          }
        });
    } else {
      this.rosterProfileEmploymentService.updateRosterEmployment(this.PersonID, this.employmentId, this.rosterEmployment)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.confirmationService.close();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Employment has been updated'
            });
            this.mainForm.markAsPristine();
            this.mainForm.markAsUntouched();
            this.isSaving = false;
            this.existingMessage = false;
            this.closeAddEdit.emit();
          }, error: () => {
            this.confirmationService.close();
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            this.existingMessage = false;
          }
        });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  isValid(): boolean {
    return this.mainForm.valid;
  }
}
