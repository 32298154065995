<ng-container>
  @if (contextService.contextObject && !_environment.production && contextService.contextObject?.apiBaseUrlV1) {
    <div class="ut-apidomain" [ngClass]="{'mobile-api' : isMobile}"><b>NOT PRODUCTION</b> -
      API: {{ contextService.contextObject.apiBaseUrlV1 }}
    </div>
  }
  @if (isAuthenticated && (userAgreement || contextService.contextObject.tenantName !== 'IUPAT')) {
    <app-header [showMenuSelect]="ShowMenuSelect"
                [parentShowBurger]="parentShowBurger" (MenuSelect)="menuSelect($event)"
                (MenuViewSelect)="menuViewSelect($event)" [ngClass]="{'mobile-nav' : isMobile}"
    ></app-header>
    @if (topNav) {
      <app-header-nav [selectedMenuView]="selectedMenuView"></app-header-nav>
    }
  }
  <!--  <ng-sidebar-container #appSelect [ngStyle]="{'height': opened ? 'calc(100% - 86px)' : (!dataPerspectiveDescription && !isPublic ? 'calc(100% - 104px)' : (isPublic ? 'calc(100%)' : 'calc(100% - 123px)'))}">-->
  <div #appSelect class="ng-sidebar-container"
       [ngStyle]="{'height': opened ? 'calc(100% - 86px)' : (!dataPerspectiveDescription && !isPublic ? 'calc(100% - 104px)' : (isPublic ? 'calc(100%)' : 'calc(100% - 123px)'))}">
    @if (isAuthenticated && (userAgreement || contextService.contextObject.tenantName !== 'IUPAT')) {
      @if (!topNav) {
        <p-sidebar class="side-bar" [(visible)]="opened" [modal]="isMobile"
                   (onHide)="opened = false; sidenavService.updateMenuSideBar()">
          <app-side-nav [selectedMenuView]="selectedMenuView"></app-side-nav>
        </p-sidebar>
      }
      <p-sidebar class="alertSidebar" position="right" [(visible)]="isAlertsOpened" [modal]="!isMobile"
                 (onHide)="isAlertsOpened = false; sidenavService.updateAlertSideBar()">
        <app-alerts (close)="closeAlerts()"></app-alerts>
      </p-sidebar>
    }
    <div ng-sidebar-content [className]="'ng-sidebar-content'" [ngClass]="{'mobile-wrapper' : isMobile}"
         [ngStyle]="{'padding-left': (opened && !isMobile) ? '240px' : '0px'}">
      <div class="full-width">
        <ng-scrollbar #scrollable track="all" appearance="compact" pointerEventsMethod="scrollbar" visibility="hover">
          @if (isAuthenticated && userAgreement && dataPerspectiveDescription) {
            <h2 class="align-center p-mt-3 p-mb-1" style="color: #4A4A4A;"><strong><i class="fas fa-eye eye"
                                                                                      pTooltip="Data Perspective"
                                                                                      tooltipPosition="bottom"></i> {{ dataPerspectiveDescription }}</strong>
            </h2>
          }
          <router-outlet class="content"></router-outlet>
        </ng-scrollbar>
      </div>
    </div>
  </div>
</ng-container>
@if (isAuthenticated) {
  <footer id="footer">
    <div class="menu-container">
      <a id="aUniontrack" class="ut-navigation ut-saveexclude" href="https://www.uniontrack.com"
         rel="noopener noreferrer" target="_blank" title="UnionTrack, Inc">&copy; {{ copyYear }},
        UnionTrack<sup>&reg;</sup></a>
      <div style="float: right; margin-bottom: 5px;">
        Required
        <sup class="req">*</sup>
      </div>
    </div>
  </footer>
} @else {
  <footer class="full-width text-align-middle unauthFooterContainer">
    <a class="ut-navigation unauthFooter" href="https://www.uniontrack.com"
       rel="noopener noreferrer" target="_blank" title="UnionTrack, Inc">&copy; {{ copyYear }},
      UnionTrack<sup>&reg;</sup></a>
  </footer>
}
<p-confirmDialog [baseZIndex]="19000"></p-confirmDialog>
<p-confirmDialog [baseZIndex]="10000" key="validationFailed"></p-confirmDialog>
<p-confirmDialog [baseZIndex]="10000" key="suspension"></p-confirmDialog>
<div class="form-changed-dialog">
  <p-confirmDialog icon="pi pi-exclamation-triangle" key="formChanged"
                   [blockScroll]="false" [maskStyleClass]="'custom-mask-style'"></p-confirmDialog>

  <!--  <p-confirmDialog icon="pi pi-exclamation-triangle" key="formChanged" #cd-->
  <!--                   [blockScroll]="false" [maskStyleClass]="'custom-mask-style'" class="custom-mask-style">-->
  <!--    <ng-template pTemplate="headless" let-message>-->
  <!--      <div class="flex flex-column align-items-center p-5 surface-overlay border-round">-->
  <!--        <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">-->
  <!--          <i class="pi pi-question text-5xl"></i>-->
  <!--        </div>-->
  <!--        <span class="font-bold text-2xl block mb-2 mt-4">-->
  <!--                {{ message.header }}-->
  <!--            </span>-->
  <!--        <p class="mb-0">{{ message.message }}</p>-->
  <!--        <div class="flex align-items-center gap-2 mt-4">-->
  <!--          <button-->
  <!--            pButton-->
  <!--            label="Ignore"-->
  <!--            (click)="cd.reject()"-->
  <!--            class="p-button-outlined w-8rem " tabindex="10">-->
  <!--          </button>-->
  <!--          <button-->
  <!--            pButton-->
  <!--            label="Save"-->
  <!--            (click)="cd.accept()"-->
  <!--            class="w-8rem" tabindex="11">-->
  <!--          </button>-->

  <!--        </div>-->
  <!--      </div>-->
  <!--    </ng-template>-->
  <!--  </p-confirmDialog>-->
</div>
